import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SlackErrorRequest } from './modules/common/model/slack.model';
import { SlackService } from './modules/common/services/slack.service';

/**
 * Commom error messages from API.
 */
const CommonMessages: { [key: string]: string } = {
  'COMMON.MESSAGE_CONCURRENT_CHANGES':
    'Your changes were not saved, because another user has changed the data while you were viewing the page. Please refresh the page to see the updated data.',
  'COMMON.MESSAGE_SYSTEM_EXCEPTION':
    'There was an error during the operation. If this is not the first time you are seeing this message, please contact the system administrator.',
};

/**
 * Intercept http requests and handles the error message.
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private slackService: SlackService) {}

  /**
   * Handles error message.
   * @param request Request data.
   * @param next Next interceptor.
   *
   * @returns a Observable for the next interceptor handler.
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        this.reportError(response);
        const errorMsg = this.getErrorMessage(response);
        return throwError(errorMsg);
      })
    );
  }

  /**
   * Gets the appropriate message to display for the given http error.
   * @param response
   * @returns the error message
   */
  getErrorMessage(response: HttpErrorResponse): string {
    let result = '';

    // extract message from response
    if (response.error instanceof ErrorEvent) {
      result = `Error: ${response.error.message}`;
    } else {
      result = response.error?.originalMessage || response.error?.message || response.message;
    }

    // translate if it's a message key
    if (CommonMessages[result] != null) {
      result = CommonMessages[result];
    }

    // fallback to a generic message
    if (!result) {
      result = 'An unexpected error occurred.';
    }

    return result;
  }

  /**
   * Report an server error to Slack.
   * @param response
   */
  reportError(response: HttpErrorResponse): void {
    // ignore access denied / business / connection reset errors
    if (response.status === 400 || response.status === 403 || response.status === -1) {
      return;
    }

    // ignore slack requests (would cause an infinite loop)
    if (response.url && this.slackService.matches(response.url)) {
      return;
    }

    const data: SlackErrorRequest = {
      title: 'Questionnaire server error reported.',
      stackTrace: response.error?.detail || response.message,
      fields: [
        {
          title: 'Error type',
          value: `${response.status} ${response.statusText}`,
        },
        {
          title: 'Request',
          value: String(response.url),
        },
      ],
    };

    this.slackService.sendError(data).subscribe();
  }
}
