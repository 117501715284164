<!-- Welcome message -->
<div fxLayout="column" class="abs-margin">
  <p>Thanks for answering the ABS Operational Due Diligence Questionnaire. While completing, please notice the following:</p>
  <p>
    1. All inputted data is saved automatically, so you can return to complete the questionnaire at any time. Please remember to always use the
    original link provided in the email (do not bookmark) or your work will not be saved.
  </p>
  <p>
    2. We have pre-populated some information based upon our current records. Please take the time to fully review all information for incorrect or
    stale data and update as needed.
  </p>
  <p>
    3. Some information will be feeder or side by side fund specific. Please do your best to incorporate all funds listed. If anything is
    significantly different or conflicting between the funds, please call to discuss a solution.
  </p>
  <p>
    4. You may access the questionnaire as many times as needed, but once submitted as final you will lose access. Please complete and submit the
    final by {{ data.endDate | date : 'MM/dd/yyyy' }}.
  </p>
  <p>
    5. If you make any changes or update a blank cell, the cell will highlight itself after a second or two. If it does not highlight then the
    information is not being saved. Please refresh the browser or close and re-enter the link.
  </p>
  <p>If there are any questions or concerns regarding any of the material represented in the following report, please contact ABS below.</p>
  <span>Gregory J. Moroney</span>
  <span>ABS Global Investments</span>
  <span>537 Steamboat Road, 4th floor</span>
  <span>Greenwich, CT 06830</span>
  <span>Tel: 203-618-3766</span>
  <span>
    Email:
    <a href="mailto:gjm@absinv.com">gjm&#64;absinv.com</a>
  </span>
</div>
