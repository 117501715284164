import { Component, EventEmitter, Output } from '@angular/core';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { DdqAdviserTabBaseComponent } from '../ddq-adviser-tab-base/ddq-adviser-tab-base.component';

@Component({
  selector: 'app-ddq-adviser-other-accounts',
  templateUrl: './ddq-adviser-other-accounts.component.html',
  styleUrls: ['../ddq-adviser-tab-base/ddq-adviser-tab-base.component.scss'],
})
export class DdqAdviserOtherAccountsComponent extends DdqAdviserTabBaseComponent {
  @Output() selectedTab = new EventEmitter<number>();

  constructor(protected service: QuestionnaireService) {
    super(service);
  }

  /**
   * Send user to the IT/Cyber tab
   */
  public selectTab(): void {
    this.selectedTab.emit(10);
  }
}
