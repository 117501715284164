import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

export interface Contact {
  id: number;
  name: string;
  idtCompany?: number;
  companyName?: string;
  position?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  constructor(private http: HttpClient) {}

  /**
   * Search for contacts in one or more companies
   *
   * @param name - the contact name to search for
   * @param companyIds - the company ids
   */
  public queryContacts(name: string, companyIds: number[]): Observable<Contact[]> {
    return this.http.get<Contact[]>(`${environment.apiUrl}/contact/list`, {
      params: { name, companyIds: companyIds ? companyIds.map(String) : [], projection: 'excerpt' },
    });
  }

  /**
   * Find one contact by exact name in a company
   *
   * @param name - the contact name
   * @param companyIds - the company id
   */
  public findContact(name: string, companyId: number): Observable<Contact> {
    return this.http.get<Contact>(`${environment.apiUrl}/contact`, {
      params: { name, companyId: String(companyId), projection: 'excerpt' },
    });
  }
}
