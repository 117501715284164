import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

export interface CompanyAddress {
  id: number;
  idtCompany: number;
  name: string;
  address?: string;
  city?: string;
  country?: string;
  state?: string;
  zip?: string;
  phone?: string;
  location?: string;
  questionnaireIndex?: number;
}

export interface Company {
  id: number;
  idtCompany: number;
  name: string;
}

export enum CompanyType {
  FIRM = 1,
  ADMINISTRATOR = 2,
  AUDITOR = 3,
  LEGAL = 4,
  PRIME_BROKER = 5,
  OTHER = 6,
  CUSTODIAN = 7,
  COMPLIANCE_CONSULTANT = 8,
  INDUSTRY_CONTACT = 9,
  VENDOR = 10,
  IT_CONSULTANT = 11,
  INDEP_FUND_DIRECTOR = 12,
}

export enum ServiceProviderType {
  ADMINISTRATOR = 1,
  AUDITOR = 2,
  LEGAL_MC = 3,
  LEGAL_OS = 4,
  COMPLIANCE_CONSULTANT = 5,
  IT_CONSULTANT = 6,
  CUSTODIAN = 7,
  ISDA = 8,
  INDEPENDENT_BOARD_DIRECTOR = 9,
  // values below are for this app only, they don't exist in the system
  PRIME_BROKER = 99,
}

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private http: HttpClient) {}

  private commonSearchTypes = [
    CompanyType.ADMINISTRATOR,
    CompanyType.AUDITOR,
    CompanyType.COMPLIANCE_CONSULTANT,
    CompanyType.CUSTODIAN,
    CompanyType.LEGAL,
    CompanyType.PRIME_BROKER,
    CompanyType.IT_CONSULTANT,
    CompanyType.INDEP_FUND_DIRECTOR,
  ];

  public getCommonSearchTypes(): CompanyType[] {
    return this.commonSearchTypes;
  }

  /**
   * Search for companies with its default addresses
   *
   * @param searchTerm - the company name to search
   * @param companyTypes - the company types to search
   */
  public queryAddresses(searchTerm: string, companyTypes: number[] | CompanyType[]): Observable<CompanyAddress[]> {
    return this.http.get<CompanyAddress[]>(`${environment.apiUrl}/company/addresses`, {
      params: { searchTerm, companyTypes },
    });
  }

  /**
   * Get one Company by name
   *
   * @param name - the company name
   */
  public getCompanyByName(name: string): Observable<Company> {
    return this.http.get<Company>(`${environment.apiUrl}/company/name`, {
      params: { name, projection: 'excerpt' },
    });
  }
}
