import { Component } from '@angular/core';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { QuestionnaireAnswerBaseComponent } from '../questionnaire-answer-util/questionnaire-answer-base.component';

@Component({
  selector: 'app-questionnaire-checkbox-answer',
  templateUrl: './questionnaire-checkbox-answer.component.html',
  styleUrls: ['./questionnaire-checkbox-answer.component.scss'],
})
export class QuestionnaireCheckboxAnswerComponent extends QuestionnaireAnswerBaseComponent {
  constructor(protected service: QuestionnaireService) {
    super(service);
  }
}
