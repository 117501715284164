import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { SignaturePadComponent } from 'app/modules/common/signature-pad/signature-pad.component';
import { Questionnaire, QuestionnaireConclusionData, QuestionnaireStatus } from 'app/modules/questionnaire/model/questionnaire.model';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';

/**
 * Component to handle the questionnaire conclusion.
 */
@Component({
  selector: 'app-questionnaire-conclusion',
  templateUrl: './questionnaire-conclusion.component.html',
  styleUrls: ['./questionnaire-conclusion.component.scss'],
})
export class QuestionnaireConclusionComponent implements AfterViewInit {
  @Input() questionnaire!: Questionnaire;

  @Output() cancel = new EventEmitter<void>();

  @Output() errorhandler = new EventEmitter<string | null>();

  public form = this.formBuilder.group({
    name: new FormControl<string | null>(null, Validators.required),
    title: new FormControl<string | null>(null, Validators.required),
  });

  public signatureWarn = false;

  @ViewChild('signature')
  public signaturePad!: SignaturePadComponent;

  constructor(private service: QuestionnaireService, private formBuilder: FormBuilder) {}

  ngAfterViewInit(): void {
    this.clear();
  }

  /**
   * Finishes filling out the questionnaire
   */
  public onFinish(): void {
    this.signatureWarn = false;

    if (!this.signaturePad.getPoints().length) {
      this.signatureWarn = true;
      return;
    }

    const conclusion = this.form.value as QuestionnaireConclusionData;
    conclusion.signature = this.signaturePad.getImage();

    this.service.finishProgress(this.questionnaire.id, conclusion).subscribe(
      () => {
        this.questionnaire.status = QuestionnaireStatus.TO_BE_REVIEWED;
        this.errorhandler.emit(null);
      },
      (errorMsg: string) => this.errorhandler.emit(errorMsg)
    );
  }

  /**
   * Cancel questionnaire conclusion.
   */
  public onCancel(): void {
    this.cancel.emit();
  }

  public clear(): void {
    this.signaturePad.clearPad();
  }
}
