<div fxLayout="column" class="odd-form-section">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    @for (comment of comments; track comment.name) {
      <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
        <span class="question-info">{{ comment.label }}</span>
        @if (showReview) {
          <app-questionnaire-review
            [disabled]="isReviewed()"
            [answers]="getAnswersForReview([comment.name])"
            (errorhandler)="onError($event)"
          ></app-questionnaire-review>
        }
      </div>
      <app-questionnaire-comment-answer
        [noMargin]="true"
        [noLabel]="true"
        [highlightChanges]="true"
        [data]="getAnswer(comment.name)"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer(comment.name)?.reviewed || !!getAnswer(comment.name)?.reviewing"
      ></app-questionnaire-comment-answer>
    }

    @for (question of dropdowns; track question.name) {
      <div fxLayout="column" class="form-section" fxLayoutGap="8px">
        <span>{{ question.label }}</span>
        <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
          <app-questionnaire-boolean-answer
            [highlightChanges]="true"
            [data]="getAnswer(question.name)"
            (errorhandler)="onError($event)"
            [disabled]="isReviewed() || !!getAnswer(question.name)?.reviewed || !!getAnswer(question.name)?.reviewing"
          ></app-questionnaire-boolean-answer>
          @if (showReview) {
            <app-questionnaire-review
              [disabled]="isReviewed()"
              [answers]="getAnswersForReview([question.name, question.comment])"
              (errorhandler)="onError($event)"
            ></app-questionnaire-review>
          }
        </div>
        <app-questionnaire-comment-answer
          [label]="question.commentLabel"
          [noMargin]="true"
          [highlightChanges]="true"
          [data]="getAnswer(question.comment)"
          (errorhandler)="onError($event)"
          [disabled]="isReviewed() || !!getAnswer(question.comment)?.reviewed || !!getAnswer(question.comment)?.reviewing"
        ></app-questionnaire-comment-answer>
      </div>
    }
  </div>
</div>
