import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { environment } from 'environments/environment';

/**
 * Interface for ip data.
 */
export interface IpData {
  ip: string;
}

/**
 * Service to get client IP address.
 */
@Injectable({
  providedIn: 'root',
})
export class IpService {
  constructor(private http: HttpClient) {}

  /**
   * Get the client IP address.
   */
  public getIPAddress(): Observable<IpData> {
    return this.http.get<IpData>(environment.ipifyUrl).pipe(
      timeout(5000),
      catchError(() => of({ ip: 'undefined' }))
    );
  }
}
