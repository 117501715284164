import { Component, Input } from '@angular/core';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { QuestionnaireAnswerBaseComponent } from '../questionnaire-answer-util/questionnaire-answer-base.component';

@Component({
  selector: 'app-questionnaire-decimal-answer',
  templateUrl: './questionnaire-decimal-answer.component.html',
})
export class QuestionnaireDecimalAnswerComponent extends QuestionnaireAnswerBaseComponent {
  @Input() decimals?: number;

  constructor(protected service: QuestionnaireService) {
    super(service);
  }
}
