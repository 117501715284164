import { Component, Input } from '@angular/core';
import { QuestionnaireService } from '../../../services/questionnaire.service';
import { QuestionnaireAnswerBaseComponent } from '../questionnaire-answer-util/questionnaire-answer-base.component';

/**
 * Component that represents an questionnaire answer with boolean input type.
 */
@Component({
  selector: 'app-questionnaire-boolean-answer',
  templateUrl: './questionnaire-boolean-answer.component.html',
  styleUrls: ['./questionnaire-boolean-answer.component.scss'],
})
export class QuestionnaireBooleanAnswerComponent extends QuestionnaireAnswerBaseComponent {
  @Input() highlightChanges = false;

  constructor(service: QuestionnaireService) {
    super(service);
  }
}
