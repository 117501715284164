import { Component } from '@angular/core';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { OddWebformTabBaseComponent } from '../odd-webform-tab-base/odd-webform-tab-base.component';

@Component({
  selector: 'app-odd-webform-cash-procedures',
  templateUrl: './odd-webform-cash-procedures.component.html',
  styleUrls: ['../odd-webform-tab-base/odd-webform-tab-base.component.scss'],
})
export class OddWebformCashProceduresComponent extends OddWebformTabBaseComponent {
  comments = [
    {
      label: 'Have there been any changes to your cash controls or procedures in the past year? (including changes to authorized signatories)',
      name: 'CASH_QUESTION_F1',
    },
    {
      label:
        'Do you have an independent party (ie fund administrator) as an authorized signatory for all external payments out of the name of the fund?',
      name: 'CASH_QUESTION_F2',
    },
    {
      label:
        'In which countries/jurisdictions are Fund bank accounts maintained? Are all accounts in a country member of the Financial Action Task Force (FATF)?',
      name: 'CASH_QUESTION_F3',
    },
    {
      label:
        'In which countries/jurisdictions are Management Company bank accounts maintained? Are all accounts in a country member of the Financial Action Task Force (FATF)?',
      name: 'CASH_QUESTION_F4',
    },
  ];

  constructor(protected service: QuestionnaireService) {
    super(service);
  }
}
