import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

/*
 * Represents the ID of a General Info option
 */
export interface GeneralInfoOptionId {
  code: string;
  option: string;
}

/*
 * One option of the General Info type
 */
export interface GeneralInfoOption {
  id: GeneralInfoOptionId;
  order: number;
  complementEnabled: boolean;
  complementDatatype: string;
  complementTitle: string;
  title: string;
}

/*
 * One General Info object and its options
 */
export interface GeneralInfo {
  code: string;
  multiple: boolean;
  order: number;
  title: string;
  options: GeneralInfoOption[];
  optionTitles?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class GeneralInfoService {
  constructor(private http: HttpClient) {}

  /*
   * Get all General Info types and its options
   */
  public getAll(): Observable<GeneralInfo[]> {
    return this.http.get<GeneralInfo[]>(`${environment.apiUrl}/odd/generalInfo`);
  }
}
