import { CommonModule as AngularCommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatModule } from 'app/mat.module';
import { CommonModule } from '../../../common/common.module';
import { QuestionnaireModule } from '../../questionnaire.module';
import { OddWebformCashProceduresComponent } from './components/odd-webform-cash-procedures/odd-webform-cash-procedures.component';
import { OddWebformFirmInfoComponent } from './components/odd-webform-firm-info/odd-webform-firm-info.component';
import { OddWebformFundExpensesComponent } from './components/odd-webform-fund-expenses/odd-webform-fund-expenses.component';
import { OddWebformFundInfoComponent } from './components/odd-webform-fund-info/odd-webform-fund-info.component';
import { OddWebformGeneralInfoComponent } from './components/odd-webform-general-info/odd-webform-general-info.component';
import { OddWebformIsdaExposureComponent } from './components/odd-webform-isda-exposure/odd-webform-isda-exposure.component';
import { OddWebformItCyberComponent } from './components/odd-webform-it-cyber/odd-webform-it-cyber.component';
import { OddWebformOverviewFundsComponent } from './components/odd-webform-overview/odd-webform-overview-funds/odd-webform-overview-funds.component';
import { OddWebformOverviewTablesComponent } from './components/odd-webform-overview/odd-webform-overview-tables/odd-webform-overview-tables.component';
import { OddWebformOverviewComponent } from './components/odd-webform-overview/odd-webform-overview.component';
import { OddWebformPrimeBrokerageComponent } from './components/odd-webform-prime-brokerage/odd-webform-prime-brokerage.component';
import { OddWebformRespInvestingComponent } from './components/odd-webform-resp-investing/odd-webform-resp-investing.component';
import { OddWebformReviewComponent } from './components/odd-webform-review/odd-webform-review.component';
import { OddWebformServiceProvidersComponent } from './components/odd-webform-service-providers/odd-webform-service-providers.component';
import { OddWebformValuationComponent } from './components/odd-webform-valuation/odd-webform-valuation.component';
import { OddWebformWelcomeComponent } from './components/odd-webform-welcome/odd-webform-welcome.component';
import { OddWebformComponent } from './components/odd-webform/odd-webform.component';

@NgModule({
  declarations: [
    OddWebformComponent,
    OddWebformOverviewComponent,
    OddWebformWelcomeComponent,
    OddWebformFundExpensesComponent,
    OddWebformOverviewFundsComponent,
    OddWebformOverviewTablesComponent,
    OddWebformServiceProvidersComponent,
    OddWebformPrimeBrokerageComponent,
    OddWebformIsdaExposureComponent,
    OddWebformCashProceduresComponent,
    OddWebformFirmInfoComponent,
    OddWebformItCyberComponent,
    OddWebformValuationComponent,
    OddWebformFundInfoComponent,
    OddWebformRespInvestingComponent,
    OddWebformGeneralInfoComponent,
    OddWebformReviewComponent,
  ],
  imports: [AngularCommonModule, FormsModule, MatModule, CommonModule, ReactiveFormsModule, QuestionnaireModule],
  exports: [],
})
export class OddWebformModule {}
