import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { UserContext } from '../model/user.model';

/**
 * User context service. Gets user context information form API.
 */
@Injectable({
  providedIn: 'root',
})
export class UserContextService {
  constructor(private http: HttpClient) {}

  /**
   * User context data.
   */
  public userContext$?: Observable<UserContext>;

  /**
   * Clears user context data.
   */
  public clear(): void {
    this.userContext$ = undefined;
  }

  /**
   * Get the user data from api.
   */
  public get(): Observable<UserContext> {
    if (!this.userContext$) {
      this.userContext$ = this.http.get<UserContext>(`${environment.apiUrl}/context`).pipe(shareReplay(1));
    }
    return this.userContext$;
  }

  public set(userContext: Observable<UserContext>) {
    this.userContext$ = userContext;
  }
}
