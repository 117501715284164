import { AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import SignaturePad, { PointGroup } from 'signature_pad';

@Component({
  selector: 'app-signature-pad',
  templateUrl: 'signature-pad.component.html',
  styleUrls: ['signature-pad.component.scss'],
})
export class SignaturePadComponent implements AfterViewInit {
  private signaturePad!: SignaturePad;

  @ViewChild('canvas', { read: ElementRef }) canvasRef!: ElementRef;

  @Input() points: PointGroup[] = [];

  @Input() editable = true;

  @Input() penColor = 'black';
  @Input() backgroundColor = 'transparent';

  @Input() height = 150;
  @Input() width = 400;

  @Input() responsive = true;

  ngAfterViewInit(): void {
    if (this.responsive) {
      this.resizeCanvas();
    }

    this.initPad();
  }

  @HostListener('window:resize')
  handleResize(): void {
    if (this.responsive) {
      this.resizeCanvas();
    }
  }

  initPad(): void {
    this.signaturePad = new SignaturePad(this.canvasRef.nativeElement, {
      penColor: this.penColor,
      backgroundColor: this.backgroundColor,
    });

    if (this.editable) {
      this.signaturePad.on();
    } else {
      this.signaturePad.off();
    }

    this.applyPoints();
  }

  public clearPad(): void {
    this.signaturePad.clear();
  }

  applyPoints(): void {
    if (!this.points || !this.signaturePad) {
      return;
    }

    this.signaturePad.clear();

    this.signaturePad.fromData(this.points);
  }

  public getPoints(): PointGroup[] {
    if (this.signaturePad) {
      return this.signaturePad.toData();
    } else {
      return [];
    }
  }

  public getImage(): string {
    return this.signaturePad.toDataURL();
  }

  resizeCanvas(): void {
    const canvas = this.canvasRef.nativeElement;
    if (!canvas) {
      return;
    }

    const pad = canvas.closest('.signature-pad') as HTMLElement;
    if (!pad) {
      return;
    }

    const w = pad.offsetWidth;
    const h = pad.offsetWidth / (this.width / this.height);

    canvas.setAttribute('width', `${w}`);
    canvas.setAttribute('height', `${h}`);

    this.applyPoints();
  }
}
