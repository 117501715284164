import { Component, Input } from '@angular/core';
import { Answer } from 'app/modules/questionnaire/model/questionnaire.model';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { QuestionnaireAnswerBaseComponent } from '../questionnaire-answer-util/questionnaire-answer-base.component';

@Component({
  selector: 'app-questionnaire-radio-answer',
  templateUrl: './questionnaire-radio-answer.component.html',
  styleUrls: ['./questionnaire-radio-answer.component.scss'],
})
export class QuestionnaireRadioAnswerComponent extends QuestionnaireAnswerBaseComponent {
  @Input() options!: string[];

  @Input() layout = 'row';

  @Input() multiAnswers!: Answer[];

  constructor(service: QuestionnaireService) {
    super(service);
  }

  public onMultiChange(index: number): void {
    this.multiAnswers.forEach((answer) => {
      answer.value = answer.index === index;
      this.answer(answer);
    });
  }
}
