import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { CompanyAddress, CompanyService } from 'app/modules/common/services/company.service';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { EMPTY } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { QuestionnaireAnswerBaseComponent } from '../questionnaire-answer-util/questionnaire-answer-base.component';

@Component({
  selector: 'app-questionnaire-company-answer',
  templateUrl: './questionnaire-company-answer.component.html',
})
export class QuestionnaireCompanyAnswerComponent extends QuestionnaireAnswerBaseComponent implements OnInit {
  public options: CompanyAddress[] = [];

  public textInput!: UntypedFormControl;

  @Input() companyTypes!: number[];

  @Output() selectedCompany = new EventEmitter<CompanyAddress>();

  private $disabled = false;

  @Input()
  set inputDisabled(value: boolean) {
    this.$disabled = value;
    if (this.$disabled) {
      this.textInput?.disable();
    } else {
      this.textInput?.enable();
    }
  }
  get inputDisabled(): boolean {
    return this.$disabled;
  }

  constructor(protected service: QuestionnaireService, private companyService: CompanyService) {
    super(service);
  }

  ngOnInit(): void {
    this.textInput = new UntypedFormControl({ disabled: this.$disabled, value: { id: -1, name: this.data!.value } });
    this.textInput.valueChanges
      .pipe(
        debounceTime(300),
        tap((searchTerm: string) => {
          // if search term is empty, erase the field
          if (!searchTerm && this.data!.value) {
            this.data!.value = null;
            this.onValueChange();
          }
        }),
        // the filter also avoids an object to be used for search after user selection
        filter((searchTerm) => searchTerm?.length >= 3),
        switchMap((searchTerm) =>
          this.companyService.queryAddresses(searchTerm, this.companyTypes).pipe(
            catchError((error) => {
              this.errorhandler.emit(error);
              return EMPTY;
            }),
            map((result) => {
              if (!result.some((e) => e.name.toLowerCase() === searchTerm.toLowerCase())) {
                result.unshift({ name: searchTerm, id: -1, idtCompany: -1 });
              }
              this.errorhandler.emit(null);

              return result;
            })
          )
        ),
        untilComponentDestroyed(this)
      )
      .subscribe(
        (response) => {
          this.options = response;
          this.errorhandler.emit(null);
        },
        (error: string) => {
          this.errorhandler.emit(error);
          return EMPTY;
        }
      );
  }

  /*
   * Save selected option and emit event for other fields
   */
  public selectOption(event: MatAutocompleteSelectedEvent): void {
    const company = { questionnaireIndex: this.data!.index, ...event.option.value };
    this.data!.value = company.name;
    this.onValueChange();
    this.selectedCompany.emit(company);
  }

  public display(company: CompanyAddress): string {
    return company?.name ? company.name : '';
  }

  public onBlur(): void {
    if (this.textInput.value?.name !== this.data!.value) {
      this.textInput.setValue(this.data!.value ? { id: -1, name: this.data!.value } : null);
    }
  }
}
