import { AfterContentChecked, Component } from '@angular/core';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { OddWebformTabBaseComponent } from '../../odd-webform-tab-base/odd-webform-tab-base.component';

@Component({
  selector: 'app-odd-webform-overview-tables',
  templateUrl: './odd-webform-overview-tables.component.html',
  styleUrls: ['../../odd-webform-tab-base/odd-webform-tab-base.component.scss'],
})
export class OddWebformOverviewTablesComponent extends OddWebformTabBaseComponent implements AfterContentChecked {
  tables = [
    {
      label: `FIRM's 5 Largest Investors by Client Type (i.e. FoF, HNW, etc.) as a % of AUM`,
      answers: 'OVW_CLIENT_BRK_LG_INV_TYPE',
      value: 'OVW_CLIENT_BRK_LG_INV_VALUE',
      total: 0,
    },
    {
      label: '% of Firm Assets By Client Type (i.e. FoF, HNW, etc.)',
      answers: 'OVW_CLIENT_BRK_FIRM_ASSETS_TYPE',
      value: 'OVW_CLIENT_BRK_FIRM_ASSETS_VALUE',
      fieldsToAdd: ['OVW_CLIENT_BRK_FIRM_ASSETS_TYPE', 'OVW_CLIENT_BRK_FIRM_ASSETS_VALUE'],
      total: 0,
    },
    {
      label: '% of co-mingled Fund Assets ABS is invested by Client Type (ie FOF, HNW, etc.)',
      answers: 'OVW_CLIENT_BRK_STR_ASSETS_TYPE',
      value: 'OVW_CLIENT_BRK_STR_ASSETS_VALUE',
      fieldsToAdd: ['OVW_CLIENT_BRK_STR_ASSETS_TYPE', 'OVW_CLIENT_BRK_STR_ASSETS_VALUE'],
      total: 0,
    },
  ];

  checkboxes = [
    { label: 'Ownership', name: 'OVW_CHANGES_OWNERSHIP' },
    { label: 'Professional Staff', name: 'OVW_CHANGES_STAFF' },
    { label: 'Organizational Structure', name: 'OVW_CHANGES_STRUCTURE' },
    { label: 'Auditor / Legal Counsel', name: 'OVW_CHANGES_AUDITOR' },
    { label: 'Custodian / Prime Broker / Administrator', name: 'OVW_CHANGES_ADMINISTRATOR' },
    { label: 'Board of Directors', name: 'OVW_CHANGES_DIRECTORS' },
    { label: 'IT/Compliance Consultant', name: 'OVW_CHANGES_IT_COMPLIANCE' },
  ];

  constructor(protected service: QuestionnaireService) {
    super(service);
  }

  ngAfterContentChecked(): void {
    this.tables.forEach((table) => {
      table.total = this.getAllAnswers(table.value).reduce((total, item) => total + this.getNumber(item.value), 0);
    });
  }
}
