import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../environments/environment';

/**
 * Intercept http requests and add the JWT Authorization token if present.
 */
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  /**
   * Add auth header with jwt if user is logged in and request is to the api url
   * @param request Request data.
   * @param next Next interceptor.
   *
   * @returns a Observable for the next interceptor handler.
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = window.localStorage.getItem('auth_token');

    const isApiUrl = request.url.startsWith(environment.apiUrl);

    if (token && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(request);
  }
}
