@if (data) {
  <mat-form-field [ngClass]="{ 'answer-saved': highlightChanges && data.modified, 'comment-margin': !noMargin }">
    @if (!noLabel) {
      <mat-label>{{ label || 'Leave a comment' }}</mat-label>
    }
    <textarea
      matInput
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="30"
      [disabled]="disabled"
      [(ngModel)]="data.value"
      (change)="onValueChange()"
    ></textarea>
  </mat-form-field>
}
