import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

/**
 * Interface for datatype data.
 */
export interface Datatype {
  // eslint-disable-next-line
  value: any;
  label: string;
  abbreviation: string;
}

@Injectable({
  providedIn: 'root',
})
export class DatatypeService {
  public observables$: { [key: string]: Observable<Datatype[]> } = {};

  constructor(private http: HttpClient) {}

  public get(id: string): Observable<Datatype[]> {
    if (!this.observables$[id]) {
      this.observables$[id] = this.http.get<Datatype[]>(`${environment.apiUrl}/datatype/${id}`).pipe(shareReplay(1));
    }
    return this.observables$[id];
  }
}
