<mat-card appearance="outlined" fxFlex fxLayout="column" id="questionnaire">
  <mat-card-content fxLayout="column" class="abs-nomargin">
    @if (errorMessage) {
      <app-error-message [message]="errorMessage"></app-error-message>
    }

    <!--Fixed Header -->
    @if (data) {
      <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-half-bottom">
        <h4 fxFlex class="abs-nomargin mat-subtitle-2">ABS Adviser-SubAdviser DDQ for {{ data.company }}</h4>
        <h4 class="abs-nomargin mat-subtitle-2">Status: {{ getStatus() }}</h4>
        <div fxFlex="none" fxLayoutAlign="center center" class="abs-margin-half-right abs-margin-left">
          <button mat-raised-button [matMenuTriggerFor]="menu">Print</button>
          <mat-menu #menu="matMenu">
            <a [href]="getPdfUrl(tabIndex)" mat-menu-item target="_blank">This tab</a>
            <a [href]="getPdfUrl()" mat-menu-item target="_blank">All tabs</a>
          </mat-menu>
        </div>
      </div>
    }
  </mat-card-content>

  @if (data) {
    <mat-card-content class="no-scroll" fxLayout="column">
      <!-- Tabs for data filling -->
      <mat-tab-group (selectedIndexChange)="onTabChange($event)" class="no-scroll" [selectedIndex]="tabIndex">
        <mat-tab label="Overview">
          <app-ddq-adviser-overview [data]="data" (showError)="onError($event)" [showReview]="isShowReview()"></app-ddq-adviser-overview>
          <app-ddq-adviser-overview-funds [data]="data" (showError)="onError($event)" [showReview]="isShowReview()"></app-ddq-adviser-overview-funds>
          <app-ddq-adviser-overview-tables
            [data]="data"
            (showError)="onError($event)"
            [showReview]="isShowReview()"
          ></app-ddq-adviser-overview-tables>
        </mat-tab>
        <mat-tab label="Organization">
          <app-ddq-adviser-organization [data]="data" (showError)="onError($event)" [showReview]="isShowReview()"></app-ddq-adviser-organization>
        </mat-tab>
        <mat-tab label="Service Providers">
          <app-ddq-adviser-service-providers
            [data]="data"
            (showError)="onError($event)"
            [showReview]="isShowReview()"
            [companyData]="companyData"
          ></app-ddq-adviser-service-providers>
        </mat-tab>
        <mat-tab label="Investment">
          <app-ddq-adviser-investment [data]="data" (showError)="onError($event)" [showReview]="isShowReview()"></app-ddq-adviser-investment>
        </mat-tab>
        <mat-tab label="Compliance">
          <app-ddq-adviser-compliance [data]="data" (showError)="onError($event)" [showReview]="isShowReview()"></app-ddq-adviser-compliance>
        </mat-tab>
        <mat-tab label="Inspections and Proceedings">
          <app-ddq-adviser-inspections [data]="data" (showError)="onError($event)" [showReview]="isShowReview()"></app-ddq-adviser-inspections>
        </mat-tab>
        <mat-tab label="Audits and Recordkeeping">
          <app-ddq-adviser-audits [data]="data" (showError)="onError($event)" [showReview]="isShowReview()"></app-ddq-adviser-audits>
        </mat-tab>
        <mat-tab label="Firm Info">
          <app-ddq-adviser-firm-info [data]="data" (showError)="onError($event)" [showReview]="isShowReview()"></app-ddq-adviser-firm-info>
        </mat-tab>
        <mat-tab label="Transactions and Valuation">
          <app-ddq-adviser-transactions [data]="data" (showError)="onError($event)" [showReview]="isShowReview()"></app-ddq-adviser-transactions>
        </mat-tab>
        <mat-tab label="Other Accounts">
          <app-ddq-adviser-other-accounts
            [data]="data"
            (showError)="onError($event)"
            (selectedTab)="onTabChange($event)"
            [showReview]="isShowReview()"
          ></app-ddq-adviser-other-accounts>
        </mat-tab>
        <mat-tab label="Insurance">
          <app-ddq-adviser-insurance [data]="data" (showError)="onError($event)" [showReview]="isShowReview()"></app-ddq-adviser-insurance>
        </mat-tab>
        <mat-tab label="Responsible Investing">
          <app-ddq-adviser-resp-investing [data]="data" (showError)="onError($event)" [showReview]="isShowReview()"></app-ddq-adviser-resp-investing>
        </mat-tab>
        <mat-tab label="General Info">
          <app-ddq-adviser-general-info [data]="data" (showError)="onError($event)" [showReview]="isShowReview()"></app-ddq-adviser-general-info>
        </mat-tab>
        <mat-tab label="IT/Cyber">
          <app-ddq-adviser-it-cyber [data]="data" (showError)="onError($event)" [showReview]="isShowReview()"></app-ddq-adviser-it-cyber>
        </mat-tab>
        <mat-tab label="Other">
          <app-ddq-adviser-other [data]="data" (showError)="onError($event)" [showReview]="isShowReview()"></app-ddq-adviser-other>
          @if (isReviewing() || isReviewed()) {
            <div fxLayout="column">
              <h4 class="mat-body-strong">Signature information:</h4>
              <span class="mat-small">Name</span>
              <span class="mat-body-strong">{{ data.respondentName }}</span>
              <span class="mat-small abs-margin-half-top">Title</span>
              <span class="mat-body-strong">{{ data.respondentTitle }}</span>
              <span class="mat-small abs-margin-half-top">Signed on</span>
              <span class="mat-body-strong">{{ data.respondentFinishDate | date: 'longDate' }}</span>
              <span class="mat-small abs-margin-half-top">IP Address</span>
              <span class="mat-body-strong">{{ data.respondentIpAddress }}</span>
              @if (data.respondentSignature) {
                <span class="mat-small abs-margin-half-top">Signature image</span>
                <span class="mat-body-strong"><img [src]="data.respondentSignature" /></span>
              }
            </div>
          }
          @if (isReviewing()) {
            <div fxLayout="row" fxLayoutAlign="end end" class="abs-margin-right">
              <button mat-raised-button color="primary" (click)="finishReview()">Finish Review</button>
            </div>
          }
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  }
</mat-card>
