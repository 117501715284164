import { Component, Input } from '@angular/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Company, CompanyService, ServiceProviderType } from 'app/modules/common/services/company.service';
import { Contact, ContactService } from 'app/modules/common/services/contact.service';
import { DialogService } from 'app/modules/common/services/dialog/dialog.service';
import { Answer, CompanyReviewData, ReviewAction } from 'app/modules/questionnaire/model/questionnaire.model';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { QuestionnaireConflictComponent } from '../questionnaire-conflict/questionnaire-conflict.component';
import { QuestionnaireReviewComponent } from './questionnaire-review.component';

/**
 * Component that handles answers review for company/contact combos with conflict resolution.
 * It receives one or more answers to be reviewed at same time.
 */
@Component({
  selector: 'app-questionnaire-company-review',
  templateUrl: './questionnaire-review.component.html',
  styleUrls: ['./questionnaire-review.component.scss'],
})
export class QuestionnaireCompanyReviewComponent extends QuestionnaireReviewComponent {
  @Input() companyField!: string;

  @Input() serviceProviderType!: string;

  @Input() contactField!: string;

  @Input() companyData?: CompanyReviewData;

  @Input() conflictTitle!: string;

  constructor(
    public service: QuestionnaireService,
    public companyService: CompanyService,
    public contactService: ContactService,
    public dialog: DialogService
  ) {
    super(service, dialog);
  }

  /**
   * Adds or removes review for the component answers.
   */
  public toggleReview(): void {
    this.dialog.openLoadingDialog();

    this.reviewActions = [];

    // if adding a review and company or contact was modified
    if (!this.isReviewed() && (this.getCompanyAnswer().modified || this.getContactAnswer().modified)) {
      this.checkCompany();
    } else {
      super.toggleReview();
    }
  }

  getAnswer(code: string): Answer {
    return this.answers.find((answer) => answer.code === code)!;
  }

  checkCompany(): void {
    if (this.getCompanyAnswer().value) {
      // find the company of the answer
      this.companyService
        .getCompanyByName(this.getCompanyAnswer().value)
        .pipe(
          catchError((error) => {
            this.errorhandler.emit(error);
            this.stopLoading();
            return EMPTY;
          }),
          tap((result: Company) => {
            // company doest not exist in the system, enter conflict mode
            if (!result?.idtCompany) {
              // current company
              const type = Number(this.serviceProviderType);
              const currentCompany = this.getCurrentCompany(type);

              // open company conflict dialog
              this.openConflictDialog('company', type, currentCompany, this.getCompanyAnswer().value);
            } else {
              // company exists check contact
              this.checkContact(result.idtCompany);
            }

            this.errorhandler.emit(null);
          }),
          untilComponentDestroyed(this)
        )
        .subscribe();
    } else {
      super.toggleReview();
    }
  }

  checkContact(companyId?: number): void {
    const originalContact = this.findOriginalContact();

    // if has company id and contact was modified and there's a contact value
    if (companyId && this.getContactAnswer().modified && this.getContactAnswer().value && this.getContactAnswer().value !== originalContact?.name) {
      this.contactService
        .findContact(this.getContactAnswer().value, companyId)
        .pipe(
          catchError((error) => {
            this.errorhandler.emit(error);
            this.stopLoading();
            return EMPTY;
          }),
          tap((result: Contact) => {
            // contact doest not exist in the system, enter conflict mode
            if (!result?.id) {
              const type = Number(this.serviceProviderType);

              // open contact conflict dialog
              this.openConflictDialog('contact', type, originalContact, this.getContactAnswer().value, companyId);
            } else {
              // contact exists call review
              super.toggleReview();
            }

            this.errorhandler.emit(null);
          }),
          untilComponentDestroyed(this)
        )
        .subscribe();
    } else {
      super.toggleReview();
    }
  }

  private findOriginalContact(): Contact | null {
    const type = Number(this.serviceProviderType);
    const company = this.getCurrentCompany(type);
    const contacts = this.companyData!.contacts[type];

    if (company?.id) {
      return contacts.find((item: Contact) => item.idtCompany === company.id) || null;
    }

    return null;
  }

  private getCurrentCompany(type: number): Company {
    if (type === ServiceProviderType.PRIME_BROKER) {
      return this.companyData!.primeBrokers[this.getCompanyAnswer().index];
    } else if (type === ServiceProviderType.CUSTODIAN) {
      return this.companyData!.custodians[this.getCompanyAnswer().index];
    } else if (type === ServiceProviderType.INDEPENDENT_BOARD_DIRECTOR) {
      // there are two directors on a separate row, index starts on 6
      return this.companyData!.fundDirectors[this.getCompanyAnswer().index - 6];
    } else {
      return this.companyData!.companies[type];
    }
  }

  private getCompanyAnswer(): Answer {
    return this.getAnswer(this.companyField);
  }

  private getContactAnswer(): Answer {
    return this.getAnswer(this.contactField);
  }

  private stopLoading(): void {
    this.dialog.closeLoadingDialog();
    this.reviewed = false;
  }

  private openConflictDialog(
    entityType: string,
    type: number,
    current: Company | Contact | null,
    userTyped: string,
    contactCompanyId?: number
  ): void {
    this.dialog
      .getService()
      .open(QuestionnaireConflictComponent, {
        disableClose: true,
        width: '500px',
        data: {
          entityType,
          type,
          current,
          userTyped,
          contactCompanyId,
          title: this.conflictTitle,
        },
      })
      .afterClosed()
      .subscribe((result: ReviewAction) => {
        // after closing dialog check user decision
        if (result) {
          // if user didn't cancel, add action to review data
          this.reviewActions.push(result);

          if (entityType === 'company') {
            // check if contact was modified too
            this.checkContact(result?.id);
          } else {
            // conflict resolved call review
            super.toggleReview();
          }
        } else {
          this.stopLoading();
        }
      });
  }
}
