import { Component, Input, OnInit } from '@angular/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Datatype, DatatypeService } from 'app/modules/common/services/datatype.service';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { QuestionnaireAnswerBaseComponent } from '../questionnaire-answer-util/questionnaire-answer-base.component';

export interface SelectOptions {
  label: string;
  // eslint-disable-next-line
  value: any;
}

@Component({
  selector: 'app-questionnaire-select-answer',
  templateUrl: './questionnaire-select-answer.component.html',
  styleUrls: ['./questionnaire-select-answer.component.scss'],
})
export class QuestionnaireSelectAnswerComponent extends QuestionnaireAnswerBaseComponent implements OnInit {
  @Input() required: boolean = false;

  @Input() datatype!: string;

  @Input() options!: SelectOptions[];

  public datatypeOptions: Datatype[] = [];

  constructor(protected service: QuestionnaireService, private datatypeService: DatatypeService) {
    super(service);
  }

  ngOnInit(): void {
    if (this.datatype) {
      this.datatypeService
        .get(this.datatype)
        .pipe(
          catchError((error) => {
            this.errorhandler.emit(error);
            return EMPTY;
          }),
          tap((items: Datatype[]) => {
            this.datatypeOptions = items;
          }),
          untilComponentDestroyed(this)
        )
        .subscribe();
    }
  }
}
