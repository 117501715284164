@if (data) {
  <mat-form-field [ngClass]="{ 'answer-saved': data.modified }">
    @if (label) {
      <mat-label>{{ label }}</mat-label>
    }
    <input
      type="text"
      matInput
      [matDatepicker]="picker"
      [(ngModel)]="data.valueAsDate"
      (dateChange)="onValueChange()"
      [disabled]="disabled"
      (focus)="noIcon && picker.open()"
      (click)="noIcon && picker.open()"
      />
      <mat-datepicker #picker></mat-datepicker>
      @if (!noIcon) {
        <mat-datepicker-toggle [disabled]="disabled" matSuffix [for]="picker"></mat-datepicker-toggle>
      }
    </mat-form-field>
  }
