import { Component } from '@angular/core';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { OddWebformTabBaseComponent } from '../odd-webform-tab-base/odd-webform-tab-base.component';

@Component({
  selector: 'app-odd-webform-overview',
  templateUrl: './odd-webform-overview.component.html',
  styleUrls: ['../odd-webform-tab-base/odd-webform-tab-base.component.scss'],
})
export class OddWebformOverviewComponent extends OddWebformTabBaseComponent {
  constructor(protected service: QuestionnaireService) {
    super(service);
  }
}
