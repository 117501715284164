/**
 * Attestation questionnaire contants, holds the info for each question in the questionnaire.
 */

export const Attestation: { [key: string]: { title: string } } = {
  ATTESTATION_Q1: {
    title: `1. During the Reporting Period, have there been any material violations to the
        Sub-Advisor’s compliance program and established policies and procedures,
        including the Code of Ethics?`,
  },

  ATTESTATION_Q2: {
    title: `2. Were there any material compliance matters that occurred during the Reporting Period?`,
  },

  ATTESTATION_Q3: {
    title: `3. During the Reporting Period, were there any substantive changes to the Sub-Advisor’s compliance
        policies and procedures?`,
  },

  ATTESTATION_Q4: {
    title: `4. During the Reporting Period, were there any substantive changes to the Sub-Advisor’s proxy
        voting policy and procedures?`,
  },

  ATTESTATION_Q5: {
    title: `5. During the Reporting Period, were there any changes to the key individuals who
        implement the Sub-Advisor’s compliance policies and procedures?`,
  },

  ATTESTATION_Q6: {
    title: `6. During the Reporting Period, were there been any violations of the SMA’s investment
        guidelines, limitations or restrictions?`,
  },

  ATTESTATION_Q7: {
    title: `7. During the Reporting Period, has the firm NOT complied with its trade allocation
        policy and have the clients NOT been treated fairly in the allocation of
        investment opportunities?`,
  },

  ATTESTATION_Q8: {
    title: `8. Has the Sub-Adviser received any complaints in relation to client accounts managed
        by the Sub-Adviser during the Reporting Period?`,
  },

  ATTESTATION_Q9: {
    title: `9. Have there been any material changes to the Sub-Advisor’s technology solutions
        during the Reporting Period?`,
  },

  ATTESTATION_Q10: {
    title: `10. During the Reporting Period, were there any changes to the Sub-Advisor’s key service
        providers?`,
  },

  ATTESTATION_Q11: {
    title: `11. Has the Sub-Advisor or its affiliates (as applicable) received any notification or
        inquiry (routine or non-routine) from any regulators during the Reporting
        Period?`,
  },

  ATTESTATION_Q12: {
    title: `12. Has the Sub-Advisor, it’s employees or, any of the client account it manages or sub-advises,
        been the focus of any litigation, formal investigation or administrative
        proceedings (including settlements) during the Reporting Period?`,
  },

  ATTESTATION_Q13: {
    title: `13. Has the Sub-Advisor experienced a material security (e.g., information security,
        cybersecurity, etc.) event during the Reporting Period?`,
  },

  ATTESTATION_Q14: {
    title: `14. Has the Sub-Advisor’s business continuity/business recovery plan been tested during
        the Reporting Period? If
        ‘Yes’, provide an overview of the tests completed, if there were any
        significant failures and how they were, or will be, remedied.`,
  },

  ATTESTATION_Q15: {
    title: `15. Has the Sub-Advisor had to implement its business continuity/disaster recovery plan
        during the Reporting Period?`,
  },

  ATTESTATION_Q16: {
    title: `16. The Sub-Advisor is currently not required to be registered with the Commodities
        Futures Trading Commission (“CFTC”) as a CTA or a CPO or a National Futures
        Association (“NFA”) member. During the Reporting Period, have there been any
        changes to the Sub-Advisor’s registration status with the CFTC and membership
        status with the NFA?`,
  },

  ATTESTATION_Q17: {
    title: `17. Are there any other material conflicts of interest or relationships (contractual or otherwise) that
        we should be aware of?`,
  },
};
