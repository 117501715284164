import { Component } from '@angular/core';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { DdqAdviserTabBaseComponent } from '../ddq-adviser-tab-base/ddq-adviser-tab-base.component';

@Component({
  selector: 'app-ddq-adviser-resp-investing',
  templateUrl: './ddq-adviser-resp-investing.component.html',
  styleUrls: ['../ddq-adviser-tab-base/ddq-adviser-tab-base.component.scss'],
})
export class DdqAdviserRespInvestingComponent extends DdqAdviserTabBaseComponent {
  public questions = [
    {
      selectLabel:
        'Has your firm created a policy for Responsible Investing (RI)? Environmental, Social, and Governance (ESG)? Socially Responsible Investing (SRI)?',
      selectQuestion: 'DDQ_RESP_INVESTING_Q1_OPTION',
      commentLabel: 'Comments',
      commentQuestion: 'DDQ_RESP_INVESTING_Q1',
      reviewGroup: ['DDQ_RESP_INVESTING_Q1_OPTION', 'DDQ_RESP_INVESTING_Q1'],
    },
    {
      commentLabel:
        'If so, how do you incorporate RI/ESG/SRI matters into your risk management framework and investment process? What data sources do you use to access ESG-related data?',
      commentQuestion: 'DDQ_RESP_INVESTING_Q2',
      reviewGroup: ['DDQ_RESP_INVESTING_Q2'],
    },
    {
      commentLabel: 'What is your approach to engagement with companies on ESG/SRI issues?',
      commentQuestion: 'DDQ_RESP_INVESTING_Q3',
      reviewGroup: ['DDQ_RESP_INVESTING_Q3'],
    },
    {
      commentLabel: 'Are there any additional costs to the funds for RI/ESG/SRI services, and if so, what are they?',
      commentQuestion: 'DDQ_RESP_INVESTING_Q4',
      reviewGroup: ['DDQ_RESP_INVESTING_Q4'],
    },
    {
      selectLabel: 'Do you actively exercise your voting rights for proxies?',
      selectQuestion: 'DDQ_RESP_INVESTING_Q6',
      commentLabel: 'Comments',
      commentQuestion: 'DDQ_RESP_INVESTING_Q6_COMMENT',
      reviewGroup: ['DDQ_RESP_INVESTING_Q6', 'DDQ_RESP_INVESTING_Q6_COMMENT'],
    },
    {
      selectLabel: 'Is ESG considered in proxy voting?',
      selectQuestion: 'DDQ_RESP_INVESTING_Q7',
      commentLabel: 'Comments',
      commentQuestion: 'DDQ_RESP_INVESTING_Q7_COMMENT',
      reviewGroup: ['DDQ_RESP_INVESTING_Q7', 'DDQ_RESP_INVESTING_Q7_COMMENT'],
    },
    {
      commentLabel: 'Does your firm have a DEI Internal Policy in place to promote a diverse workforce?',
      commentQuestion: 'DDQ_RESP_INVESTING_Q8',
      reviewGroup: ['DDQ_RESP_INVESTING_Q8'],
    },
    {
      commentLabel: 'Do you integrate DEI within the investment process?',
      commentQuestion: 'DDQ_RESP_INVESTING_Q9',
      reviewGroup: ['DDQ_RESP_INVESTING_Q9'],
    },
    {
      commentLabel: 'Are you a United Nations Principle for Responsible Investing (PRI) signatory? If not, would you consider becoming one?',
      commentQuestion: 'DDQ_RESP_INVESTING_Q5',
      reviewGroup: ['DDQ_RESP_INVESTING_Q5'],
    },
  ];

  constructor(protected service: QuestionnaireService) {
    super(service);
  }
}
