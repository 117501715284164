import { Component, Input } from '@angular/core';

/**
 * Component that represents a question in the questionnaire.
 */
@Component({
  selector: 'app-questionnaire-question',
  templateUrl: './questionnaire-question.component.html',
  styleUrls: ['./questionnaire-question.component.scss'],
})
export class QuestionnaireQuestionComponent {
  @Input() name!: string;
}
