<div fxLayout="column" class="odd-form-section">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    @for (comment of comments; track comment.name) {
      <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
        <span class="question-info">{{ comment.label }}</span>
        @if (showReview) {
          <app-questionnaire-review
            [disabled]="isReviewed()"
            [answers]="getAnswersForReview([comment.name])"
            (errorhandler)="onError($event)"
          ></app-questionnaire-review>
        }
      </div>
      <app-questionnaire-comment-answer
        [noMargin]="true"
        [noLabel]="true"
        [highlightChanges]="true"
        [data]="getAnswer(comment.name)"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer(comment.name)?.reviewed || !!getAnswer(comment.name)?.reviewing"
      ></app-questionnaire-comment-answer>
    }
  </div>
</div>
