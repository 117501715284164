import { Component } from '@angular/core';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { QuestionnaireAnswerBaseComponent } from '../questionnaire-answer-util/questionnaire-answer-base.component';

@Component({
  selector: 'app-questionnaire-number-answer',
  templateUrl: './questionnaire-number-answer.component.html',
  styleUrls: ['./questionnaire-number-answer.component.scss'],
})
export class QuestionnaireNumberAnswerComponent extends QuestionnaireAnswerBaseComponent {
  constructor(protected service: QuestionnaireService) {
    super(service);
  }
}
