import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { Answer, IndexedValue } from '../../../model/questionnaire.model';
import { QuestionnaireService } from '../../../services/questionnaire.service';

/**
 * Base class for answers input components.
 */
@Component({
  template: '',
})
export abstract class QuestionnaireAnswerBaseComponent extends OnDestroyMixin {
  @Input() data?: Answer;

  @Input() label!: string;

  @Input() changeListener!: Observable<IndexedValue>;

  @Input() disabled: boolean = false;

  @Output() errorhandler = new EventEmitter<string | null>();

  constructor(protected service: QuestionnaireService) {
    super();
  }

  /**
   * Call the service to updates the answer data
   * @param data Answer data.
   */

  public answer(data: Answer): void {
    const answer = this.data || data;

    if (!answer.saving) {
      answer.saving = true;

      this.service.answer(data).subscribe(
        (saved: Answer) => {
          answer.modified = saved.modified;
          answer.reviewed = saved.reviewed;
          answer.version = saved.version;
          answer.saving = false;
          if (this.getSavedValue(saved) !== this.getValueToSave(answer)) {
            this.answer(answer);
          }
          this.errorhandler.emit(null);
        },
        (error: string) => this.errorhandler.emit(error)
      );
      console.log('Answering question: ' + data.code + ', index: ' + data.index + ', with value: ' + data.value);
    }
  }

  /**
   *  Updates the answer on value change event of the input.
   */
  public onValueChange(): void {
    this.data!.value = this.data!.value === '' || this.data!.value === undefined ? null : this.data!.value;
    this.answer(this.data!);
  }

  /**
   * The methods below exist to be overriden when comparing specific types like dates
   * @param saved - the saved answer that comes from the backed
   */

  // eslint-disable-next-line
  public getSavedValue(saved: Answer): any {
    return saved.value;
  }

  /**
   *
   * @param answer the answer to be saved in the backend
   */

  // eslint-disable-next-line
  public getValueToSave(answer: Answer): any {
    return answer.value;
  }
}
