import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { DialogService } from 'app/modules/common/services/dialog/dialog.service';
import { Answer, ReviewAction, ReviewData } from 'app/modules/questionnaire/model/questionnaire.model';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

/**
 * Component that handles answers review. It receives one or more answers to be reviewed at same time.
 */
@Component({
  selector: 'app-questionnaire-review',
  templateUrl: './questionnaire-review.component.html',
  styleUrls: ['./questionnaire-review.component.scss'],
})
export class QuestionnaireReviewComponent extends OnDestroyMixin implements OnInit {
  @Input() disabled: boolean = false;

  @Input() answers!: Answer[];

  @Input() reviewHandler?: string;

  @Output() errorhandler = new EventEmitter<string | null>();

  public reviewActions!: ReviewAction[];

  public reviewed: boolean = false;

  constructor(public service: QuestionnaireService, public dialog: DialogService) {
    super();
  }

  ngOnInit(): void {
    this.reviewed = this.isReviewed();
  }

  /**
   * Adds or removes review for the component answers.
   */
  public toggleReview(): void {
    this.setAnswersReviewing(true);

    const reviewData = {
      answers: this.answers,
      reviewHandler: this.reviewHandler,
      reviewActions: this.reviewActions,
    } as ReviewData;

    const action$ = this.isReviewed() ? this.service.removeReview(reviewData) : this.service.addReview(reviewData);

    action$
      .pipe(
        catchError((errorMessage) => {
          this.reviewed = false;
          this.setAnswersReviewing(false);
          this.dialog.closeLoadingDialog();
          this.errorhandler.emit(errorMessage);
          return EMPTY;
        }),
        tap((data) => {
          this.updateAnswers(data);
          this.errorhandler.emit(null);
        }),
        tap(() => ((this.reviewed = this.isReviewed()), this.setAnswersReviewing(false), this.dialog.closeLoadingDialog()))
      )
      .subscribe();
  }

  /**
   * Updates current answers states coping review data and version.
   * @param data Updated review data.
   */
  private updateAnswers(data: ReviewData): void {
    data.answers.forEach((answer, index) => {
      this.answers[index].reviewed = answer.reviewed;
      this.answers[index].version = answer.version;
    });
  }

  /**
   * Review state of the answers.
   */
  public isReviewed(): boolean {
    return this.answers && this.answers[0].reviewed != null;
  }

  /**
   * Review state of the answers.
   */
  public isReviewing(): boolean {
    return this.answers && this.answers[0].reviewing!;
  }

  setAnswersReviewing(reviewing: boolean): void {
    this.answers.forEach((answer) => {
      answer.reviewing = reviewing;
    });
  }
}
