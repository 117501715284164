<div fxLayout="column" class="form-section">
  <span class="question-info">Client Breakdown</span>
  <div fxLayout="row" fxLayoutGap="8px">
    @for (table of tables; track table.value) {
      <div fxFlex="33" fxLayout="column" class="form-section">
        <p class="small-info">{{ table.label }}</p>
        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
          <div class="abs-bold" fxFlex="33">Client Type</div>
          <div class="abs-bold" fxFlex="23">%</div>
          <div></div>
        </div>
        @for (answer of getAllAnswers(table.answers); track answer.id; let isLast = $last) {
          <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
            <div fxFlex="33">
              @if (answer) {
                <app-questionnaire-text-answer
                  [data]="answer"
                  (errorhandler)="onError($event)"
                  [disabled]="isReviewed() || !!answer.reviewed || !!answer.reviewing"
                ></app-questionnaire-text-answer>
              }
            </div>
            <div fxFlex="23">
              @if (getAnswer(table.value, answer.index)) {
                <app-questionnaire-decimal-answer
                  [data]="getAnswer(table.value, answer.index)"
                  (errorhandler)="onError($event)"
                  [disabled]="isReviewed() || !!getAnswer(table.value, answer.index).reviewed || !!getAnswer(table.value, answer.index).reviewing"
                ></app-questionnaire-decimal-answer>
              }
            </div>
            <div>
              @if (table.fieldsToAdd && !showReview) {
                <div>
                  @if (isLast) {
                    <button mat-icon-button aria-label="Add" (click)="addField(table.fieldsToAdd, answer.index)" [disabled]="creatingAnswers">
                      <mat-icon>add_circle</mat-icon>
                    </button>
                  }
                </div>
              }
              @if (showReview) {
                <div>
                  @if (isLast) {
                    <app-questionnaire-review
                      [disabled]="isReviewed()"
                      [answers]="getAnswersForReview([table.answers, table.value])"
                      (errorhandler)="onError($event)"
                    ></app-questionnaire-review>
                  }
                </div>
              }
            </div>
          </div>
        }
        <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
          <div fxFlex="33" fxLayoutAlign="end center">Sum Total:</div>
          <div fxFlex="23" fxLayoutAlign="end center">
            <span [matTooltip]="(table.total | number: '1.2-2') || ''" class="text-ellipsis">{{ table.total | number: '1.2-2' }}%</span>
          </div>
          <div></div>
        </div>
      </div>
    }
  </div>
</div>

<div fxLayout="column" class="form-section">
  <p class="question-info">Have there been any changes in your firm to the following over the past 12 months?</p>
  <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="16px">
    @for (chkbox of checkboxes; track chkbox.name) {
      <app-questionnaire-checkbox-answer
        [label]="chkbox.label"
        [data]="getAnswer(chkbox.name)"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer(chkbox.name).reviewed || !!getAnswer(chkbox.name).reviewing"
      ></app-questionnaire-checkbox-answer>
    }
    @if (showReview) {
      <app-questionnaire-review
        [disabled]="isReviewed()"
        [answers]="
          getAnswersForReview([
            'DDQ_OVW_CHANGES_OWNERSHIP',
            'DDQ_OVW_CHANGES_STAFF',
            'DDQ_OVW_CHANGES_STRUCTURE',
            'DDQ_OVW_CHANGES_AUDITOR',
            'DDQ_OVW_CHANGES_ADMINISTRATOR',
            'DDQ_OVW_CHANGES_DIRECTORS',
            'DDQ_OVW_CHANGES_COMMENTS'
          ])
        "
        (errorhandler)="onError($event)"
      ></app-questionnaire-review>
    }
  </div>

  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <span class="abs-margin-half-top">
      If you answered "yes" to any of the above, please provide an explanation below (of any new service providers added within the past 12 months).
      Please also include details of any additional material events of which ABS should be aware.
    </span>
    <app-questionnaire-comment-answer
      [noMargin]="true"
      [noLabel]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_OVW_CHANGES_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_OVW_CHANGES_COMMENTS').reviewed || !!getAnswer('DDQ_OVW_CHANGES_COMMENTS').reviewing"
    ></app-questionnaire-comment-answer>

    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between abs-nomargin-bottom">
      <span>
        Describe any changes in the key investment and operational professionals who are involved in managing, trading and reconciling the portfolio.
      </span>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswers(['DDQ_OVW_Q1'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      [noMargin]="true"
      [noLabel]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_OVW_Q1')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_OVW_Q1').reviewed || !!getAnswer('DDQ_OVW_Q1').reviewing"
    ></app-questionnaire-comment-answer>
  </div>
</div>
