<h2 mat-dialog-title>{{ data.title }}</h2>
<div mat-dialog-content class="abs-margin-bottom">
  <p>
    This {{ data.entityType }} does not exist in the system, you can choose to keep the current {{ data.entityType }}, search for a
    {{ data.entityType }} in the list, or create a new one.
  </p>
  @if (!isDirector()) {
    <div fxLayout="row" class="abs-margin-bottom" fxLayoutAlign="start center" fxLayoutGap="16px">
      <span fxFlex>{{ data.current?.name }}</span>
      <button mat-raised-button color="primary" (click)="keepCurrentValue()">
        {{ data.current?.name ? 'Keep' : 'Keep blank' }}
      </button>
    </div>
  }
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
    <mat-form-field>
      <input type="text" matInput [formControl]="textInput" [matAutocomplete]="autoComplete" (blur)="onBlur()" />
      <mat-autocomplete #autoComplete="matAutocomplete" (optionSelected)="selectOption($event)" [displayWith]="display" panelWidth="fit-content">
        @for (option of options; track option.id) {
          <mat-option [value]="option">
            {{ option.name }}
          </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="saveNewValue()">Save</button>
  </div>
</div>
<div mat-dialog-actions fxLayoutAlign="end center">
  <button mat-raised-button (click)="closeDialog()">Close</button>
</div>
