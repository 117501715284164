import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { UserContext } from 'app/modules/common/model/user.model';
import {
  CompanyReviewData,
  Questionnaire,
  QuestionnaireStatus,
  QuestionnaireStatusLabel,
  QuestionnaireType,
} from 'app/modules/questionnaire/model/questionnaire.model';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { environment } from 'environments/environment';
import { EMPTY, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Component({
  template: '',
})
export abstract class QuestionnaireBaseComponent extends OnDestroyMixin implements OnInit {
  public errorMessage: string | null = null;

  public data: Questionnaire | null = null;

  public companyData?: CompanyReviewData;
  public questionnaireHash!: string;

  public userContext!: UserContext;

  public tabIndex = 0;

  constructor(protected route: ActivatedRoute, protected service: QuestionnaireService) {
    super();
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        catchError((error) => {
          this.errorMessage = error;
          return throwError(error);
        }),
        tap(({ hash }: Params) => (this.questionnaireHash = hash)),
        tap(() => {
          const ctx = this.route.snapshot.data.context;
          this.errorMessage = ctx.error;
          if (!ctx.error) {
            this.userContext = ctx;
            this.getQuestionnaireDetails();
          }
        }),
        untilComponentDestroyed(this)
      )
      .subscribe();
  }

  /**
   * Loads the Odd questionnaire details.
   */
  private getQuestionnaireDetails(): void {
    this.service
      .getQuestionnaire(this.questionnaireHash)
      .pipe(
        catchError((error) => {
          this.errorMessage = error;
          return EMPTY;
        }),
        tap((questionnaire: Questionnaire) => {
          this.data = questionnaire;
          if (this.isSent()) {
            this.service.startProgress(this.data.id).subscribe();
          } else if (this.isReviewing() && !this.isAttestation()) {
            this.getCompanyReviewData();
          }
        })
      )
      .subscribe();
  }

  private getCompanyReviewData(): void {
    this.service
      .getCompanyReviewData(this.data!.id)
      .pipe(
        catchError((error) => {
          this.errorMessage = error;
          return EMPTY;
        }),
        tap((reviewData: CompanyReviewData) => {
          this.companyData = reviewData;
        })
      )
      .subscribe();
  }

  /**
   * Handles an error of webform questionnaire
   * @param message Error message.
   */
  public onError(message: string | null): void {
    this.errorMessage = message;
  }

  /**
   * Handles an error of webform questionnaire
   * @param message Error message.
   */
  public clearError(): void {
    this.errorMessage = null;
  }

  /**
   * Checks if the questionnaire is finished.
   */
  public isFinished(): boolean {
    return QuestionnaireStatus.TO_BE_REVIEWED === this.data?.status;
  }

  /**
   * Checks if the questionnaire is finished.
   */
  public isSent(): boolean {
    return QuestionnaireStatus.SENT === this.data?.status;
  }

  /**
   * Returns the printed version (pdf) url of the questionnaire.
   */
  public getPdfUrl(section?: number): string {
    const token = window.localStorage.getItem('auth_token');
    let params = `?auth_token=${token}`;

    if (section !== undefined) {
      params += `&section=${section}`;
    }

    return `${environment.apiUrl}/questionnaire/${this.data?.id}/print${params}`;
  }

  public onTabChange(pos: number): void {
    this.tabIndex = pos;
  }

  /**
   * Get description for questionnaire status.
   */
  public getStatus(): string {
    return QuestionnaireStatusLabel.get(this.data!.status)!;
  }

  /**
   * Check questionnaire is reviewed.
   */
  public isReviewed(): boolean {
    return QuestionnaireStatus.REVIEWED === this.data?.status;
  }

  /**
   * Check questionnaire is under review.
   */
  public isReviewing(): boolean {
    return QuestionnaireStatus.TO_BE_REVIEWED === this.data?.status;
  }

  public isShowReview(): boolean {
    return this.isReviewed() || this.isReviewing();
  }

  public isAttestation(): boolean {
    return QuestionnaireType.ATTESTATION === this.data?.questionnaireType;
  }
}
