@if (generalInfo) {
  <div fxLayout="column" class="odd-form-section" fxLayoutGap="8px">
    <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
      @for (group of firstCheckboxGroup; track group.code) {
        <div fxLayout="column">
          <!-- Multiple = Checkboxes, allow multiple selection -->
          @if (generalInfo[group.code].multiple) {
            <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
              <span class="question-info">{{ group.label }}</span>
              @if (showReview) {
                <app-questionnaire-review
                  [disabled]="isReviewed()"
                  [answers]="getDefinedAnswersForReview([group.field, group.complField, group.extraDateCompl])"
                  (errorhandler)="onError($event)"
                  reviewHandler="GENERAL_INFO_HANDLER"
                ></app-questionnaire-review>
              }
            </div>
            @for (question of generalInfo[group.code].options; track question.id; let idx = $index) {
              @if (getAnswer(group.field, idx)) {
                <app-questionnaire-checkbox-answer
                  [label]="question.title"
                  [data]="getAnswer(group.field, idx)"
                  (errorhandler)="onError($event)"
                  [disabled]="isReviewed() || !!getAnswer(group.field, idx)?.reviewed || !!getAnswer(group.field, idx)?.reviewing"
                ></app-questionnaire-checkbox-answer>
              }
              @if (
                getAnswer(group.field, idx)?.value && group.complField && question.complementEnabled && question.complementDatatype.startsWith('text')
              ) {
                <app-questionnaire-text-answer
                  style="width: 50%; margin-top: 4px"
                  [label]="question.complementTitle"
                  [data]="getAnswer(group.complField)"
                  (errorhandler)="onError($event)"
                  [disabled]="isReviewed() || !!getAnswer(group.complField)?.reviewed || !!getAnswer(group.complField)?.reviewing"
                ></app-questionnaire-text-answer>
              }
            }
            @if (group.extraDateCompl) {
              <app-questionnaire-date-answer
                style="margin-top: 4px; width: 150px"
                label="As of"
                [data]="getAnswer(group.extraDateCompl)"
                (errorhandler)="onError($event)"
                [disabled]="isReviewed() || !!getAnswer(group.extraDateCompl)?.reviewed || !!getAnswer(group.extraDateCompl)?.reviewing"
              ></app-questionnaire-date-answer>
            }
          }
          <!-- Non-multiple = Radios, allow only one selection -->
          @if (!generalInfo[group.code].multiple) {
            <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
              <span class="question-info">{{ group.label }}</span>
              @if (showReview) {
                <app-questionnaire-review
                  [disabled]="isReviewed()"
                  [answers]="getDefinedAnswersForReview([group.field, group.complField])"
                  (errorhandler)="onError($event)"
                  reviewHandler="GENERAL_INFO_HANDLER"
                ></app-questionnaire-review>
              }
            </div>
            <app-questionnaire-radio-answer
              [multiAnswers]="getAllAnswers(group.field)"
              [options]="generalInfo[group.code].optionTitles!"
              (errorhandler)="onError($event)"
              layout="column"
              [disabled]="isReviewed() || !!getAnswer(group.field, 0)?.reviewed || !!getAnswer(group.field, 0)?.reviewing"
            ></app-questionnaire-radio-answer>
            @if (
              group.complField &&
              group.complementIndex &&
              getAnswer(group.field, group.complementIndex)?.value &&
              generalInfo[group.code].options[group.complementIndex].complementEnabled
            ) {
              @if (generalInfo[group.code].options[group.complementIndex].complementDatatype === 'date') {
                <app-questionnaire-date-answer
                  style="margin-top: 4px; width: 150px"
                  [label]="generalInfo[group.code].options[group.complementIndex].complementTitle"
                  [data]="getAnswer(group.complField)"
                  (errorhandler)="onError($event)"
                  [disabled]="isReviewed() || !!getAnswer(group.complField)?.reviewed || !!getAnswer(group.complField)?.reviewing"
                ></app-questionnaire-date-answer>
              }
              @if (generalInfo[group.code].options[group.complementIndex].complementDatatype.startsWith('text')) {
                <app-questionnaire-text-answer
                  style="width: 50%; margin-top: 4px"
                  [label]="generalInfo[group.code].options[group.complementIndex].complementTitle"
                  [data]="getAnswer(group.complField)"
                  (errorhandler)="onError($event)"
                  [disabled]="isReviewed() || !!getAnswer(group.complField)?.reviewed || !!getAnswer(group.complField)?.reviewing"
                ></app-questionnaire-text-answer>
              }
            }
          }
        </div>
      }
    </div>
  </div>
  <div fxLayout="column" class="odd-form-section">
    <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
      <span class="abs-bold">PM Liquid Net Worth</span>
      <span class="question-info abs-margin-half-top">
        Where do the Portfolio Managers(s) have the majority of their liquid net worth? Please provide an estimate dollar amount and/or % to all that
        apply.
      </span>
      <table class="table-spacing abs-align-left abs-margin-half-top" style="width: 85%">
        <tr>
          <th style="width: 75%"></th>
          <th style="width: 15%">Dollar Amount</th>
          <th style="width: 10%">%</th>
        </tr>
        @for (answer of getAllAnswers('STR_NET_INVESTMENT_NAME'); track answer.id; let isLast = $last) {
          <tr>
            <td>
              <div fxLayout="row">
                <span class="abs-margin-top">{{ answer.value }}</span>
                @if (isLast) {
                  <app-questionnaire-text-answer
                    class="abs-margin-left"
                    [data]="getAnswer('STR_NET_INVESTMENT_COMMENT', answer.index)"
                    (errorhandler)="onError($event)"
                    [disabled]="
                      isReviewed() ||
                      !!getAnswer('STR_NET_INVESTMENT_COMMENT', answer.index)?.reviewed ||
                      !!getAnswer('STR_NET_INVESTMENT_COMMENT', answer.index)?.reviewing
                    "
                  ></app-questionnaire-text-answer>
                }
              </div>
            </td>
            <td>
              <app-questionnaire-decimal-answer
                [data]="getAnswer('STR_NET_INVESTMENT_AMOUNT', answer.index)"
                (errorhandler)="onError($event)"
                [disabled]="
                  isReviewed() ||
                  !!getAnswer('STR_NET_INVESTMENT_AMOUNT', answer.index)?.reviewed ||
                  !!getAnswer('STR_NET_INVESTMENT_AMOUNT', answer.index)?.reviewing
                "
              ></app-questionnaire-decimal-answer>
            </td>
            <td>
              <app-questionnaire-decimal-answer
                [data]="getAnswer('STR_NET_INVESTMENT_PERCENTUAL', answer.index)"
                (errorhandler)="onError($event)"
                [disabled]="
                  isReviewed() ||
                  !!getAnswer('STR_NET_INVESTMENT_PERCENTUAL', answer.index)?.reviewed ||
                  !!getAnswer('STR_NET_INVESTMENT_PERCENTUAL', answer.index)?.reviewing
                "
              ></app-questionnaire-decimal-answer>
            </td>
          </tr>
        }
      </table>
      <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
        <app-questionnaire-comment-answer
          fxFlex
          label="Comment"
          [highlightChanges]="true"
          [data]="getAnswer('STR_NET_INVESTMENT_ADDITIONAL_COMMENT')"
          (errorhandler)="onError($event)"
          [disabled]="
            isReviewed() ||
            !!getAnswer('STR_NET_INVESTMENT_ADDITIONAL_COMMENT')?.reviewed ||
            !!getAnswer('STR_NET_INVESTMENT_ADDITIONAL_COMMENT')?.reviewing
          "
        ></app-questionnaire-comment-answer>
        @if (showReview) {
          <app-questionnaire-review
            [disabled]="isReviewed()"
            [answers]="
              getAnswersForReview([
                'STR_NET_INVESTMENT_NAME',
                'STR_NET_INVESTMENT_COMMENT',
                'STR_NET_INVESTMENT_AMOUNT',
                'STR_NET_INVESTMENT_PERCENTUAL',
                'STR_NET_INVESTMENT_ADDITIONAL_COMMENT'
              ])
            "
            (errorhandler)="onError($event)"
            reviewHandler="NET_INVESTMENT_HANDLER"
          ></app-questionnaire-review>
        }
      </div>
    </div>
  </div>
}
