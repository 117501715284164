import { Component, Input } from '@angular/core';
import { Answer } from 'app/modules/questionnaire/model/questionnaire.model';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { DateTime } from 'luxon';
import { QuestionnaireAnswerBaseComponent } from '../questionnaire-answer-util/questionnaire-answer-base.component';

@Component({
  selector: 'app-questionnaire-date-answer',
  templateUrl: './questionnaire-date-answer.component.html',
})
export class QuestionnaireDateAnswerComponent extends QuestionnaireAnswerBaseComponent {
  @Input() noIcon: boolean = false;

  constructor(protected service: QuestionnaireService) {
    super(service);
  }

  /**
   *  Custom valueChange to check date validity before calling update,
   *  also it needs to convert the date to a string format due to the Questionnaire backend
   */
  public onValueChange(): void {
    if (this.isValidDate(this.data!.valueAsDate)) {
      this.answer({ ...this.data!, value: this.getValueToSave(this.data!) });
    }
  }

  /*
   * Check date validity
   */
  private isValidDate(dt?: Date): boolean {
    return dt === null || (dt instanceof Date && !isNaN(dt.getTime()));
  }

  public getSavedValue(saved: Answer): string | null {
    const date = saved?.valueAsDate ? DateTime.fromISO(String(saved.valueAsDate)).toISODate() : null;
    return date;
  }

  public getValueToSave(answer: Answer): string | null {
    const date = answer?.valueAsDate ? DateTime.fromISO(answer.valueAsDate.toISOString()).toISODate() : null;
    return date;
  }
}
