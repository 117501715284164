import { Pipe, PipeTransform } from '@angular/core';
import { take, tap } from 'rxjs/operators';
import { Datatype, DatatypeService } from '../services/datatype.service';

@Pipe({
  name: 'datatype',
  pure: false,
})
export class DatatypePipe implements PipeTransform {
  private prevType?: string;
  private datatypeOptions?: { [key: string]: Datatype };

  constructor(private service: DatatypeService) {}

  transform(value: string, ...args: string[]): string {
    const type = args[0];
    const field = args.length === 2 && args[1] === 'abbr' ? 'abbreviation' : 'label';

    if (value !== null && value !== undefined) {
      if (type !== this.prevType) {
        this.prevType = type;

        this.service
          .get(type)
          .pipe(
            tap((items: Datatype[]) => {
              this.datatypeOptions = this.toMap(items);
              return this.datatypeOptions[value][field];
            }),
            take(1)
          )
          .subscribe();
      } else if (this.datatypeOptions) {
        return this.datatypeOptions[value][field];
      }
    }

    return '';
  }

  private toMap(items: Datatype[]): { [key: string]: Datatype } {
    return items.reduce((map: { [key: string]: Datatype }, obj) => {
      map[obj.value] = obj;
      return map;
    }, {});
  }
}
