import { Component } from '@angular/core';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { OddWebformTabBaseComponent } from '../odd-webform-tab-base/odd-webform-tab-base.component';

@Component({
  selector: 'app-odd-webform-it-cyber',
  templateUrl: './odd-webform-it-cyber.component.html',
  styleUrls: ['../odd-webform-tab-base/odd-webform-tab-base.component.scss'],
})
export class OddWebformItCyberComponent extends OddWebformTabBaseComponent {
  comments = [
    {
      label: 'What IT improvements have you made in the past 12 months?',
      name: 'IT_CYBER_Q1',
    },
    {
      label:
        'Is the Firm’s BCP plan tested at least annually? Please describe how plans are tested (in mock environments, in production parallel, or in production environments) and date of last test.',
      name: 'IT_CYBER_Q2',
    },
    {
      label:
        'Please describe the firm’s security awareness program for employees regarding Privacy and Information Security/Cybersecurity including any training and frequency.',
      name: 'IT_CYBER_Q3',
    },
    {
      label:
        'Has the firm identified any data breach or other cyber security event? Has the firm enacted its incident response or disaster recovery plan? If so, please explain.',
      name: 'IT_CYBER_Q14',
    },
    {
      label:
        'What are you doing to assess your service providers including (but not limited to) prime brokers, custodians, administrator, and IT provider?',
      name: 'IT_CYBER_Q4',
    },
    {
      label:
        'Please describe your defense against external security threats. Does the Firm utilize an intrusion detection system to prevent unauthorized access?',
      name: 'IT_CYBER_Q5',
    },
    {
      label: 'Please describe any vulnerability testing done on the firm’s IT infrastructure. By who? When? What were the results?',
      name: 'IT_CYBER_Q6',
    },
    {
      label: 'Does the Firm have an information security incident response plan that addresses data breaches and notification? Please describe.',
      name: 'IT_CYBER_Q7',
    },
    {
      label:
        'Does the Firm employ a system access administration process to configure, approve and revoke user access based on Least Privilege, Need-to-Know and Segregation of Duties, including regular review of such access for appropriateness?',
      name: 'IT_CYBER_Q8',
    },
    {
      label: 'Does the Firm encrypt all Firm issued laptops, USB devices and mobile devices?',
      name: 'IT_CYBER_Q9',
    },
    {
      label:
        'Do employees have access to personal email, file sharing sites (e.g. DropBox), and/or third-party social networking sites from their work computers?',
      name: 'IT_CYBER_Q10',
    },
    {
      label:
        'Is your IT hosted in a cloud environment? If so, is it private or public? Please list all services/companies you are using to host your IT?',
      name: 'IT_CYBER_Q11',
    },
  ];

  dropdowns = [
    {
      label: 'Do you use multi-factor authentication wherever possible?',
      name: 'IT_CYBER_Q12',
      commentLabel: 'Please provide a brief description.',
      comment: 'IT_CYBER_Q12_COMMENTS',
    },
    {
      label: 'Have you run a tabletop test of your Incident Response Plan?',
      name: 'IT_CYBER_Q13',
      commentLabel: 'Please provide a brief description.',
      comment: 'IT_CYBER_Q13_COMMENTS',
    },
  ];

  constructor(protected service: QuestionnaireService) {
    super(service);
  }
}
