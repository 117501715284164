<div fxLayout="column" class="odd-form-section">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
    <div class="abs-bold format-small-fields">Service Provider</div>
    <div fxFlex="20" class="abs-bold">Company</div>
    <div fxFlex="13" class="abs-bold">Location</div>
    <div fxFlex="13" class="abs-bold">Main Contact</div>
    <div fxFlex="14" class="abs-bold">Email</div>
    <div fxFlex="12" class="abs-bold">Phone Number</div>
    <div fxFlex="10"></div>
  </div>

  @for (answer of getAllAnswers('SP_SERV_PROV_NAME'); track answer.id) {
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <div class="format-small-fields">{{ answer.value | datatype: 'serviceProvider' }}</div>
      <div fxFlex="20">
        <app-questionnaire-company-answer
          [companyTypes]="companyService.getCommonSearchTypes()"
          [data]="getAnswer('SP_SERV_PROV_COMPANY', answer.index)"
          (errorhandler)="onError($event)"
          (selectedCompany)="onCompanySelect($event)"
          [inputDisabled]="
            isReviewed() ||
            !!getAnswer('SP_SERV_PROV_COMPANY', answer.index)?.reviewed ||
            !!getAnswer('SP_SERV_PROV_COMPANY', answer.index)?.reviewing
          "
        ></app-questionnaire-company-answer>
      </div>
      <div fxFlex="13">
        <app-questionnaire-text-answer
          [data]="getAnswer('SP_SERV_PROV_LOCATION', answer.index)"
          (errorhandler)="onError($event)"
          [changeListener]="companyEmitter.asObservable()"
          [disabled]="
            isReviewed() ||
            !!getAnswer('SP_SERV_PROV_LOCATION', answer.index)?.reviewed ||
            !!getAnswer('SP_SERV_PROV_LOCATION', answer.index)?.reviewing
          "
        ></app-questionnaire-text-answer>
      </div>
      <div fxFlex="13">
        <app-questionnaire-text-answer
          [data]="getAnswer('SP_SERV_PROV_CONTACT', answer.index)"
          (errorhandler)="onError($event)"
          [disabled]="
            isReviewed() ||
            !!getAnswer('SP_SERV_PROV_CONTACT', answer.index)?.reviewed ||
            !!getAnswer('SP_SERV_PROV_CONTACT', answer.index)?.reviewing
          "
        ></app-questionnaire-text-answer>
      </div>
      <div fxFlex="14">
        <app-questionnaire-text-answer
          [data]="getAnswer('SP_SERV_PROV_EMAIL', answer.index)"
          (errorhandler)="onError($event)"
          [disabled]="
            isReviewed() || !!getAnswer('SP_SERV_PROV_EMAIL', answer.index)?.reviewed || !!getAnswer('SP_SERV_PROV_EMAIL', answer.index)?.reviewing
          "
        ></app-questionnaire-text-answer>
      </div>
      <div fxFlex="12">
        <app-questionnaire-text-answer
          [data]="getAnswer('SP_SERV_PROV_PHONE', answer.index)"
          (errorhandler)="onError($event)"
          [disabled]="
            isReviewed() || !!getAnswer('SP_SERV_PROV_PHONE', answer.index)?.reviewed || !!getAnswer('SP_SERV_PROV_PHONE', answer.index)?.reviewing
          "
        ></app-questionnaire-text-answer>
      </div>
      <div fxFlex="10">
        @if (showReview) {
          <app-questionnaire-company-review
            [disabled]="isReviewed()"
            [answers]="[
              getAnswer('SP_SERV_PROV_NAME', answer.index)!,
              getAnswer('SP_SERV_PROV_COMPANY', answer.index)!,
              getAnswer('SP_SERV_PROV_LOCATION', answer.index)!,
              getAnswer('SP_SERV_PROV_CONTACT', answer.index)!,
              getAnswer('SP_SERV_PROV_EMAIL', answer.index)!,
              getAnswer('SP_SERV_PROV_PHONE', answer.index)!,
            ]"
            (errorhandler)="onError($event)"
            reviewHandler="SERVICE_PROVIDER_HANDLER"
            [companyData]="companyData"
            companyField="SP_SERV_PROV_COMPANY"
            [serviceProviderType]="getAnswer('SP_SERV_PROV_NAME', answer.index)?.value"
            contactField="SP_SERV_PROV_CONTACT"
            conflictTitle="New Service Provider"
          ></app-questionnaire-company-review>
        }
      </div>
    </div>
  }

  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <div fxLayout="row" class="abs-nomargin" fxLayoutAlign="start center" fxLayoutGap="16px">
      <span class="question-info">
        Please describe your firm's procedures for monitoring your service providers. How often are their procedures reviewed? How often are their
        disaster recovery procedures tested?
      </span>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="[getAnswer('SP_DESCRIPTION')!]"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      [noMargin]="true"
      [noLabel]="true"
      [highlightChanges]="true"
      [data]="getAnswer('SP_DESCRIPTION')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('SP_DESCRIPTION')?.reviewed || !!getAnswer('SP_DESCRIPTION')?.reviewing"
    ></app-questionnaire-comment-answer>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <app-questionnaire-checkbox-answer
        label="Do you have a SOC 1 or 2, SSAE16, or similar report on your eligible service providers on file?"
        [data]="getAnswer('SP_SSAE16')"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer('SP_SSAE16')?.reviewed || !!getAnswer('SP_SSAE16')?.reviewing"
      ></app-questionnaire-checkbox-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="[getAnswer('SP_SSAE16')!]"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
  </div>
</div>
