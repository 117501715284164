import { Component } from '@angular/core';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { OddWebformTabBaseComponent } from '../odd-webform-tab-base/odd-webform-tab-base.component';

@Component({
  selector: 'app-odd-webform-firm-info',
  templateUrl: './odd-webform-firm-info.component.html',
  styleUrls: ['../odd-webform-tab-base/odd-webform-tab-base.component.scss'],
})
export class OddWebformFirmInfoComponent extends OddWebformTabBaseComponent {
  comments = [
    {
      label: `Does your firm have any managed accounts? Is it managed pari passu with the main funds? Do the managed accounts have any potential fee breaks or advantageous liquidity?`,
      name: `FIRM_INFO_Q1`,
    },
    {
      label: `Are you ever cross-trading internally between funds, managed accounts, SPVs, etc? If yes, please explain.`,
      name: `FIRM_INFO_Q2`,
    },
    {
      label: `Has your firm launched any new funds in the last 12 months? Does your firm run any other structures with overlapping positions (i.e. in a regulated format like a UCITS fund)? If yes, are liquidity terms and fees different from our invested fund? If yes, please explain.`,
      name: `FIRM_INFO_Q3`,
    },
    {
      label: `Are there any principal/internal withdrawals planned from the fund in the next year? If yes, please explain.`,
      name: `FIRM_INFO_Q4`,
    },
    {
      label: `Please briefly describe the keyman provisions for the firm. Has the firm determined a plan of succession should any principal leave his position? If yes, please explain.`,
      name: `FIRM_INFO_Q5`,
    },
    {
      label: `Does the firm utilize any sort of risk aggregation/ transparency reporting (RiskMetrics, MeasureRisk@,etc)?`,
      name: `FIRM_INFO_Q6`,
    },
    {
      label: `Have there been any changes to your personal trading or Code of Ethics policies in the past year?`,
      name: `FIRM_INFO_Q8`,
    },
    {
      label: `Have there been any personal trading or Code of Ethics violations in the past year? What were the violations and ramifications?`,
      name: `FIRM_INFO_Q9`,
    },
    {
      label: `What, if any, percentage of staff continues to work remotely? How is this broken down between investment and back office staff?`,
      name: `FIRM_INFO_Q10`,
    },

    {
      label: `What changes or policies and procedures does the firm plan to implement upon the SEC's adoption of the Private Fund Advisers Rule going into effect in 2024?`,
      name: `FIRM_INFO_Q11`,
    },
    {
      label: `With the Private Fund Rules going into effect in 2024, what is the firm's current thinking on how and what new information will be shared across all investors? Or do you expect to decrease transparency to certain investors as a result of the rules?`,
      name: `FIRM_INFO_Q12`,
    },
    {
      label: `What impact will preferential treatment, as defined in the Private Fund Advisers Rule (Liquidity, Transparency, Economic Terms, or any other preferential terms), that you currently provide to investors be affected by the Rule's implementation?`,
      name: `FIRM_INFO_Q13`,
    },
  ];

  constructor(protected service: QuestionnaireService) {
    super(service);
  }
}
