import { DecimalPipe } from '@angular/common';
import { Directive, ElementRef, forwardRef, HostListener, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';

@Directive({
  selector: 'input[appDecimalInput]',
  providers: [
    {
      provide: DecimalPipe,
    },
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: DecimalInputDirective },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DecimalInputDirective),
      multi: true,
    },
  ],
})
export class DecimalInputDirective {
  private $val!: string;

  private numDecimals = 2;

  constructor(private elementRef: ElementRef<HTMLInputElement>, private decimalPipe: DecimalPipe) {}

  @Input('decimals')
  get decimals(): number {
    return this.numDecimals;
  }
  set decimals(val: number | undefined) {
    if (val) {
      this.numDecimals = val;
    }
  }

  @Input('value')
  get value(): string {
    return this.$val;
  }
  set value(value: string) {
    this.$val = value;
    this.formatValue(value);
  }

  private formatValue(value: string): void {
    let $v = value && String(value).replace(/[^\d.-]/g, '');

    $v = this.getCorrespondingValue($v);

    if ($v !== null && !isNaN(+$v)) {
      this.elementRef.nativeElement.value =
        this.decimalPipe.transform($v.toString(), '1.' + this.numDecimals + '-' + this.numDecimals, 'en-US') || '';
    } else {
      this.elementRef.nativeElement.value = '';
    }
  }

  private unFormatValue(): void {
    const value = this.elementRef.nativeElement.value;
    this.$val = value?.replace(/[^\d.-]/g, '');
    if (value) {
      this.elementRef.nativeElement.value = this.$val.toString();
    } else {
      this.elementRef.nativeElement.value = '';
    }
  }

  @HostListener('input', ['$event.target.value'])
  // eslint-disable-next-line
  onInput(value: string) {
    // keep only digits, dots, minus sign
    let $v = value.replace(/[^\d.-]/g, '');

    // keep one minus sign in the beginning, one dot
    $v = $v.replace(/(?!^)-/g, '');
    this.$val = $v.replace('.', '%FD%').replace(/\./g, '').replace('%FD%', '.');

    // save the numeric value typed in the server
    const numVal = parseFloat(this.getCorrespondingValue(this.$val));
    this._onChange(!isNaN(numVal) ? numVal : null);

    // set the valid string value typed in the field
    this.elementRef.nativeElement.value = this.$val;
  }

  private getCorrespondingValue(val: string): string {
    if (val === '.') {
      return '0.';
    } else if (val === '-') {
      return '-0';
    } else if (val === '-.') {
      return '-0.';
    }

    return val;
  }

  @HostListener('blur')
  // eslint-disable-next-line
  _onBlur() {
    this.formatValue(this.$val);
  }

  @HostListener('focus')
  // eslint-disable-next-line
  onFocus() {
    this.unFormatValue();
  }

  // eslint-disable-next-line
  _onChange(value: any): void {}

  // eslint-disable-next-line
  writeValue(value: any) {
    this.$val = value;
    this.formatValue(this.$val); // format Value
  }

  // eslint-disable-next-line
  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
  }

  // eslint-disable-next-line
  registerOnTouched() {}
}
