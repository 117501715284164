<div fxLayout="column" class="odd-form-section" fxLayoutGap="4px">
  <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
    <app-questionnaire-text-answer
      fxFlex="80"
      label="Investment Management Company"
      [data]="getAnswer('OVW_COMPANY_NAME')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('OVW_COMPANY_NAME')?.reviewed || !!getAnswer('OVW_COMPANY_NAME')?.reviewing"
    ></app-questionnaire-text-answer>
    @if (showReview) {
      <app-questionnaire-review
        [disabled]="isReviewed()"
      [answers]="
        getAnswersForReview([
          'OVW_SEC_CONTACT_NAME',
          'OVW_SEC_CONTACT_TITLE',
          'OVW_SEC_CONTACT_PHONE',
          'OVW_SEC_CONTACT_EMAIL',
          'OVW_PRIM_CONTACT_NAME',
          'OVW_PRIM_CONTACT_TITLE',
          'OVW_PRIM_CONTACT_PHONE',
          'OVW_PRIM_CONTACT_EMAIL',
          'OVW_COMPANY_NAME',
          'OVW_COMPANY_ADDRESS',
          'OVW_COMPANY_CITY',
          'OVW_COMPANY_STATE',
          'OVW_COMPANY_ZIP',
          'OVW_COMPANY_COUNTRY',
          'OVW_COMPANY_MAIN_PHONE',
          'OVW_COMPANY_WEB_SITE'
        ])
      "
        reviewHandler="OVERVIEW_COMPANY_HANDLER"
        (errorhandler)="onError($event)"
      ></app-questionnaire-review>
    }
  </div>
  <app-questionnaire-text-answer
    style="width: 85%"
    label="Primary Office Address"
    [data]="getAnswer('OVW_COMPANY_ADDRESS')"
    (errorhandler)="onError($event)"
    [disabled]="isReviewed() || !!getAnswer('OVW_COMPANY_ADDRESS')?.reviewed || !!getAnswer('OVW_COMPANY_ADDRESS')?.reviewing"
  ></app-questionnaire-text-answer>

  <div fxLayout="row" fxLayoutGap="16px">
    <app-questionnaire-text-answer
      style="width: 30%"
      label="City"
      [data]="getAnswer('OVW_COMPANY_CITY')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('OVW_COMPANY_CITY')?.reviewed || !!getAnswer('OVW_COMPANY_CITY')?.reviewing"
    ></app-questionnaire-text-answer>
    <app-questionnaire-text-answer
      style="width: 25%"
      label="State/County/Province"
      [data]="getAnswer('OVW_COMPANY_STATE')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('OVW_COMPANY_STATE')?.reviewed || !!getAnswer('OVW_COMPANY_STATE')?.reviewing"
    ></app-questionnaire-text-answer>
    <app-questionnaire-text-answer
      style="width: 10%"
      label="Zip"
      [data]="getAnswer('OVW_COMPANY_ZIP')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('OVW_COMPANY_ZIP')?.reviewed || !!getAnswer('OVW_COMPANY_ZIP')?.reviewing"
    ></app-questionnaire-text-answer>
    <app-questionnaire-text-answer
      style="width: 25%"
      label="Country"
      [data]="getAnswer('OVW_COMPANY_COUNTRY')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('OVW_COMPANY_COUNTRY')?.reviewed || !!getAnswer('OVW_COMPANY_COUNTRY')?.reviewing"
    ></app-questionnaire-text-answer>
  </div>

  <div fxLayout="row" fxLayoutGap="16px">
    <app-questionnaire-text-answer
      style="width: 20%"
      label="Main Telephone"
      [data]="getAnswer('OVW_COMPANY_MAIN_PHONE')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('OVW_COMPANY_MAIN_PHONE')?.reviewed || !!getAnswer('OVW_COMPANY_MAIN_PHONE')?.reviewing"
    ></app-questionnaire-text-answer>
    <app-questionnaire-text-answer
      style="width: 35%"
      label="Company Website"
      [data]="getAnswer('OVW_COMPANY_WEB_SITE')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('OVW_COMPANY_WEB_SITE')?.reviewed || !!getAnswer('OVW_COMPANY_WEB_SITE')?.reviewing"
    ></app-questionnaire-text-answer>
  </div>

  <app-questionnaire-text-answer
    style="width: 60%"
    label="Primary Ops Contact"
    [data]="getAnswer('OVW_PRIM_CONTACT_NAME')"
    (errorhandler)="onError($event)"
    [disabled]="isReviewed() || !!getAnswer('OVW_PRIM_CONTACT_NAME')?.reviewed || !!getAnswer('OVW_PRIM_CONTACT_NAME')?.reviewing"
  ></app-questionnaire-text-answer>
  <div fxLayout="row" fxLayoutGap="16px">
    <app-questionnaire-text-answer
      style="width: 40%"
      label="Title"
      [data]="getAnswer('OVW_PRIM_CONTACT_TITLE')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('OVW_PRIM_CONTACT_TITLE')?.reviewed || !!getAnswer('OVW_PRIM_CONTACT_TITLE')?.reviewing"
    ></app-questionnaire-text-answer>
    <app-questionnaire-text-answer
      style="width: 20%"
      label="Phone #"
      [data]="getAnswer('OVW_PRIM_CONTACT_PHONE')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('OVW_PRIM_CONTACT_PHONE')?.reviewed || !!getAnswer('OVW_PRIM_CONTACT_PHONE')?.reviewing"
    ></app-questionnaire-text-answer>
    <app-questionnaire-text-answer
      style="width: 35%"
      label="Email"
      [data]="getAnswer('OVW_PRIM_CONTACT_EMAIL')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('OVW_PRIM_CONTACT_EMAIL')?.reviewed || !!getAnswer('OVW_PRIM_CONTACT_EMAIL')?.reviewing"
    ></app-questionnaire-text-answer>
  </div>

  <app-questionnaire-text-answer
    style="width: 60%"
    label="Secondary Ops Contact"
    [data]="getAnswer('OVW_SEC_CONTACT_NAME')"
    (errorhandler)="onError($event)"
    [disabled]="isReviewed() || !!getAnswer('OVW_SEC_CONTACT_NAME')?.reviewed || !!getAnswer('OVW_SEC_CONTACT_NAME')?.reviewing"
  ></app-questionnaire-text-answer>
  <div fxLayout="row" fxLayoutGap="16px">
    <app-questionnaire-text-answer
      style="width: 40%"
      label="Title"
      [data]="getAnswer('OVW_SEC_CONTACT_TITLE')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('OVW_SEC_CONTACT_TITLE')?.reviewed || !!getAnswer('OVW_SEC_CONTACT_TITLE')?.reviewing"
    ></app-questionnaire-text-answer>
    <app-questionnaire-text-answer
      style="width: 20%"
      label="Phone #"
      [data]="getAnswer('OVW_SEC_CONTACT_PHONE')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('OVW_SEC_CONTACT_PHONE')?.reviewed || !!getAnswer('OVW_SEC_CONTACT_PHONE')?.reviewing"
    ></app-questionnaire-text-answer>
    <app-questionnaire-text-answer
      style="width: 35%"
      label="Email"
      [data]="getAnswer('OVW_SEC_CONTACT_EMAIL')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('OVW_SEC_CONTACT_EMAIL')?.reviewed || !!getAnswer('OVW_SEC_CONTACT_EMAIL')?.reviewing"
    ></app-questionnaire-text-answer>
  </div>
</div>
