<div fxLayout="column" class="odd-form-section" fxLayoutGap="8px">
  <span class="abs-nomargin question-info">Confirm your prime broker and custodians and the % of the fund's AUM including cash.</span>

  <!-- Prime Broker -->

  <div fxFlex fxLayoutGap="8px">
    <div fxFlex="65" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <div fxFlex="100"></div>
    </div>

    <div fxFlex="30" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <div class="abs-bold set-text-decoration" fxFlex="64">Cash Rates (ie ff +/- x bps)</div>
    </div>
  </div>

  <div fxFlex fxLayoutGap="8px">
    <div fxFlex="65" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <div class="no-wrap" fxFlex="3"></div>
      <div class="abs-bold" fxFlex="19">Name of Prime Broker</div>
      <div class="abs-bold" fxFlex="13">Jurisdication / Locale</div>
      <div class="abs-bold" fxFlex="11">Main Contact</div>
      <div class="abs-bold" fxFlex="12">Email</div>
      <div class="abs-bold" fxFlex="11">Phone Number</div>
      <div class="abs-bold" fxFlex="11">Contract date as of</div>
      <div class="abs-bold" fxFlex="11">% of Fund Assets</div>
    </div>

    <div fxFlex="30" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <div class="abs-bold" fxFlex="23">as of date</div>
      <div class="abs-bold" fxFlex="20">Debit</div>
      <div class="abs-bold" fxFlex="20">Credit</div>
      <div fxFlex></div>
    </div>
  </div>

  @for (answer of getAllAnswers('PB_PRIME_BROKER_NAME'); track answer.id; let isLast = $last) {
    <div fxFlex fxLayoutGap="8px">
      <div fxFlex="65" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <div class="no-wrap" fxFlex="3">{{ answer.index + 1 }} -</div>
        <div fxFlex="19">
          <app-questionnaire-company-answer
            [companyTypes]="[companyType.PRIME_BROKER]"
            [data]="answer"
            (errorhandler)="onError($event)"
            (selectedCompany)="onPrimeBrokerSelect($event)"
            [inputDisabled]="isReviewed() || !!answer.reviewed || !!answer.reviewing"
          ></app-questionnaire-company-answer>
        </div>
        <div fxFlex="13">
          <app-questionnaire-text-answer
            [data]="getAnswer('PB_PRIME_BROKER_LOCALE', answer.index)"
            (errorhandler)="onError($event)"
            [changeListener]="primeBrokerEmitter.asObservable()"
            [disabled]="
              isReviewed() ||
              !!getAnswer('PB_PRIME_BROKER_LOCALE', answer.index)?.reviewed ||
              !!getAnswer('PB_PRIME_BROKER_LOCALE', answer.index)?.reviewing
            "
          ></app-questionnaire-text-answer>
        </div>
        <div fxFlex="11">
          <app-questionnaire-text-answer
            [data]="getAnswer('PB_PRIME_BROKER_CONTACT', answer.index)"
            (errorhandler)="onError($event)"
            [disabled]="
              isReviewed() ||
              !!getAnswer('PB_PRIME_BROKER_CONTACT', answer.index)?.reviewed ||
              !!getAnswer('PB_PRIME_BROKER_CONTACT', answer.index)?.reviewing
            "
          ></app-questionnaire-text-answer>
        </div>
        <div fxFlex="12">
          <app-questionnaire-text-answer
            [data]="getAnswer('PB_PRIME_BROKER_EMAIL', answer.index)"
            (errorhandler)="onError($event)"
            [disabled]="
              isReviewed() ||
              !!getAnswer('PB_PRIME_BROKER_EMAIL', answer.index)?.reviewed ||
              !!getAnswer('PB_PRIME_BROKER_EMAIL', answer.index)?.reviewing
            "
          ></app-questionnaire-text-answer>
        </div>
        <div fxFlex="11">
          <app-questionnaire-text-answer
            [data]="getAnswer('PB_PRIME_BROKER_PHONE', answer.index)"
            (errorhandler)="onError($event)"
            [disabled]="
              isReviewed() ||
              !!getAnswer('PB_PRIME_BROKER_PHONE', answer.index)?.reviewed ||
              !!getAnswer('PB_PRIME_BROKER_PHONE', answer.index)?.reviewing
            "
          ></app-questionnaire-text-answer>
        </div>
        <div fxFlex="11">
          <app-questionnaire-date-answer
            [data]="getAnswer('PB_PRIME_BROKER_DATE', answer.index)"
            [noIcon]="true"
            (errorhandler)="onError($event)"
            [disabled]="
              isReviewed() ||
              !!getAnswer('PB_PRIME_BROKER_DATE', answer.index)?.reviewed ||
              !!getAnswer('PB_PRIME_BROKER_DATE', answer.index)?.reviewing
            "
          ></app-questionnaire-date-answer>
        </div>
        <div fxFlex="11">
          <app-questionnaire-decimal-answer
            [data]="getAnswer('PB_PRIME_BROKER_ASSETS', answer.index)"
            (errorhandler)="onError($event)"
            [disabled]="
              isReviewed() ||
              !!getAnswer('PB_PRIME_BROKER_ASSETS', answer.index)?.reviewed ||
              !!getAnswer('PB_PRIME_BROKER_ASSETS', answer.index)?.reviewing
            "
          ></app-questionnaire-decimal-answer>
        </div>
      </div>
      <div fxFlex="30" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <div fxFlex="23">
          <app-questionnaire-date-answer
            [data]="getAnswer('PB_PRIME_BROKER_AS_OF', answer.index)"
            [noIcon]="true"
            (errorhandler)="onError($event)"
            [disabled]="
              isReviewed() ||
              !!getAnswer('PB_PRIME_BROKER_AS_OF', answer.index)?.reviewed ||
              !!getAnswer('PB_PRIME_BROKER_AS_OF', answer.index)?.reviewing
            "
          ></app-questionnaire-date-answer>
        </div>
        <div fxFlex="20">
          <app-questionnaire-decimal-answer
            [data]="getAnswer('PB_PRIME_BROKER_DEBIT', answer.index)"
            (errorhandler)="onError($event)"
            [disabled]="
              isReviewed() ||
              !!getAnswer('PB_PRIME_BROKER_DEBIT', answer.index)?.reviewed ||
              !!getAnswer('PB_PRIME_BROKER_DEBIT', answer.index)?.reviewing
            "
          ></app-questionnaire-decimal-answer>
        </div>
        <div fxFlex="20">
          <app-questionnaire-decimal-answer
            [data]="getAnswer('PB_PRIME_BROKER_CREDIT', answer.index)"
            (errorhandler)="onError($event)"
            [disabled]="
              isReviewed() ||
              !!getAnswer('PB_PRIME_BROKER_CREDIT', answer.index)?.reviewed ||
              !!getAnswer('PB_PRIME_BROKER_CREDIT', answer.index)?.reviewing
            "
          ></app-questionnaire-decimal-answer>
        </div>
        <div fxFlex>
          @if (isLast && !showReview) {
            <button mat-icon-button aria-label="Add" (click)="addPrimeBroker(answer.index)" [disabled]="creatingAnswers">
              <mat-icon>add_circle</mat-icon>
            </button>
          }
          @if (showReview) {
            <app-questionnaire-company-review
              [disabled]="isReviewed()"
              [answers]="[
                getAnswer('PB_PRIME_BROKER_NAME', answer.index)!,
                getAnswer('PB_PRIME_BROKER_LOCALE', answer.index)!,
                getAnswer('PB_PRIME_BROKER_CONTACT', answer.index)!,
                getAnswer('PB_PRIME_BROKER_EMAIL', answer.index)!,
                getAnswer('PB_PRIME_BROKER_PHONE', answer.index)!,
                getAnswer('PB_PRIME_BROKER_DATE', answer.index)!,
                getAnswer('PB_PRIME_BROKER_ASSETS', answer.index)!,
                getAnswer('PB_PRIME_BROKER_AS_OF', answer.index)!,
                getAnswer('PB_PRIME_BROKER_DEBIT', answer.index)!,
                getAnswer('PB_PRIME_BROKER_CREDIT', answer.index)!
              ]"
              (errorhandler)="onError($event)"
              reviewHandler="PRIME_BROKER_HANDLER"
              [companyData]="companyData"
              companyField="PB_PRIME_BROKER_NAME"
              [serviceProviderType]="serviceProviderType.PRIME_BROKER.toString()"
              contactField="PB_PRIME_BROKER_CONTACT"
              conflictTitle="New Prime Broker"
            ></app-questionnaire-company-review>
          }
        </div>
      </div>
    </div>
  }

  <!-- Custodian -->

  <div fxFlex fxLayoutGap="8px">
    <div fxFlex="65" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <div class="no-wrap" fxFlex="3"></div>
      <div class="abs-bold" fxFlex="19">Name of Custodian</div>
      <div class="abs-bold" fxFlex="13">Jurisdication / Locale</div>
      <div class="abs-bold" fxFlex="11">Main Contact</div>
      <div class="abs-bold" fxFlex="12">Email</div>
      <div class="abs-bold" fxFlex="11">Phone Number</div>
      <div class="abs-bold" fxFlex="11">Contract date as of</div>
      <div class="abs-bold" fxFlex="11">% of Fund Assets</div>
    </div>

    <div fxFlex="30" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <div class="abs-bold" fxFlex="23">as of date</div>
      <div fxFlex="20"></div>
      <div fxFlex="20"></div>
      <div fxFlex></div>
    </div>
  </div>

  @for (answer of getAllAnswers('PB_CUSTODIAN_NAME'); track answer.id; let isLast = $last) {
    <div fxFlex fxLayoutGap="8px">
      <div fxFlex="65" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <div class="no-wrap" fxFlex="3">{{ answer.index + 1 }} -</div>
        <div fxFlex="19">
          <app-questionnaire-company-answer
            [companyTypes]="[companyType.CUSTODIAN]"
            [data]="answer"
            (errorhandler)="onError($event)"
            (selectedCompany)="onCustodianSelect($event)"
            [inputDisabled]="isReviewed() || !!answer.reviewed || !!answer.reviewing"
          ></app-questionnaire-company-answer>
        </div>
        <div fxFlex="13">
          <app-questionnaire-text-answer
            [data]="getAnswer('PB_CUSTODIAN_LOCALE', answer.index)"
            (errorhandler)="onError($event)"
            [changeListener]="custodianEmitter.asObservable()"
            [disabled]="
              isReviewed() ||
              !!getAnswer('PB_CUSTODIAN_LOCALE', answer.index)?.reviewed ||
              !!getAnswer('PB_CUSTODIAN_LOCALE', answer.index)?.reviewing
            "
          ></app-questionnaire-text-answer>
        </div>
        <div fxFlex="11">
          <app-questionnaire-text-answer
            [data]="getAnswer('PB_CUSTODIAN_CONTACT', answer.index)"
            (errorhandler)="onError($event)"
            [disabled]="
              isReviewed() ||
              !!getAnswer('PB_CUSTODIAN_CONTACT', answer.index)?.reviewed ||
              !!getAnswer('PB_CUSTODIAN_CONTACT', answer.index)?.reviewing
            "
          ></app-questionnaire-text-answer>
        </div>
        <div fxFlex="12">
          <app-questionnaire-text-answer
            [data]="getAnswer('PB_CUSTODIAN_EMAIL', answer.index)"
            (errorhandler)="onError($event)"
            [disabled]="
              isReviewed() || !!getAnswer('PB_CUSTODIAN_EMAIL', answer.index)?.reviewed || !!getAnswer('PB_CUSTODIAN_EMAIL', answer.index)?.reviewing
            "
          ></app-questionnaire-text-answer>
        </div>
        <div fxFlex="11">
          <app-questionnaire-text-answer
            [data]="getAnswer('PB_CUSTODIAN_PHONE', answer.index)"
            (errorhandler)="onError($event)"
            [disabled]="
              isReviewed() || !!getAnswer('PB_CUSTODIAN_PHONE', answer.index)?.reviewed || !!getAnswer('PB_CUSTODIAN_PHONE', answer.index)?.reviewing
            "
          ></app-questionnaire-text-answer>
        </div>
        <div fxFlex="11">
          <app-questionnaire-date-answer
            [data]="getAnswer('PB_CUSTODIAN_DATE', answer.index)"
            [noIcon]="true"
            (errorhandler)="onError($event)"
            [disabled]="
              isReviewed() || !!getAnswer('PB_CUSTODIAN_DATE', answer.index)?.reviewed || !!getAnswer('PB_CUSTODIAN_DATE', answer.index)?.reviewing
            "
          ></app-questionnaire-date-answer>
        </div>
        <div fxFlex="11">
          <app-questionnaire-decimal-answer
            [data]="getAnswer('PB_CUSTODIAN_ASSETS', answer.index)"
            (errorhandler)="onError($event)"
            [disabled]="
              isReviewed() ||
              !!getAnswer('PB_CUSTODIAN_ASSETS', answer.index)?.reviewed ||
              !!getAnswer('PB_CUSTODIAN_ASSETS', answer.index)?.reviewing
            "
          ></app-questionnaire-decimal-answer>
        </div>
      </div>
      <div fxFlex="30" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <div fxFlex="23">
          <app-questionnaire-date-answer
            [data]="getAnswer('PB_CUSTODIAN_AS_OF', answer.index)"
            [noIcon]="true"
            (errorhandler)="onError($event)"
            [disabled]="
              isReviewed() || !!getAnswer('PB_CUSTODIAN_AS_OF', answer.index)?.reviewed || !!getAnswer('PB_CUSTODIAN_AS_OF', answer.index)?.reviewing
            "
          ></app-questionnaire-date-answer>
        </div>
        <div fxFlex="20"></div>
        <div fxFlex="20"></div>
        <div fxFlex>
          @if (isLast && !showReview) {
            <button mat-icon-button aria-label="Add" (click)="addCustodian(answer.index)" [disabled]="creatingAnswers">
              <mat-icon>add_circle</mat-icon>
            </button>
          }
          @if (showReview) {
            <app-questionnaire-company-review
              [disabled]="isReviewed()"
              [answers]="[
                getAnswer('PB_CUSTODIAN_NAME', answer.index)!,
                getAnswer('PB_CUSTODIAN_LOCALE', answer.index)!,
                getAnswer('PB_CUSTODIAN_CONTACT', answer.index)!,
                getAnswer('PB_CUSTODIAN_EMAIL', answer.index)!,
                getAnswer('PB_CUSTODIAN_PHONE', answer.index)!,
                getAnswer('PB_CUSTODIAN_DATE', answer.index)!,
                getAnswer('PB_CUSTODIAN_ASSETS', answer.index)!,
                getAnswer('PB_CUSTODIAN_AS_OF', answer.index)!
              ]"
              (errorhandler)="onError($event)"
              reviewHandler="CUSTODIAN_HANDLER"
              [companyData]="companyData"
              companyField="PB_CUSTODIAN_NAME"
              [serviceProviderType]="serviceProviderType.CUSTODIAN.toString()"
              contactField="PB_CUSTODIAN_CONTACT"
              conflictTitle="New Custodian"
            ></app-questionnaire-company-review>
          }
        </div>
      </div>
    </div>
  }

  <!-- Depositary -->

  <div fxFlex fxLayoutGap="8px">
    <div fxFlex="65" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <div class="no-wrap" fxFlex="3"></div>
      <div class="abs-bold" fxFlex="19">Name of Depositary (if applicable)</div>
      <div class="abs-bold" fxFlex="13">Jurisdication / Locale</div>
      <div class="abs-bold" fxFlex="11">Main Contact</div>
      <div class="abs-bold" fxFlex="12">Email</div>
      <div class="abs-bold" fxFlex="11">Phone Number</div>
      <div class="abs-bold" fxFlex="11">Contract date as of</div>
      <div class="abs-bold" fxFlex="11">% of Fund Assets</div>
    </div>

    <div fxFlex="30" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <div class="abs-bold" fxFlex="23">as of date</div>
      <div class="abs-bold" fxFlex="20">Depositary Light</div>
      <div fxFlex="20"></div>
      <div fxFlex></div>
    </div>
  </div>

  @for (answer of getAllAnswers('PB_DEPOSITARY_NAME'); track answer.id; let isLast = $last) {
    <div fxFlex fxLayoutGap="8px">
      <div fxFlex="65" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <div class="no-wrap" fxFlex="3">{{ answer.index + 1 }} -</div>
        <div fxFlex="19">
          <app-questionnaire-company-answer
            [companyTypes]="[companyType.CUSTODIAN]"
            [data]="answer"
            (errorhandler)="onError($event)"
            (selectedCompany)="onDepositarySelect($event)"
            [inputDisabled]="isReviewed() || !!answer.reviewed || !!answer.reviewing"
          ></app-questionnaire-company-answer>
        </div>
        <div fxFlex="13">
          <app-questionnaire-text-answer
            [data]="getAnswer('PB_DEPOSITARY_LOCALE', answer.index)"
            (errorhandler)="onError($event)"
            [changeListener]="depositaryEmitter.asObservable()"
            [disabled]="
              isReviewed() ||
              !!getAnswer('PB_DEPOSITARY_LOCALE', answer.index)?.reviewed ||
              !!getAnswer('PB_DEPOSITARY_LOCALE', answer.index)?.reviewing
            "
          ></app-questionnaire-text-answer>
        </div>
        <div fxFlex="11">
          <app-questionnaire-text-answer
            [data]="getAnswer('PB_DEPOSITARY_CONTACT', answer.index)"
            (errorhandler)="onError($event)"
            [disabled]="
              isReviewed() ||
              !!getAnswer('PB_DEPOSITARY_CONTACT', answer.index)?.reviewed ||
              !!getAnswer('PB_DEPOSITARY_CONTACT', answer.index)?.reviewing
            "
          ></app-questionnaire-text-answer>
        </div>
        <div fxFlex="12">
          <app-questionnaire-text-answer
            [data]="getAnswer('PB_DEPOSITARY_EMAIL', answer.index)"
            (errorhandler)="onError($event)"
            [disabled]="
              isReviewed() ||
              !!getAnswer('PB_DEPOSITARY_EMAIL', answer.index)?.reviewed ||
              !!getAnswer('PB_DEPOSITARY_EMAIL', answer.index)?.reviewing
            "
          ></app-questionnaire-text-answer>
        </div>
        <div fxFlex="11">
          <app-questionnaire-text-answer
            [data]="getAnswer('PB_DEPOSITARY_PHONE', answer.index)"
            (errorhandler)="onError($event)"
            [disabled]="
              isReviewed() ||
              !!getAnswer('PB_DEPOSITARY_PHONE', answer.index)?.reviewed ||
              !!getAnswer('PB_DEPOSITARY_PHONE', answer.index)?.reviewing
            "
          ></app-questionnaire-text-answer>
        </div>
        <div fxFlex="11">
          <app-questionnaire-date-answer
            [data]="getAnswer('PB_DEPOSITARY_DATE', answer.index)"
            [noIcon]="true"
            (errorhandler)="onError($event)"
            [disabled]="
              isReviewed() || !!getAnswer('PB_DEPOSITARY_DATE', answer.index)?.reviewed || !!getAnswer('PB_DEPOSITARY_DATE', answer.index)?.reviewing
            "
          ></app-questionnaire-date-answer>
        </div>
        <div fxFlex="11">
          <app-questionnaire-decimal-answer
            [data]="getAnswer('PB_DEPOSITARY_ASSETS', answer.index)"
            (errorhandler)="onError($event)"
            [disabled]="
              isReviewed() ||
              !!getAnswer('PB_DEPOSITARY_ASSETS', answer.index)?.reviewed ||
              !!getAnswer('PB_DEPOSITARY_ASSETS', answer.index)?.reviewing
            "
          ></app-questionnaire-decimal-answer>
        </div>
      </div>
      <div fxFlex="30" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <div fxFlex="23">
          <app-questionnaire-date-answer
            [data]="getAnswer('PB_DEPOSITARY_AS_OF', answer.index)"
            [noIcon]="true"
            (errorhandler)="onError($event)"
            [disabled]="
              isReviewed() ||
              !!getAnswer('PB_DEPOSITARY_AS_OF', answer.index)?.reviewed ||
              !!getAnswer('PB_DEPOSITARY_AS_OF', answer.index)?.reviewing
            "
          ></app-questionnaire-date-answer>
        </div>
        <div fxFlex="20">
          <app-questionnaire-checkbox-answer
            [data]="getAnswer('PB_DEPOSITARY_LIGHT', answer.index)"
            (errorhandler)="onError($event)"
            [disabled]="
              isReviewed() ||
              !!getAnswer('PB_DEPOSITARY_LIGHT', answer.index)?.reviewed ||
              !!getAnswer('PB_DEPOSITARY_LIGHT', answer.index)?.reviewing
            "
          ></app-questionnaire-checkbox-answer>
        </div>
        <div fxFlex="20"></div>
        <div fxFlex>
          @if (isLast && !showReview) {
            <button mat-icon-button aria-label="Add" (click)="addDepositary(answer.index)" [disabled]="creatingAnswers">
              <mat-icon>add_circle</mat-icon>
            </button>
          }
          @if (showReview) {
            <app-questionnaire-company-review
              [disabled]="isReviewed()"
              [answers]="[
                getAnswer('PB_DEPOSITARY_NAME', answer.index)!,
                getAnswer('PB_DEPOSITARY_LOCALE', answer.index)!,
                getAnswer('PB_DEPOSITARY_CONTACT', answer.index)!,
                getAnswer('PB_DEPOSITARY_EMAIL', answer.index)!,
                getAnswer('PB_DEPOSITARY_PHONE', answer.index)!,
                getAnswer('PB_DEPOSITARY_DATE', answer.index)!,
                getAnswer('PB_DEPOSITARY_ASSETS', answer.index)!,
                getAnswer('PB_DEPOSITARY_AS_OF', answer.index)!
              ]"
              (errorhandler)="onError($event)"
              [companyData]="companyData"
              companyField="PB_DEPOSITARY_NAME"
              [serviceProviderType]="serviceProviderType.CUSTODIAN.toString()"
              contactField="PB_DEPOSITARY_CONTACT"
              conflictTitle="New Depositary"
            ></app-questionnaire-company-review>
          }
        </div>
      </div>
    </div>
  }

  <div fxFlex fxLayoutGap="8px">
    <div fxFlex="65" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <div class="abs-bold" fxFlex></div>
      <div class="abs-bold" fxFlex="69"></div>
      <div fxFlex="11">Total:</div>
      <div fxFlex="11">{{ custodianTotal | number: '1.2-2' }}%</div>
    </div>
  </div>

  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <div class="abs-nomargin" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <span class="question-info">What is your average general collateral rate on your shorts?</span>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="[getAnswer('PB_AVG_COLLATERAL_RATE')!]"
          (errorhandler)="onError($event)"
          reviewHandler="PRIME_BROKER_INFO_HANDLER"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-decimal-answer
      style="width: 10%"
      [data]="getAnswer('PB_AVG_COLLATERAL_RATE')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('PB_AVG_COLLATERAL_RATE')?.reviewed || !!getAnswer('PB_AVG_COLLATERAL_RATE')?.reviewing"
    ></app-questionnaire-decimal-answer>

    <div class="abs-nomargin" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <span class="question-info">What do you consider a Hard-to-Borrow rate?</span>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="[getAnswer('PB_HARD_TO_BORROW_RATE')!]"
          (errorhandler)="onError($event)"
          reviewHandler="PRIME_BROKER_INFO_HANDLER"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-decimal-answer
      style="width: 10%"
      [data]="getAnswer('PB_HARD_TO_BORROW_RATE')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('PB_HARD_TO_BORROW_RATE')?.reviewed || !!getAnswer('PB_HARD_TO_BORROW_RATE')?.reviewing"
    ></app-questionnaire-decimal-answer>

    @for (comment of lastComments; track comment.name) {
      @if (getAnswer(comment.name)) {
        <div fxLayout="row" fxLayoutAlign="start center" class="abs-nomargin abs-margin-between">
          <span class="question-info">{{ comment.label }}</span>
          @if (showReview) {
            <app-questionnaire-review
              [disabled]="isReviewed()"
              [answers]="[getAnswer(comment.name)!]"
              (errorhandler)="onError($event)"
            ></app-questionnaire-review>
          }
        </div>
        <app-questionnaire-comment-answer
          [noMargin]="true"
          [noLabel]="true"
          [highlightChanges]="true"
          [data]="getAnswer(comment.name)"
          (errorhandler)="onError($event)"
          [disabled]="isReviewed() || !!getAnswer(comment.name)?.reviewed || !!getAnswer(comment.name)?.reviewing"
        ></app-questionnaire-comment-answer>
      }
    }
  </div>
</div>
