<div fxLayout="column" class="form-section" fxLayoutGap="8px">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between abs-nomargin-bottom">
      <span>
        If the firm has Directors & Officers (D&O) and Errors & Omissions (E&O) insurance, what is the amount of coverage and premium? Please break
        down the premium paid by the Investment Manager, the Funds or both.
      </span>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswers(['DDQ_INSURANCE_Q2'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      [noLabel]="true"
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_INSURANCE_Q2')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_INSURANCE_Q2').reviewed || !!getAnswer('DDQ_INSURANCE_Q2').reviewing"
    ></app-questionnaire-comment-answer>

    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between abs-nomargin-bottom">
      <span>
        If any, please provide detail into what your insurance covers. Does it explicitly cover all potential liabilities stemming from regulatory
        inquiries? Does it include a cyber-security enhancement or endorsement?
      </span>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswers(['DDQ_INSURANCE_Q3'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      [noLabel]="true"
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_INSURANCE_Q3')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_INSURANCE_Q3').reviewed || !!getAnswer('DDQ_INSURANCE_Q3').reviewing"
    ></app-questionnaire-comment-answer>

    <span>
      As applicable, have there been any material changes to your firm's fidelity bond insurance, error & omission insurance, and/or director &
      officer insurance?
    </span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [highlightChanges]="true"
        [data]="getAnswer('DDQ_INSURANCE_Q1')"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer('DDQ_INSURANCE_Q1').reviewed || !!getAnswer('DDQ_INSURANCE_Q1').reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswers(['DDQ_INSURANCE_Q1', 'DDQ_INSURANCE_Q1_COMMENTS'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      label="If yes, describe."
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_INSURANCE_Q1_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_INSURANCE_Q1_COMMENTS').reviewed || !!getAnswer('DDQ_INSURANCE_Q1_COMMENTS').reviewing"
    ></app-questionnaire-comment-answer>
  </div>
</div>
