import { Component } from '@angular/core';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { DdqAdviserTabBaseComponent } from '../ddq-adviser-tab-base/ddq-adviser-tab-base.component';

@Component({
  selector: 'app-ddq-adviser-compliance',
  templateUrl: './ddq-adviser-compliance.component.html',
  styleUrls: ['../ddq-adviser-tab-base/ddq-adviser-tab-base.component.scss'],
})
export class DdqAdviserComplianceComponent extends DdqAdviserTabBaseComponent {
  public matComplianceQuestions = [
    {
      label: 'The code of ethics',
      question: 'DDQ_MAT_COMPLIANCE_Q1',
    },
    {
      label: 'The receipt and use of nonpublic information?',
      question: 'DDQ_MAT_COMPLIANCE_Q2',
    },
    {
      label:
        'Portfolio management processes (e.g., allocating investment opportunities, compliance with client investment objectives, policies and restrictions in the prospectus/statement of additional information, and other applicable regulatory requirements)?',
      question: 'DDQ_MAT_COMPLIANCE_Q3',
    },
    {
      label:
        'Trading practices (e.g., best execution obligations, soft dollar arrangements, allocation of aggregated trades among clients, cross trades)?',
      question: 'DDQ_MAT_COMPLIANCE_Q4',
    },
    {
      label: 'Trading errors, including monitoring, reporting, maintenance of records, and corrections?',
      question: 'DDQ_MAT_COMPLIANCE_Q5',
    },
    {
      label: 'The accurate creation and maintenance of required records, including electronic records?',
      question: 'DDQ_MAT_COMPLIANCE_Q6',
    },
    {
      label: 'The valuation of assets, if applicable?',
      question: 'DDQ_MAT_COMPLIANCE_Q7',
    },
    {
      label:
        'Any other Material Compliance Matters affecting the Separately Managed Accounts which you Sub-Advise for ABS Global Investments (Investment Manager) where Standard Chartered Bank or Northern Trust serves as the custodian ("SMA").',
      question: 'DDQ_MAT_COMPLIANCE_Q8',
    },
  ];

  public changesComplianceQuestions = [
    {
      label: 'The code of ethics and/or gifts and entertainment policies?',
      question: 'DDQ_COMPLIANCE_POLICY_Q1',
    },
    {
      label: 'The receipt and use of nonpublic information?',
      question: 'DDQ_COMPLIANCE_POLICY_Q2',
    },
    {
      label:
        'Portfolio management processes (e.g., allocation of investment opportunities, compliance with client investment objectives, policies and restrictions in the prospectus/SAI, and other applicable regulatory requirements)?',
      question: 'DDQ_COMPLIANCE_POLICY_Q3',
    },
    {
      label:
        'Trading practices (e.g., best execution obligations, soft dollar arrangements, allocation of aggregated trades among clients, cross trades)?',
      question: 'DDQ_COMPLIANCE_POLICY_Q4',
    },
    {
      label: 'Trading errors, including monitoring, reporting, maintenance of records, and corrections?',
      question: 'DDQ_COMPLIANCE_POLICY_Q5',
    },
    {
      label: 'The creation and maintenance of required records, including electronic records?',
      question: 'DDQ_COMPLIANCE_POLICY_Q6',
    },
    {
      label: 'The valuation of assets, if applicable?',
      question: 'DDQ_COMPLIANCE_POLICY_Q7',
    },
    {
      label:
        'Any other compliance policies and procedures affecting the SMA that have been adopted under Rule 206(4)-7 under the Investment Advisers Act of 1940 (the "Compliance Rule") or any additional regulatory rules adopted through local regulators in which your firm is registered?',
      question: 'DDQ_COMPLIANCE_POLICY_Q8',
    },
  ];

  constructor(protected service: QuestionnaireService) {
    super(service);
  }
}
