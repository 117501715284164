import { ErrorHandler, Injectable } from '@angular/core';
import { SlackService } from './modules/common/services/slack.service';

@Injectable()
export class AppErrorHandler extends ErrorHandler {
  /*
   * The stack trace of the last error reported.
   */
  private lastStackTrace: string = '';

  /*
   * The time when the last error was reported.
   */
  private lastTimestamp: number = 0;

  /*
   * The minimum interval for the same error to be reported again.
   */
  private minInterval: number = 30 * 1000; // 30 seconds

  constructor(private slackService: SlackService) {
    super();
  }

  handleError(error: Error): void {
    this.reportError(error?.stack || error?.message || '' + error);
    super.handleError(error);
  }

  /**
   * Report a client error to Slack.
   * @param response
   */
  reportError(stackTrace: string): void {
    const timestamp = new Date().getTime();

    if (stackTrace !== this.lastStackTrace || timestamp - this.lastTimestamp > this.minInterval) {
      const data = {
        title: 'Questionnaire Javascript error reported.',
        stackTrace,
        fields: [],
      };
      this.slackService.sendError(data).subscribe();
    }

    this.lastStackTrace = stackTrace;
    this.lastTimestamp = timestamp;
  }
}
