import { Component, OnInit } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserContextService } from '../services/user-context.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(private userContextService: UserContextService) {}

  ngOnInit(): void {
    this.userContextService
      .get()
      .pipe(catchError(() => EMPTY))
      .subscribe();
  }
}
