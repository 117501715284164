import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Company, CompanyAddress, CompanyService, ServiceProviderType } from 'app/modules/common/services/company.service';
import { Contact, ContactService } from 'app/modules/common/services/contact.service';
import { ReviewAction } from 'app/modules/questionnaire/model/questionnaire.model';
import { EMPTY, Observable } from 'rxjs';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-questionnaire-conflict',
  templateUrl: './questionnaire-conflict.component.html',
})
export class QuestionnaireConflictComponent extends OnDestroyMixin implements OnInit {
  public textInput!: UntypedFormControl;

  newValue!: { id: number; name: string };

  public options: (CompanyAddress | Contact)[] = [];

  constructor(
    protected dialogRef: MatDialogRef<QuestionnaireConflictComponent>,
    protected companyService: CompanyService,
    protected contactService: ContactService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      entityType: string;
      type: number;
      current: Company | Contact | null | undefined;
      userTyped: string;
      contactCompanyId: number;
      title: string;
    }
  ) {
    super();
  }

  ngOnInit(): void {
    this.newValue = { id: -1, name: this.data.userTyped };

    this.textInput = new UntypedFormControl(this.newValue);

    this.textInput.valueChanges
      .pipe(
        debounceTime(300),
        // the filter also avoids an object to be used for search after user selection
        filter((searchTerm) => searchTerm?.length >= 3),
        switchMap((searchTerm) => {
          const companyIds = this.data.contactCompanyId ? [this.data.contactCompanyId] : [];
          if (this.data.current?.idtCompany) {
            companyIds.push(this.data.current.idtCompany);
          }

          const service =
            ((this.data.entityType === 'company'
              ? this.companyService.queryAddresses(searchTerm, this.companyService.getCommonSearchTypes())
              : this.contactService.queryContacts(searchTerm, companyIds)) as Observable<CompanyAddress[]>) || Observable<Contact[]>;

          return service.pipe(
            map((result) => {
              if (!result.some((e: Company) => e.name.toLowerCase() === searchTerm.toLowerCase())) {
                result.unshift({ id: -1, name: searchTerm, idtCompany: -1 });
              }
              return result;
            })
          );
        }),
        untilComponentDestroyed(this)
      )
      .subscribe(
        (response) => (this.options = response),
        (error: string) => {
          console.error(error);
          return EMPTY;
        }
      );
  }

  public display(value: CompanyAddress | Contact): string {
    return value?.name ? value.name : '';
  }

  public closeDialog(result?: ReviewAction): void {
    this.dialogRef.close(result);
  }

  public saveNewValue(): void {
    const action = this.data.entityType + (this.newValue.id === -1 ? '-save-new' : '-keep');
    this.closeDialog({ action, ...this.newValue });
  }

  public keepCurrentValue(): void {
    this.closeDialog({
      action: this.data.entityType + '-keep',
      id: this.data.current?.id,
      name: this.data.current?.name,
    });
  }

  /*
   * Set selected option
   */

  public selectOption(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;
    this.newValue = { id: this.data.entityType === 'company' ? value.idtCompany : value.id, name: value.name };
  }

  public onBlur(): void {
    if (this.textInput.value?.name !== this.newValue.name) {
      this.textInput.setValue(this.newValue);
    }
  }

  public isDirector(): boolean {
    return this.data.type === ServiceProviderType.INDEPENDENT_BOARD_DIRECTOR;
  }
}
