import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './modules/common/home/home.component';
import { LayoutComponent } from './modules/common/layout/layout.component';
import { AttestationEditComponent } from './modules/questionnaire/components/attestation-edit/attestation-edit.component';
import { AttestationComponent } from './modules/questionnaire/components/attestation/attestation.component';
import { DdqAdviserReviewComponent } from './modules/questionnaire/components/ddq-adviser/components/ddq-adviser-review/ddq-adviser-review.component';
import { DdqAdviserComponent } from './modules/questionnaire/components/ddq-adviser/components/ddq-adviser/ddq-adviser.component';
import { OddWebformReviewComponent } from './modules/questionnaire/components/odd-webform/components/odd-webform-review/odd-webform-review.component';
import { OddWebformComponent } from './modules/questionnaire/components/odd-webform/components/odd-webform/odd-webform.component';
import { guestAuthResolverService } from './modules/questionnaire/services/guest-auth-resolver.service';
import { secureAuthResolverService } from './modules/questionnaire/services/secure-auth-resolver.service';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'secure/attestation/:hash',
        component: AttestationEditComponent,
        resolve: { context: secureAuthResolverService },
      },
      {
        path: 'attestation/:hash',
        component: AttestationComponent,
        resolve: { context: guestAuthResolverService },
      },
      {
        path: 'oddwebform/:hash',
        component: OddWebformComponent,
        resolve: { context: guestAuthResolverService },
      },
      {
        path: 'secure/oddwebform/:hash',
        component: OddWebformReviewComponent,
        resolve: { context: secureAuthResolverService },
      },
      {
        path: 'ddqadviser/:hash',
        component: DdqAdviserComponent,
        resolve: { context: guestAuthResolverService },
      },
      {
        path: 'secure/ddqadviser/:hash',
        component: DdqAdviserReviewComponent,
        resolve: { context: secureAuthResolverService },
      },
      {
        path: '**',
        component: HomeComponent,
      },
    ],
  },
  {
    path: '**',
    component: HomeComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
