<!-- Welcome message -->
<div fxLayout="column" class="abs-margin">
  <p>Thanks for answering the ABS Adviser-SubAdviser DDQ. While completing, please notice the following:</p>
  <p>
    1. All inputted data is saved automatically, so you can return to complete the questionnaire at any time. Please remember to always use the
    original link provided in the email (do not bookmark) or your work will not be saved.
  </p>
  <p>
    2. You may access the questionnaire as many times as needed, but once submitted as final you will lose access. Please complete and submit the
    final by {{ data.endDate | date : 'MM/dd/yyyy' }}.
  </p>
  <p>
    3. If you make any changes or update a blank cell, the cell will highlight itself after a second or two. If it does not highlight then the
    information is not being saved. Please refresh the browser or close and re-enter the link.
  </p>
  <span>
    Document Request – Please provide the following:
    <ol class="abs-margin-half-between-vertical">
      <li>
        Most recent version of Compliance Manual - if there are material changes from the last submission, please also provide a redline version or a
        summary of changes.
      </li>
      <li>
        Most recent version of the firm's Code of Ethics – if there are material changes from the last submission, please also provide a redline
        version or a summary of changes.
      </li>
      <li>
        Most recent version of the firm's Business Continuity Plan – if there are material changes from the last submission, please also provide a
        redline version or a summary of changes.
      </li>
      <li>
        Most recently completed Annual Review of the Adviser's/Sub-Adviser's compliance policies and procedures, as required by Rule 206(4)-7 of the
        Investment Advisers Act of 1940 or any other rules imposed by regulators in which your firm is registered - if preferred, a summary of the
        testing procedures and findings is adequate.
      </li>
      <li>The proxy voting log for our SMA from {{ getStartDate() | date : 'MM/dd/yyyy' }} to {{ data.asOfDate | date : 'MM/dd/yyyy' }}.</li>
    </ol>
  </span>
  <p>If there are any questions or concerns regarding any of the material represented in the following report, please contact ABS below.</p>
  <span>Gregory J. Moroney</span>
  <span>ABS Global Investments</span>
  <span>537 Steamboat Road, 4th floor</span>
  <span>Greenwich, CT 06830</span>
  <span>Tel: 203-618-3766</span>
  <span>
    Email:
    <a href="mailto:gjm@absinv.com">gjm&#64;absinv.com</a>
  </span>
</div>
