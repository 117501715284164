<div fxLayout="column" class="form-section" fxLayoutGap="8px">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between abs-nomargin-bottom">
      <span>
        When was your firm's last inspection, Securities and Exchange Commission and/or other regulatory agency, relating to money management
        activities? What was the substance, nature and current status of the inquiry? Provide copies of or descriptions of any matter or issue covered
        in any inspection letters, or other regulatory inspection/examination letters, and your firm's response.
      </span>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswers(['DDQ_INSPECTIONS_Q1'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      [noLabel]="true"
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_INSPECTIONS_Q1')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_INSPECTIONS_Q1').reviewed || !!getAnswer('DDQ_INSPECTIONS_Q1').reviewing"
    ></app-questionnaire-comment-answer>

    <span>
      Has your firm or any advisory affiliate received any subpoenas or formal information requests (federal, state, or other regulatory authority)
      relating to money management activities?
    </span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [data]="getAnswer('DDQ_INSPECTIONS_Q2')"
        (errorhandler)="onError($event)"
        [highlightChanges]="true"
        [disabled]="isReviewed() || !!getAnswer('DDQ_INSPECTIONS_Q2').reviewed || !!getAnswer('DDQ_INSPECTIONS_Q2').reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswers(['DDQ_INSPECTIONS_Q2', 'DDQ_INSPECTIONS_Q2_COMMENTS'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      label="If yes, describe."
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_INSPECTIONS_Q2_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_INSPECTIONS_Q2_COMMENTS').reviewed || !!getAnswer('DDQ_INSPECTIONS_Q2_COMMENTS').reviewing"
    ></app-questionnaire-comment-answer>

    <span>
      Has your firm or any advisory affiliate been the focus of any litigation, formal investigation or administrative proceedings (including
      settlements) relating to money management activities?
    </span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [data]="getAnswer('DDQ_INSPECTIONS_Q3')"
        (errorhandler)="onError($event)"
        [highlightChanges]="true"
        [disabled]="isReviewed() || !!getAnswer('DDQ_INSPECTIONS_Q3').reviewed || !!getAnswer('DDQ_INSPECTIONS_Q3').reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswers(['DDQ_INSPECTIONS_Q3', 'DDQ_INSPECTIONS_Q3_COMMENTS'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      label="If yes, describe."
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_INSPECTIONS_Q3_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_INSPECTIONS_Q3_COMMENTS').reviewed || !!getAnswer('DDQ_INSPECTIONS_Q3_COMMENTS').reviewing"
    ></app-questionnaire-comment-answer>

    <span>
      Has the SEC, FINRA, or any other regulatory authority issued any orders or other sanctions against your firm or any advisory affiliate relating
      to money management activities?
    </span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [data]="getAnswer('DDQ_INSPECTIONS_Q4')"
        (errorhandler)="onError($event)"
        [highlightChanges]="true"
        [disabled]="isReviewed() || !!getAnswer('DDQ_INSPECTIONS_Q4').reviewed || !!getAnswer('DDQ_INSPECTIONS_Q4').reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswers(['DDQ_INSPECTIONS_Q4', 'DDQ_INSPECTIONS_Q4_COMMENTS'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      label="If yes, describe."
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_INSPECTIONS_Q4_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_INSPECTIONS_Q4_COMMENTS').reviewed || !!getAnswer('DDQ_INSPECTIONS_Q4_COMMENTS').reviewing"
    ></app-questionnaire-comment-answer>
  </div>
</div>
