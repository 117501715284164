import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IpData, IpService } from 'app/modules/common/services/ip.service';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Answer, CompanyReviewData, Questionnaire, QuestionnaireConclusionData, ReviewData } from '../model/questionnaire.model';

export interface JwtToken {
  value: string;
}

/**
 * Service that exxecutes api calls to get/update information of the questionnaire.
 */
@Injectable({
  providedIn: 'root',
})
export class QuestionnaireService {
  constructor(private http: HttpClient, private ipService: IpService) {}

  /**
   * Gets a jwt token to authorize an user to view and edit a questionnaire.
   *
   * @param hash Questionnaire hash token
   */
  public getToken(hash: string): Observable<JwtToken> {
    return this.http.get<JwtToken>(`${environment.apiUrl}/public/questionnaire/${hash}/jwt`).pipe(
      tap((token: JwtToken) => {
        window.localStorage.setItem('auth_token', token.value);
      })
    );
  }

  /**
   * Get the questionnaire details with answers.
   * @param hash Questionnaire hash identifier.
   */
  public getQuestionnaire(hash: string): Observable<Questionnaire> {
    return this.http.get<Questionnaire>(`${environment.apiUrl}/questionnaire/hash/${hash}`);
  }

  /**
   * Get the company data for questionnaire review
   * @param id the questionnaire id
   */
  public getCompanyReviewData(id: number): Observable<CompanyReviewData> {
    return this.http.get<CompanyReviewData>(`${environment.apiUrl}/questionnaire/${id}/company-review-data`);
  }

  /**
   * Call the api to update the value of the answer.
   * @param answer Answer model.
   */
  public answer(answer: Answer): Observable<Answer> {
    return this.http.put<Answer>(`${environment.apiUrl}/questionnaire/answer`, answer);
  }

  /**
   * Call the api to create a new answer instance for a multi answer question
   * @param answer Answer model.
   */
  public createAnswer(answer: Answer): Observable<Answer> {
    return this.http.post<Answer>(`${environment.apiUrl}/questionnaire/answer`, answer);
  }

  /**
   * Call the api to mark the questionnaire as started (in progress).
   * @param id Questionnaire identifier.
   */
  public startProgress(id: number): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/questionnaire/${id}/start`, {});
  }

  /**
   * Call the api to inform the completion of the questionnaire.
   * @param id Questionnaire identifier.
   * @param data Conclusion data.
   */
  public finishProgress(id: number, data: QuestionnaireConclusionData): Observable<void> {
    return this.ipService.getIPAddress().pipe(
      tap(({ ip }: IpData) => (data.ipAddress = ip)),
      mergeMap(() => this.http.put<void>(`${environment.apiUrl}/questionnaire/${id}/finish`, data))
    );
  }

  /**
   * Add review to one or more answers. Only analysts have access to his method.
   *
   * @param review Review data.
   * @return Updated review data.
   */
  public addReview(review: ReviewData): Observable<ReviewData> {
    return this.http.put<ReviewData>(`${environment.apiUrl}/questionnaire/review/add`, review);
  }

  /**
   * Remove review to one or more answers. Only analysts have access to his method.
   *
   * @param review Review data.
   * @return Updated review data.
   *
   */
  public removeReview(review: ReviewData): Observable<ReviewData> {
    return this.http.put<ReviewData>(`${environment.apiUrl}/questionnaire/review/remove`, review);
  }

  /**
   * Call the api to mark the questionnaire as finished.
   * @param id Questionnaire identifier.
   */
  public finishReview(id: number): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/questionnaire/${id}/finishReview`, {});
  }

  /**
   *
   * Update the SMA Agreement date.
   *
   * @param data Questionnaire data
   */
  public updateSmaAgreement(data: Questionnaire): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/questionnaire/${data.id}/sma-agreement`, {
      smaAgreement: DateTime.fromJSDate(new Date(data.smaAgreement!)).toISODate(),
    });
  }

  /**
   *
   * Update the Company name in the questionnaire
   *
   * @param data Questionnaire data
   */
  public updateCompanyName(data: Questionnaire): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/questionnaire/${data.id}/company-name`, {
      company: data.company,
    });
  }
}
