import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QuestionnaireService } from '../../../../services/questionnaire.service';
import { QuestionnaireBaseComponent } from '../../../common/questionnaire-base/questionnaire-base.component';

@Component({
  selector: 'app-odd-webform',
  templateUrl: './odd-webform.component.html',
  styleUrls: ['./odd-webform.component.scss'],
})
export class OddWebformComponent extends QuestionnaireBaseComponent {
  public finishProgress = false;

  public showWelcome = true;

  constructor(protected route: ActivatedRoute, protected service: QuestionnaireService) {
    super(route, service);
  }

  public startQuestionnaire(): void {
    this.showWelcome = false;
  }

  /**
   * Finishes filling out the questionnaire
   */
  public finishQuestionnaire(): void {
    this.finishProgress = true;
  }

  /**
   * Cancel finish event.
   */
  public onCancelFinishQuestionnaire(): void {
    this.finishProgress = false;
  }
}
