<div fxLayout="column" class="form-section">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <span class="question-info">
      To the best of your ability, please provide the ASC 820 / IFRS 7 estimate valuation of the fund by grossing assets and liabilities both as
      positive numbers. This is how administrators will calculate numbers on transparency reports. (Must equal 100%)
    </span>

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100" fxLayoutGap="18px">
      <span>Gross</span>
      @for (field of grossFields; track field.name) {
        <div fxFlex="18">
          <app-questionnaire-decimal-answer
            [label]="field.label"
            [data]="getAnswer(field.name)"
            (errorhandler)="onError($event)"
            [disabled]="isReviewed() || !!getAnswer(field.name).reviewed || !!getAnswer(field.name).reviewing"
          ></app-questionnaire-decimal-answer>
        </div>
      }
      <div>{{ grossTotal | number: '1.2-2' }}%</div>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="
            getAnswers([
              'DDQ_TRANS_VALUATION_ASC_LEVEL1',
              'DDQ_TRANS_VALUATION_ASC_LEVEL2',
              'DDQ_TRANS_VALUATION_ASC_LEVEL3',
              'DDQ_TRANS_VALUATION_ASC_LEVEL3_ASSET_DESCRIPTION',
              'DDQ_TRANS_VALUATION_ASC_LEVEL3_REMOVED_ASSETS'
            ])
          "
          (errorhandler)="onError($event)"
          reviewHandler="VALUATION_HANDLER"
        ></app-questionnaire-review>
      }
    </div>

    <span>If applicable, please describe what types of assets comprise your level 3 securities.</span>
    <app-questionnaire-comment-answer
      [noMargin]="true"
      [noLabel]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_TRANS_VALUATION_ASC_LEVEL3_ASSET_DESCRIPTION')"
      (errorhandler)="onError($event)"
      [disabled]="
        isReviewed() ||
        !!getAnswer('DDQ_TRANS_VALUATION_ASC_LEVEL3_ASSET_DESCRIPTION').reviewed ||
        !!getAnswer('DDQ_TRANS_VALUATION_ASC_LEVEL3_ASSET_DESCRIPTION').reviewing
      "
    ></app-questionnaire-comment-answer>

    <span>
      If applicable, have you removed any assets from your Fair Value Measurement for any reason (ie due to changing guidance like ASU 2015-07, which
      describes excluding investments measured using the NAV practical expedient from the classification of the ASC 820 hierarchy)?
    </span>
    <app-questionnaire-comment-answer
      [noMargin]="true"
      [noLabel]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_TRANS_VALUATION_ASC_LEVEL3_REMOVED_ASSETS')"
      (errorhandler)="onError($event)"
      [disabled]="
        isReviewed() ||
        !!getAnswer('DDQ_TRANS_VALUATION_ASC_LEVEL3_REMOVED_ASSETS').reviewed ||
        !!getAnswer('DDQ_TRANS_VALUATION_ASC_LEVEL3_REMOVED_ASSETS').reviewing
      "
    ></app-questionnaire-comment-answer>
  </div>
</div>

<div fxLayout="column" class="form-section">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <span>Is the fund administrator independently pricing the entire portfolio?</span>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100" fxLayoutGap="18px">
      @for (field of pricingFields; track field.name) {
        <div fxFlex="18">
          <app-questionnaire-decimal-answer
            [label]="field.label"
            [data]="getAnswer(field.name)"
            (errorhandler)="onError($event)"
            [disabled]="isReviewed() || !!getAnswer(field.name).reviewed || !!getAnswer(field.name).reviewing"
          ></app-questionnaire-decimal-answer>
        </div>
      }
      <div>{{ pricingTotal | number: '1.2-2' }}%</div>

      <div fxFlex>
        @if (showReview) {
          <app-questionnaire-review
            [disabled]="isReviewed()"
            [answers]="
              getAnswers([
                'DDQ_TRANS_VALUATION_INDEP_PRICED',
                'DDQ_TRANS_VALUATION_INDEP_3P_PRICED',
                'DDQ_TRANS_VALUATION_INDEP_PRICING_MODEL',
                'DDQ_TRANS_VALUATION_INDEP_OTHER'
              ])
            "
            (errorhandler)="onError($event)"
          ></app-questionnaire-review>
        }
      </div>
    </div>
  </div>
</div>

<div fxLayout="column" class="form-section">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <span>
        Please provide the names of the pricing sources (i.e. third party pricing firms, pricing models, other, etc.) and any additional comments.
      </span>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswers(['DDQ_TRANS_VALUATION_SOURCES_NAMES'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      [noMargin]="true"
      [noLabel]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_TRANS_VALUATION_SOURCES_NAMES')"
      (errorhandler)="onError($event)"
      [disabled]="
        isReviewed() || !!getAnswer('DDQ_TRANS_VALUATION_SOURCES_NAMES').reviewed || !!getAnswer('DDQ_TRANS_VALUATION_SOURCES_NAMES').reviewing
      "
    ></app-questionnaire-comment-answer>
  </div>
</div>

<div fxLayout="column" class="form-section" fxLayoutGap="8px">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <span>
      Did the SMA engage in any 17a-7 transactions (cross transactions between the SMA and other accounts managed by the Adviser/Sub-Adviser)?
    </span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [data]="getAnswer('DDQ_TRANS_VALUATION_Q1')"
        (errorhandler)="onError($event)"
        [highlightChanges]="true"
        [disabled]="isReviewed() || !!getAnswer('DDQ_TRANS_VALUATION_Q1').reviewed || !!getAnswer('DDQ_TRANS_VALUATION_Q1').reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswers(['DDQ_TRANS_VALUATION_Q1'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>

    <span>Does the firm have a process in place to monitor the liquidity of the securities held in the SMA?</span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [data]="getAnswer('DDQ_TRANS_VALUATION_Q2')"
        (errorhandler)="onError($event)"
        [highlightChanges]="true"
        [disabled]="isReviewed() || !!getAnswer('DDQ_TRANS_VALUATION_Q2').reviewed || !!getAnswer('DDQ_TRANS_VALUATION_Q2').reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswers(['DDQ_TRANS_VALUATION_Q2', 'DDQ_TRANS_VALUATION_Q2_COMMENTS'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      label="If yes, please describe."
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_TRANS_VALUATION_Q2_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('DDQ_TRANS_VALUATION_Q2_COMMENTS').reviewed || !!getAnswer('DDQ_TRANS_VALUATION_Q2_COMMENTS').reviewing"
    ></app-questionnaire-comment-answer>

    <span>Does the Adviser/Sub-Adviser complete a review of the valuation of the SMA's holdings?</span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [data]="getAnswer('DDQ_TRANS_VALUATION_Q3')"
        (errorhandler)="onError($event)"
        [highlightChanges]="true"
        [disabled]="isReviewed() || !!getAnswer('DDQ_TRANS_VALUATION_Q3').reviewed || !!getAnswer('DDQ_TRANS_VALUATION_Q3').reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswers(['DDQ_TRANS_VALUATION_Q3'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
  </div>
</div>
