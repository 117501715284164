import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { SlackErrorRequest } from '../model/slack.model';

/**
 * Service that executes api calls to send messages to Slack.
 */
@Injectable({
  providedIn: 'root',
})
export class SlackService {
  constructor(private http: HttpClient) {}

  /**
   * Check if a given URL corresponds to this endpoint.
   * @param url
   */

  public matches(url?: string): boolean {
    return !!url && url.startsWith(`${environment.apiUrl}/slack`);
  }

  /**
   * Report an error as a message to Slack.
   * @param error The error details.
   */
  public sendError(error: SlackErrorRequest): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/slack/send-error`, error);
  }
}
