import { Component, EventEmitter, Input } from '@angular/core';
import { CompanyAddress, CompanyService } from 'app/modules/common/services/company.service';
import { CompanyReviewData, IndexedValue } from 'app/modules/questionnaire/model/questionnaire.model';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { OddWebformTabBaseComponent } from '../odd-webform-tab-base/odd-webform-tab-base.component';

@Component({
  selector: 'app-odd-webform-service-providers',
  templateUrl: './odd-webform-service-providers.component.html',
  styleUrls: ['../odd-webform-tab-base/odd-webform-tab-base.component.scss'],
})
export class OddWebformServiceProvidersComponent extends OddWebformTabBaseComponent {
  @Input() companyData?: CompanyReviewData;

  public companyEmitter = new EventEmitter<IndexedValue>();

  constructor(protected service: QuestionnaireService, public companyService: CompanyService) {
    super(service);
  }

  /*
   * when the user selects a company on the dropdown, the default address is added to the location field
   */
  public onCompanySelect(company: CompanyAddress): void {
    this.companyEmitter.emit({ index: company.questionnaireIndex, value: company.location });
  }
}
