@if (data) {
  <mat-form-field [ngClass]="{ 'answer-saved': data.modified }">
    @if (label) {
      <mat-label>{{ label }}</mat-label>
    }
    <input type="text" matInput [formControl]="textInput" [matAutocomplete]="companyComplete" (blur)="onBlur()" />
    <mat-autocomplete #companyComplete="matAutocomplete" (optionSelected)="selectOption($event)" [displayWith]="display" panelWidth="fit-content">
      @for (option of options; track option.id) {
        <mat-option [value]="option">
          {{ option.name }}
        </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
}
