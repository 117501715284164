import { AfterContentChecked, Component } from '@angular/core';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { OddWebformTabBaseComponent } from '../odd-webform-tab-base/odd-webform-tab-base.component';

@Component({
  selector: 'app-odd-webform-fund-expenses',
  templateUrl: './odd-webform-fund-expenses.component.html',
  styleUrls: ['../odd-webform-tab-base/odd-webform-tab-base.component.scss'],
})
export class OddWebformFundExpensesComponent extends OddWebformTabBaseComponent implements AfterContentChecked {
  public directTotal = 0;
  public softDollarTotal = 0;
  public totalExpenses = 0;

  comments = [
    {
      label: 'Which vendors/systems do you use soft dollars to pay for?',
      name: 'EXP_QUESTION_F6',
    },
    {
      label: 'Did you make any changes to your soft dollar policy?',
      name: 'EXP_QUESTION_F1',
    },
    {
      label: 'Do you have any expense limits put in place to cap expenses charged to the fund? Please explain.',
      name: 'EXP_QUESTION_F2',
    },
    {
      label: `If the firm has Directors & Officers (D&O) and Errors & Omissions (E&O) insurance, what is the amount of coverage and premium? Please break down the premium paid by the Investment Manager, the Funds or both. Please provide name of the insurance provider.`,
      name: 'EXP_QUESTION_F3',
    },
    {
      label:
        'If any, please provide detail into what your insurance covers. Does it explicitly cover all potential liabilities stemming from regulatory inquiries? Does it include a cyber-security enhancement or endorsement?',
      name: 'EXP_QUESTION_F4',
    },
  ];

  constructor(protected service: QuestionnaireService) {
    super(service);
  }

  ngAfterContentChecked(): void {
    this.directTotal = 0;
    this.softDollarTotal = 0;
    this.totalExpenses = 0;

    this.getAllAnswers('EXP_EXPENSE_DIRECT').forEach((item) => {
      this.directTotal += this.getNumber(item.value);
    });

    this.getAllAnswers('EXP_EXPENSE_SOFT_DOLLAR').forEach((item) => {
      this.softDollarTotal += this.getNumber(item.value);
    });

    this.totalExpenses = this.directTotal + this.softDollarTotal;
  }
}
