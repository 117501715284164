import { AfterContentChecked, Component, EventEmitter, Input } from '@angular/core';
import { CompanyAddress, CompanyType, ServiceProviderType } from 'app/modules/common/services/company.service';
import { CompanyReviewData, IndexedValue } from 'app/modules/questionnaire/model/questionnaire.model';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { OddWebformTabBaseComponent } from '../odd-webform-tab-base/odd-webform-tab-base.component';

@Component({
  selector: 'app-odd-webform-prime-brokerage',
  templateUrl: './odd-webform-prime-brokerage.component.html',
  styleUrls: ['../odd-webform-tab-base/odd-webform-tab-base.component.scss', './odd-webform-prime-brokerage.component.scss'],
})
export class OddWebformPrimeBrokerageComponent extends OddWebformTabBaseComponent implements AfterContentChecked {
  @Input() companyData?: CompanyReviewData;

  primeBrokerEmitter = new EventEmitter<IndexedValue>();

  custodianEmitter = new EventEmitter<IndexedValue>();

  depositaryEmitter = new EventEmitter<IndexedValue>();

  companyType = CompanyType;

  serviceProviderType = ServiceProviderType;

  custodianTotal!: number;

  constructor(protected service: QuestionnaireService) {
    super(service);
  }

  lastComments = [
    {
      label: `Additional Comments`,
      name: `PB_ADDITIONAL_COMMENTS`,
    },
    {
      label: `How is counterparty risk monitored?`,
      name: `PB_COUNTERPARTY_RISK`,
    },
    {
      label: `How is excess cash managed/invested?`,
      name: `PB_EXCESS_INVESTED`,
    },

    {
      label: `Which banks are used for cash purposes at the fund level?`,
      name: `PB_Q1`,
    },
    {
      label: `Which banks are used for cash purposes at the management company level?`,
      name: `PB_Q2`,
    },
    {
      label: `What level of cash is uninsured by the FDIC?`,
      name: `PB_Q3`,
    },
    {
      label: `If applicable, what was the contribution from the short rebate for the most recent year?`,
      name: `PB_Q5`,
    },
  ];

  /*
   * when the user selects a company on the dropdown, the default address is added to the location field
   */
  onPrimeBrokerSelect(company: CompanyAddress): void {
    this.primeBrokerEmitter.emit({ index: company.questionnaireIndex, value: company.location });
  }

  onCustodianSelect(company: CompanyAddress): void {
    this.custodianEmitter.emit({ index: company.questionnaireIndex, value: company.location });
  }

  onDepositarySelect(company: CompanyAddress): void {
    this.depositaryEmitter.emit({ index: company.questionnaireIndex, value: company.location });
  }

  addPrimeBroker(index: number): void {
    this.addField(
      [
        'PB_PRIME_BROKER_NAME',
        'PB_PRIME_BROKER_LOCALE',
        'PB_PRIME_BROKER_CONTACT',
        'PB_PRIME_BROKER_EMAIL',
        'PB_PRIME_BROKER_PHONE',
        'PB_PRIME_BROKER_DATE',
        'PB_PRIME_BROKER_ASSETS',
        'PB_PRIME_BROKER_AS_OF',
        'PB_PRIME_BROKER_DEBIT',
        'PB_PRIME_BROKER_CREDIT',
      ],
      index,
    );
  }

  addCustodian(index: number): void {
    this.addField(
      [
        'PB_CUSTODIAN_NAME',
        'PB_CUSTODIAN_LOCALE',
        'PB_CUSTODIAN_CONTACT',
        'PB_CUSTODIAN_EMAIL',
        'PB_CUSTODIAN_PHONE',
        'PB_CUSTODIAN_DATE',
        'PB_CUSTODIAN_ASSETS',
        'PB_CUSTODIAN_AS_OF',
      ],
      index,
    );
  }

  addDepositary(index: number): void {
    this.addField(
      [
        'PB_DEPOSITARY_NAME',
        'PB_DEPOSITARY_LOCALE',
        'PB_DEPOSITARY_CONTACT',
        'PB_DEPOSITARY_EMAIL',
        'PB_DEPOSITARY_PHONE',
        'PB_DEPOSITARY_DATE',
        'PB_DEPOSITARY_ASSETS',
        'PB_DEPOSITARY_AS_OF',
        'PB_DEPOSITARY_LIGHT',
      ],
      index,
    );
  }

  ngAfterContentChecked(): void {
    this.custodianTotal = 0;

    this.getAllAnswers('PB_CUSTODIAN_ASSETS').forEach((item) => {
      this.custodianTotal += this.getNumber(item.value);
    });

    this.getAllAnswers('PB_PRIME_BROKER_ASSETS').forEach((item) => {
      this.custodianTotal += this.getNumber(item.value);
    });

    this.getAllAnswers('PB_DEPOSITARY_ASSETS').forEach((item) => {
      this.custodianTotal += this.getNumber(item.value);
    });
  }
}
