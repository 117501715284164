import { Component, OnInit } from '@angular/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Answer } from 'app/modules/questionnaire/model/questionnaire.model';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GeneralInfo, GeneralInfoService } from '../../../../../common/services/general-info.service';
import { OddWebformTabBaseComponent } from '../odd-webform-tab-base/odd-webform-tab-base.component';

@Component({
  selector: 'app-odd-webform-general-info',
  templateUrl: './odd-webform-general-info.component.html',
  styleUrls: ['../odd-webform-tab-base/odd-webform-tab-base.component.scss'],
})
export class OddWebformGeneralInfoComponent extends OddWebformTabBaseComponent implements OnInit {
  public generalInfo?: { [key: string]: GeneralInfo };

  public firstCheckboxGroup = [
    {
      label: 'What Order/Execution Management System(s) are you using? Select all that apply.',
      code: 'EXECUTION_MANAGEMENT_SYSTEM',
      field: 'GI_EXECUTION_MANAGEMENT_SYSTEM',
      complField: 'GI_EXECUTION_MANAGEMENT_SYSTEM_COMP',
    },
    {
      label: 'What Portfolio Management or Accounting System(s) are you using? Select all that apply.',
      code: 'PORTFOLIO_MANAGEMENT_SYSTEM',
      field: 'GI_PORTFOLIO_MANAGEMENT_SYSTEM',
      complField: 'GI_PORTFOLIO_MANAGEMENT_SYSTEM_COMP',
    },
    {
      label: 'What Customer Relationship Management System(s) are you using? Select all that apply.',
      code: 'CRM_SYSTEM',
      field: 'GI_CRM_SYSTEM',
      complField: 'GI_CRM_SYSTEM_COMP',
    },
    {
      label: 'What type of pricing feeds are you using for valuation of the portfolio? Select all that apply.',
      code: 'PRICING_FEEDS',
      field: 'GI_PRICING_FEEDS',
      complField: 'GI_PRICING_FEEDS_COMP',
    },
    {
      label: 'How many trades does the strategy place on average per day?',
      code: 'TRADE_QUANTITY',
      field: 'GI_TRADE_QUANTITY',
    },
    {
      label: 'How many trades does the firm place on average per day?',
      code: 'TRADE_QUANTITY_FIRM',
      field: 'GI_TRADE_QUANTITY_FIRM',
    },
    {
      label: 'What type of language do your side letters address, if applicable? Select all that apply.',
      code: 'SIDE_LETTER_LANGUAGE',
      field: 'GI_SIDE_LETTER_LANGUAGE',
      complField: 'GI_SIDE_LETTER_LANGUAGE_COMP',
    },
    {
      label: 'Which Compliance System, if any, do you use? Select all that apply.',
      code: 'COMPLIANCE_SYSTEM',
      field: 'GI_COMPLIANCE_SYSTEM',
      complField: 'GI_COMPLIANCE_SYSTEM_COMP',
    },
    {
      label: 'Which Proxy Voting service, if any, do you use? Select all that apply.',
      code: 'PROXY_VOTING',
      field: 'GI_PROXY_VOTING',
      complField: 'GI_PROXY_VOTING_COMP',
    },
    {
      label: 'Which Expert Networks, if any, do you use? Select all that apply.',
      code: 'EXPERT_NETWORKS',
      field: 'GI_EXPERT_NETWORKS',
      complField: 'GI_EXPERT_NETWORKS_COMP',
    },
    {
      label: 'What regulatory authorities are the firm and/or fund(s) registered with? Select all that apply.',
      code: 'REGULATORY_REGISTRATIONS',
      field: 'GI_REGULATORY_REGISTRATIONS',
      complField: 'GI_REGULATORY_REGISTRATIONS_COMP',
    },
    {
      label: 'Have you been in contact with any regulatory body in the past 2 years? Select all that apply.',
      code: 'REGULATORY_INTERATION',
      field: 'GI_REGULATORY_INTERATION',
      complField: 'GI_REGULATORY_INTERATION_COMP',
      extraDateCompl: 'GI_REGULATORY_INTERATION_COMP_DATE',
    },
    {
      label: `If you have had an audit or investigation by a regulatory body (i.e. SEC), what type of follow up was received? Select all that apply.`,
      code: 'REGULATORY_AUDIT_RESULT',
      field: 'GI_REGULATORY_AUDIT_RESULT',
      complField: 'GI_REGULATORY_AUDIT_RESULT_COMP',
      extraDateCompl: 'GI_REGULATORY_AUDIT_RESULT_COMP_DATE',
    },
    {
      label: 'Has your firm/fund/employees been involved in any of the following the past 12 months? Select all that apply.',
      code: 'ADVERSE_ACTIONS',
      field: 'GI_ADVERSE_ACTIONS',
      complField: 'GI_ADVERSE_ACTIONS_COMP',
    },
    {
      label: `How are securities that are sanctioned by the United States Treasury's office of Foreign Assets Control (OFAC), or other regulatory authorities that may impose sanctions, monitored?`,
      code: 'OFAC',
      field: 'GI_OFAC',
    },
    {
      label:
        'Is the Fund ABS Invested considered a Controlled Foreign Corporation? (NOTE: A controlled foreign corporation is defined as any foreign corporation in which more than 50 percent of the total combined voting power of all classes of stock entitled to vote is owned directly, indirectly, or constructively by U.S. shareholders on any day during the taxable year of such foreign corporation)',
      code: 'CONTROLLED_FOREIGN_CORPORATION',
      field: 'GI_CONTROLLED_FOREIGN_CORPORATION',
    },
    {
      label: 'Have you completed a full mock audit by a third party in addition to a 206(4)-7 annual review?',
      code: 'FULL_MOCK_AUDIT',
      field: 'GI_FULL_MOCK_AUDIT',
      complField: 'GI_FULL_MOCK_AUDIT_COMP',
      complementIndex: 0,
    },
    {
      label: 'If applicable, who is the third party/independent signatory on your cash procedures?',
      code: 'CASH_SIGNATORY',
      field: 'GI_CASH_SIGNATORY',
      complField: 'GI_CASH_SIGNATORY_COMP',
      complementIndex: 3,
    },
    {
      label: `How do you calculate/allocate New Issue Income for IPO's? Select all that apply.`,
      code: 'NEW_ISSUE_INCOME',
      field: 'GI_NEW_ISSUE_INCOME',
      complField: 'GI_NEW_ISSUE_INCOME_COMP',
    },
    {
      label: 'Do you provide an independent transparency and verification report from your administrator and how often is it reported?',
      code: 'TRANSPARENCY_REPORT',
      field: 'GI_TRANSPARENCY_REPORT',
    },
    {
      label: 'Do you perform internal NAV shadow accounting in order to monitor your administrator?',
      code: 'NAV_SHADOW_ACCOUNTING',
      field: 'GI_NAV_SHADOW_ACCOUNTING',
    },
    {
      label: 'What type of cyber-security specific vendors do you work with aside from your IT provider? Select all that apply.',
      code: 'CYBER_SECURITY',
      field: 'GI_CYBER_SECURITY',
      complField: 'GI_CYBER_SECURITY_COMP',
    },
  ];

  constructor(protected service: QuestionnaireService, protected genInfoService: GeneralInfoService) {
    super(service);
  }

  ngOnInit(): void {
    this.genInfoService
      .getAll()
      .pipe(
        catchError((error) => {
          this.showError.emit(error);
          return EMPTY;
        }),
        tap((items: GeneralInfo[]) => {
          this.showError.emit(null);

          // turn array into map
          this.generalInfo = items.reduce((map: { [key: string]: GeneralInfo }, obj) => {
            // concat options titles for radio buttons
            if (!obj.multiple) {
              obj.optionTitles = [];
              obj.options.forEach((option) => {
                obj.optionTitles!.push(option.title);
              });
            }

            map[obj.code] = obj;

            return map;
          }, {});
        }),

        untilComponentDestroyed(this)
      )
      .subscribe();
  }

  public getDefinedAnswersForReview(codes: (string | undefined)[]): Answer[] {
    const definedCodes = codes.filter((code) => code);
    return this.getAnswersForReview(definedCodes as string[]);
  }
}
