import { Component } from '@angular/core';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { SelectOptions } from '../../../common/questionnaire-select-answer/questionnaire-select-answer.component';
import { OddWebformTabBaseComponent } from '../odd-webform-tab-base/odd-webform-tab-base.component';

@Component({
  selector: 'app-odd-webform-fund-info',
  templateUrl: './odd-webform-fund-info.component.html',
  styleUrls: ['../odd-webform-tab-base/odd-webform-tab-base.component.scss'],
})
export class OddWebformFundInfoComponent extends OddWebformTabBaseComponent {
  options: SelectOptions[] = [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ];

  erisaCalculationLabels = ['at each class level', 'at Feeder fund level', 'at Master fund level'];

  erisaMonitoringLabels = ['Administrator only', 'Internally only', 'Both Admin and Internal'];

  comments = [
    {
      label: 'What is the date of your most recent Offering Memorandum? Please describe any amendments or changes made?',
      name: 'FUND_INFO_Q1',
    },
    {
      label:
        'Does the fund currently have the ability to implement a specific redemption restriction (ie gates and/or side pockets)? Are there currently any redemption restrictions in place or potential in the next 12 months?',
      name: 'FUND_INFO_Q2',
    },
    {
      label: 'Does the fund have any side letters? If so, what is the nature (ie favorable fees/liquidity/transparency)?',
      name: 'FUND_INFO_Q3',
    },
    {
      label: 'How are offshore investors tracked if entering through a nominee?',
      name: 'FUND_INFO_Q6',
    },
    {
      label:
        'Are you tracking subscription and redemption documents in conjunction with the administrator? If maintained internally, how is personal information secured?',
      name: 'FUND_INFO_Q7',
    },
  ];

  lastComments = [
    {
      label: 'Additional ERISA comments',
      name: 'FUND_INFO_ERISA_ADD_COMMENT',
    },
    {
      label: `Are the funds investing in any foreign IPO's? If so, is the P&L derived from these trades being treated as "new issue" P&L for FINRA 5131 purposes?`,
      name: 'FUND_INFO_Q5',
    },
    {
      label: `Have there been any changes in the process of reviewing the fund administrator's work in the past year? Have there been any restatments in the past two years? If so, please explain.`,
      name: 'FUND_INFO_Q8',
    },
    {
      label: `Have there been any trade errors within the past two years? What is the policy for handling trade errors (ie are losses charged to the fund or reimbursed)?`,
      name: 'FUND_INFO_Q9',
    },
  ];

  taxEstimatesSection = [
    {
      label: 'When do you provide final K-1s by?',
      name: 'FUND_INFO_TAX_ESTIMATES_K1',
      comment: 'FUND_INFO_TAX_ESTIMATES_K1_COMMENT',
      type: 'date',
    },
  ];

  constructor(protected service: QuestionnaireService) {
    super(service);
  }
}
