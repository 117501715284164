<div [fxLayout]="layout">
  <!-- when not in a radio-group, radio buttons of a group must have the same [name] -->
  @for (answer of multiAnswers; track answer.id) {
    <mat-radio-button
      [name]="multiAnswers[0].code"
      [disabled]="disabled"
      (change)="onMultiChange(answer.index)"
      [value]="answer.index"
      [checked]="answer.value"
      [ngClass]="{
        'answer-saved': answer.modified
      }"
    >
      <span class="abs-half-padding-right">
        {{ options[answer.index] }}
      </span>
    </mat-radio-button>
  }
</div>
