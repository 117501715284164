import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

/**
 * Wrapper for material snackbar.
 */
@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  /**
   * Shows a snackbar.
   *
   * @param message Message content.
   * @param action Action content.
   */
  public open(message: string, action: string = 'Close'): void {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top',
    });
  }
}
