<div fxLayout="column" class="odd-form-section">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <p class="question-info">Name of Counterparty</p>
    <span>Please list the fund's ISDA counterparties on swap, CDS, FX, or other derivative positions, if applicable.</span>
    <table class="abs-align-left" style="width: 80%">
      <tr>
        <th style="width: 2%"></th>
        <th style="width: 60%"></th>
        <th style="width: 10%"></th>
      </tr>
      @for (answer of getAllAnswers('ISDA_COUNTERPARTY_NAME'); track answer.id; let isLast = $last) {
        <tr>
          <td>
            <span>{{ answer.index + 1 }} -</span>
          </td>
          <td>
            <app-questionnaire-text-answer
              [data]="answer"
              (errorhandler)="onError($event)"
              [disabled]="isReviewed() || !!answer.reviewed || !!answer.reviewing"
            ></app-questionnaire-text-answer>
          </td>
          <td>
            @if (isLast && !showReview) {
              <button mat-icon-button aria-label="Add" (click)="addField(['ISDA_COUNTERPARTY_NAME'], answer.index)" [disabled]="creatingAnswers">
                <mat-icon>add_circle</mat-icon>
              </button>
            }
            @if (isLast && showReview) {
              <app-questionnaire-review
                [disabled]="isReviewed()"
                [answers]="getAnswersForReview(['ISDA_COUNTERPARTY_NAME'])"
                (errorhandler)="onError($event)"
              ></app-questionnaire-review>
            }
          </td>
        </tr>
      }
    </table>
  </div>
</div>

<div fxLayout="column" class="odd-form-section">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    @for (comment of comments; track comment.name) {
      <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
        <span class="question-info">{{ comment.label }}</span>
        @if (showReview) {
          <app-questionnaire-review
            [disabled]="isReviewed()"
            [answers]="getAnswersForReview([comment.name])"
            (errorhandler)="onError($event)"
          ></app-questionnaire-review>
        }
      </div>
      <app-questionnaire-comment-answer
        [noMargin]="true"
        [noLabel]="true"
        [highlightChanges]="true"
        [data]="getAnswer(comment.name)"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer(comment.name)?.reviewed || !!getAnswer(comment.name)?.reviewing"
      ></app-questionnaire-comment-answer>
    }
  </div>
</div>
