<mat-card appearance="outlined" fxFlex fxLayout="column" id="questionnaire">
  <mat-card-content fxLayout="column" class="abs-nomargin">
    @if (errorMessage) {
      <app-error-message [message]="errorMessage"></app-error-message>
    }

    <!--Fixed Header -->
    @if (data) {
      <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-half-bottom">
        <h4 fxFlex class="abs-nomargin mat-subtitle-2">ABS Operational Due Diligence Questionnaire for {{ data.company }}</h4>
        <h4 class="abs-nomargin mat-subtitle-2">Status: {{ getStatus() }}</h4>
        <div fxFlex="none" fxLayoutAlign="center center" class="abs-margin-half-right abs-margin-left">
          <button mat-raised-button [matMenuTriggerFor]="menu">Print</button>
          <mat-menu #menu="matMenu">
            <a [href]="getPdfUrl(tabIndex)" mat-menu-item target="_blank">This tab</a>
            <a [href]="getPdfUrl()" mat-menu-item target="_blank">All tabs</a>
          </mat-menu>
        </div>
      </div>
    }
  </mat-card-content>

  @if (data) {
    <mat-card-content class="no-scroll" fxLayout="column">
      <!-- Tabs for data filling -->
      <mat-tab-group (selectedIndexChange)="onTabChange($event)" class="no-scroll" [selectedIndex]="tabIndex">
        <mat-tab label="Overview">
          <app-odd-webform-overview [data]="data" (showError)="onError($event)" [showReview]="isShowReview()"></app-odd-webform-overview>

          <app-odd-webform-overview-funds [data]="data" (showError)="onError($event)" [showReview]="isShowReview()"></app-odd-webform-overview-funds>

          <app-odd-webform-overview-tables
            [data]="data"
            (showError)="onError($event)"
            [showReview]="isShowReview()"
          ></app-odd-webform-overview-tables>
        </mat-tab>
        <mat-tab label="Fund Expenses-Soft Dollar">
          <app-odd-webform-fund-expenses [data]="data" (showError)="onError($event)" [showReview]="isShowReview()"></app-odd-webform-fund-expenses>
        </mat-tab>
        <mat-tab label="Service Providers">
          <app-odd-webform-service-providers
            [data]="data"
            (showError)="onError($event)"
            [showReview]="isShowReview()"
            [companyData]="companyData"
          ></app-odd-webform-service-providers>
        </mat-tab>
        <mat-tab label="Prime Brokerage & Custody">
          <app-odd-webform-prime-brokerage
            [data]="data"
            (showError)="onError($event)"
            [showReview]="isShowReview()"
            [companyData]="companyData"
          ></app-odd-webform-prime-brokerage>
        </mat-tab>
        <mat-tab label="ISDA Counterparty Exposure">
          <app-odd-webform-isda-exposure [data]="data" (showError)="onError($event)" [showReview]="isShowReview()"></app-odd-webform-isda-exposure>
        </mat-tab>
        <mat-tab label="Cash Procedures">
          <app-odd-webform-cash-procedures
            [data]="data"
            (showError)="onError($event)"
            [showReview]="isShowReview()"
          ></app-odd-webform-cash-procedures>
        </mat-tab>
        <mat-tab label="Valuation & Pricing">
          <app-odd-webform-valuation [data]="data" (showError)="onError($event)" [showReview]="isShowReview()"></app-odd-webform-valuation>
        </mat-tab>
        <mat-tab label="Firm Info">
          <app-odd-webform-firm-info [data]="data" (showError)="onError($event)" [showReview]="isShowReview()"></app-odd-webform-firm-info>
        </mat-tab>
        <mat-tab label="Fund Info">
          <app-odd-webform-fund-info [data]="data" (showError)="onError($event)" [showReview]="isShowReview()"></app-odd-webform-fund-info>
        </mat-tab>
        <mat-tab label="Responsible Investing">
          <app-odd-webform-resp-investing [data]="data" (showError)="onError($event)" [showReview]="isShowReview()"></app-odd-webform-resp-investing>
        </mat-tab>
        <mat-tab label="General Info">
          <app-odd-webform-general-info [data]="data" (showError)="onError($event)" [showReview]="isShowReview()"></app-odd-webform-general-info>
        </mat-tab>
        <mat-tab label="IT / Cyber">
          <app-odd-webform-it-cyber [data]="data" (showError)="onError($event)" [showReview]="isShowReview()"></app-odd-webform-it-cyber>
          @if (isReviewing() || isReviewed()) {
            <div fxLayout="column">
              <h4 class="mat-subtitle-2">Signature information:</h4>
              <span class="mat-small">Name</span>
              <span class="mat-body-strong">{{ data.respondentName }}</span>
              <span class="mat-small abs-margin-half-top">Title</span>
              <span class="mat-body-strong">{{ data.respondentTitle }}</span>
              <span class="mat-small abs-margin-half-top">Signed on</span>
              <span class="mat-body-strong">{{ data.respondentFinishDate | date: 'longDate' }}</span>
              <span class="mat-small abs-margin-half-top">IP Address</span>
              <span class="mat-body-strong">{{ data.respondentIpAddress }}</span>
              @if (data.respondentSignature) {
                <span class="mat-small abs-margin-half-top">Signature image</span>
                <span class="mat-body-strong"><img [src]="data.respondentSignature" /></span>
              }
            </div>
          }
          @if (data && isReviewing()) {
            <div fxLayout="row" fxLayoutAlign="end end" class="abs-margin-right">
              <button mat-raised-button color="primary" (click)="finishReview()">Finish Review</button>
            </div>
          }
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  }
</mat-card>
