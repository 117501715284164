import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { UserContext } from 'app/modules/common/model/user.model';
import { UserContextService } from 'app/modules/common/services/user-context.service';
import { environment } from 'environments/environment';
import { Observable, catchError, mergeMap, of, tap } from 'rxjs';
import { JwtToken } from './questionnaire.service';

/**
 * Gets the JWT Token and UserContext for a regular user based on ABSolute cookies.
 */

export const secureAuthResolverService: ResolveFn<Observable<UserContext | { error: string }>> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  http: HttpClient = inject(HttpClient),
  userContextService: UserContextService = inject(UserContextService)
) => {
  window.localStorage.removeItem('auth_token');
  userContextService.clear();

  return http.get<JwtToken>(`${environment.apiUrl}/user/current/token`, { withCredentials: true }).pipe(
    tap((token: JwtToken) => {
      window.localStorage.setItem('auth_token', token.value);
    }),
    mergeMap(() => userContextService.get()),
    catchError((err) => {
      const error = err + ' Please reopen the questionnaire through the ABSolute.';
      return of({ error } as { error: string });
    })
  );
};
