<mat-card appearance="outlined" fxFlex fxLayout="column" id="questionnaire">
  <mat-card-content fxLayout="column" class="abs-nomargin">
    <div>
      <img src="assets/images/abs.png" class="abs-logo" />
    </div>

    <mat-divider [inset]="true"></mat-divider>

    @if (errorMessage) {
      <app-error-message [message]="errorMessage"></app-error-message>
    }

    <!--Fixed Header -->
    @if (data && !finishProgress && !isFinished()) {
      <div fxLayout="row">
        <div fxFlex fxLayout="column">
          <span class="abs-margin-bottom titles-forms">ABS Operational Due Diligence Questionnaire</span>
          <span>You are completing the DDQ for:</span>
          @for (fundName of data.fundNames; track fundName) {
            <span class="abs-margin-left abs-bold">{{ fundName }}</span>
          }
          <div fxLayout="row">
            <span>as of:</span>
            <span class="abs-bold abs-margin-half-left">{{ data.asOfDate | date: 'MM/dd/yyyy' }}</span>
          </div>
        </div>
        <div fxFlex="none" fxLayoutAlign="center center" class="abs-margin-half-right">
          @if (!showWelcome) {
            <button mat-raised-button [matMenuTriggerFor]="menu">Print</button>
          }
          <mat-menu #menu="matMenu">
            <a [href]="getPdfUrl(tabIndex)" mat-menu-item target="_blank">This tab</a>
            <a [href]="getPdfUrl()" mat-menu-item target="_blank">All tabs</a>
          </mat-menu>
        </div>
      </div>
    }
  </mat-card-content>

  @if (isFinished()) {
    <div>
      <h4 class="abs-margin-bottom">ABS Operational Due Diligence Questionnaire</h4>
      <h4>
        This Operational Due Diligence Questionnaire is already completed. Click
        <a [href]="getPdfUrl()" target="_blank">here</a>
        to download a pdf version of the questionnaire.
      </h4>
    </div>
  }

  @if (data && !finishProgress && !isFinished() && showWelcome) {
    <mat-card-content fxLayout="column" style="overflow: auto">
      <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
        <app-odd-webform-welcome [data]="data"></app-odd-webform-welcome>
      </div>
      <div fxLayout="row" class="abs-margin-right">
        <span fxFlex></span>
        <button mat-raised-button color="primary" (click)="startQuestionnaire()">Continue</button>
      </div>
    </mat-card-content>
  }

  @if (data && finishProgress && !isFinished()) {
    <app-questionnaire-conclusion [questionnaire]="data" (cancel)="onCancelFinishQuestionnaire()" (errorhandler)="onError($event)">
      <div>
        <div fxLayout="column" fxLayoutAlign="center center">
          <h2>Operational Due Diligence Questionnaire</h2>
        </div>
        <h3>
          After finished you cannot edit the questionnaire and it will be sent to our ODD team for review.
          <br />
          Are you sure you want to finish?
        </h3>
        <div class="mat-subtitle-2">{{ data.company }}</div>
      </div>
    </app-questionnaire-conclusion>
  }

  @if (data && !showWelcome && !finishProgress && !isFinished()) {
    <mat-card-content class="no-scroll" fxLayout="column">
      <!-- Tabs for data filling -->
      <mat-tab-group (selectedIndexChange)="onTabChange($event)" class="no-scroll">
        <mat-tab label="Overview">
          <app-odd-webform-overview [data]="data" (showError)="onError($event)"></app-odd-webform-overview>

          <app-odd-webform-overview-funds [data]="data" (showError)="onError($event)"></app-odd-webform-overview-funds>

          <app-odd-webform-overview-tables [data]="data" (showError)="onError($event)"></app-odd-webform-overview-tables>
        </mat-tab>
        <mat-tab label="Fund Expenses-Soft Dollar">
          <app-odd-webform-fund-expenses [data]="data" (showError)="onError($event)"></app-odd-webform-fund-expenses>
        </mat-tab>
        <mat-tab label="Service Providers">
          <app-odd-webform-service-providers [data]="data" (showError)="onError($event)"></app-odd-webform-service-providers>
        </mat-tab>
        <mat-tab label="Prime Brokerage & Custody">
          <app-odd-webform-prime-brokerage [data]="data" (showError)="onError($event)"></app-odd-webform-prime-brokerage>
        </mat-tab>
        <mat-tab label="ISDA Counterparty Exposure">
          <app-odd-webform-isda-exposure [data]="data" (showError)="onError($event)"></app-odd-webform-isda-exposure>
        </mat-tab>
        <mat-tab label="Cash Procedures">
          <app-odd-webform-cash-procedures [data]="data" (showError)="onError($event)"></app-odd-webform-cash-procedures>
        </mat-tab>
        <mat-tab label="Valuation & Pricing">
          <app-odd-webform-valuation [data]="data" (showError)="onError($event)"></app-odd-webform-valuation>
        </mat-tab>
        <mat-tab label="Firm Info">
          <app-odd-webform-firm-info [data]="data" (showError)="onError($event)"></app-odd-webform-firm-info>
        </mat-tab>
        <mat-tab label="Fund Info">
          <app-odd-webform-fund-info [data]="data" (showError)="onError($event)"></app-odd-webform-fund-info>
        </mat-tab>
        <mat-tab label="Responsible Investing">
          <app-odd-webform-resp-investing [data]="data" (showError)="onError($event)"></app-odd-webform-resp-investing>
        </mat-tab>
        <mat-tab label="General Info">
          <app-odd-webform-general-info [data]="data" (showError)="onError($event)"></app-odd-webform-general-info>
        </mat-tab>
        <mat-tab label="IT / Cyber">
          <app-odd-webform-it-cyber [data]="data" (showError)="onError($event)"></app-odd-webform-it-cyber>

          <div fxLayout="row" fxLayoutAlign="end end" class="abs-margin-right">
            <button mat-raised-button color="primary" (click)="finishQuestionnaire()">Finish</button>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  }
</mat-card>
