import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { UserContext } from 'app/modules/common/model/user.model';
import { UserContextService } from 'app/modules/common/services/user-context.service';
import { Observable, catchError, mergeMap, of, tap } from 'rxjs';
import { QuestionnaireService } from './questionnaire.service';

/**
 * Guest user auth resolver, gets the token for a questionnaire hash. User context is null.
 */

export const guestAuthResolverService: ResolveFn<Observable<UserContext | { error: string }>> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  questionnaireService: QuestionnaireService = inject(QuestionnaireService),
  userContextService: UserContextService = inject(UserContextService)
) => {
  window.localStorage.removeItem('auth_token');
  return questionnaireService.getToken(route.params.hash).pipe(
    tap(() => {
      userContextService.set(of({} as UserContext));
    }),
    mergeMap(() => userContextService.get()),
    catchError((error) => {
      return of({ error } as { error: string });
    })
  );
};
