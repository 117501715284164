import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadingDialogComponent } from '../../loading-dialog/loading-dialog.component';

/**
 * Wrapper for material dialog service
 */
@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private loadingDialog: MatDialogRef<LoadingDialogComponent> | null = null;

  constructor(private dialog: MatDialog) {}

  public openLoadingDialog(): void {
    if (!this.loadingDialog) {
      this.loadingDialog = this.dialog.open(LoadingDialogComponent, { disableClose: true });
    }
  }

  public closeLoadingDialog(): void {
    if (this.loadingDialog) {
      this.loadingDialog.close();
      this.loadingDialog = null;
    }
  }

  public getService(): MatDialog {
    return this.dialog;
  }
}
