import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QuestionnaireService } from '../../services/questionnaire.service';
import { AttestationBaseComponent } from '../attestation-base/attestation-base.component';

/**
 * Component that represents the entire attestation questionnaire.
 */
@Component({
  selector: 'app-attestation',
  templateUrl: './attestation.component.html',
  styleUrls: ['./attestation.component.scss'],
})
export class AttestationComponent extends AttestationBaseComponent {
  public finishProgress = false;

  constructor(protected route: ActivatedRoute, protected service: QuestionnaireService) {
    super(route, service);
  }

  /**
   * Finishes filling out the questionnaire
   */
  public finishQuestionnaire(): void {
    this.finishProgress = true;
  }

  /**
   * Cancel finish event.
   */
  public onCancelFinishQuestionnaire(): void {
    this.finishProgress = false;
  }
}
