import { Component } from '@angular/core';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { DdqAdviserTabBaseComponent } from '../ddq-adviser-tab-base/ddq-adviser-tab-base.component';

@Component({
  selector: 'app-ddq-adviser-overview',
  templateUrl: './ddq-adviser-overview.component.html',
  styleUrls: ['../ddq-adviser-tab-base/ddq-adviser-tab-base.component.scss'],
})
export class DdqAdviserOverviewComponent extends DdqAdviserTabBaseComponent {
  constructor(protected service: QuestionnaireService) {
    super(service);
  }
}
