import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserContext } from '../model/user.model';
import { UserContextService } from '../services/user-context.service';

/**
 * Layout component for Questionnaire app.
 */
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  public userContext$?: Observable<UserContext>;

  constructor(private userContextService: UserContextService) {}

  ngOnInit(): void {
    this.userContext$ = this.userContextService.get().pipe(catchError(() => of({} as UserContext)));
  }
}
