<div class="mat-mdc-card-content" fxLayout="column">
  <div class="header">
    <ng-content></ng-content>
  </div>
  <div class="abs-field-wrapper">
    <form fxLayout="column" [formGroup]="form" class="abs-padding-right abs-padding-bottom">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" placeholder="Name" required />
        @if (form.get('name')?.hasError('required')) {
          <mat-error>
            Name
            <strong>required</strong>
          </mat-error>
        }
      </mat-form-field>

      <mat-form-field>
        <mat-label>Title</mat-label>
        <input matInput formControlName="title" placeholder="Title" required />
        @if (form.get('title')?.hasError('required')) {
          <mat-error>
            Title
            <strong>required</strong>
          </mat-error>
        }
      </mat-form-field>
      <span>Please sign in the box below: *</span>
      <app-signature-pad #signature></app-signature-pad>
      <div fxLayout="row">
        @if (signatureWarn) {
          <mat-error style="font-size: 75%">
            Please
            <strong>provide a signature</strong>
          </mat-error>
        }
        <div fxFlex></div>
        <button mat-button (click)="clear()" [formNoValidate]="true">Clear</button>
      </div>
      <div class="actions abs-padding-top" fxLayout="row" fxLayoutAlign="end end">
        <button mat-button color="accent" (click)="onCancel()">Cancel</button>
        <button type="submit" [disabled]="!form.valid" mat-raised-button color="primary" (click)="onFinish()">Finish</button>
      </div>
    </form>
  </div>
</div>
