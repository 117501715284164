import { Component, Input } from '@angular/core';
import { Questionnaire } from 'app/modules/questionnaire/model/questionnaire.model';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-ddq-adviser-welcome',
  templateUrl: './ddq-adviser-welcome.component.html',
})
export class DdqAdviserWelcomeComponent {
  @Input() data!: Questionnaire;

  public getStartDate(): Date {
    return DateTime.fromISO(this.data.asOfDate).startOf('year').toJSDate();
  }
}
