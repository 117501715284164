@if (data && (datatypeOptions || options)) {
  <mat-form-field [ngClass]="{ 'answer-saved': data.modified }">
    @if (label) {
      <mat-label>{{ label }}</mat-label>
    }
    <mat-select [(value)]="data.value" (selectionChange)="onValueChange()" [disabled]="disabled" [required]="required">
      @if (!required) {
        <mat-option>Select...</mat-option>
      }
      @for (item of datatypeOptions; track item.value) {
        <mat-option [value]="item.value">{{ item.label }}</mat-option>
      }
      @for (item of options; track item.value) {
        <mat-option [value]="item.value">{{ item.label }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
}
