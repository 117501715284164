<div fxLayout="column" class="odd-form-section">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    @for (comment of comments; track comment.name) {
      <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
        <span class="question-info">{{ comment.label }}</span>
        @if (showReview) {
          <app-questionnaire-review
            [disabled]="isReviewed()"
            [answers]="getAnswersForReview([comment.name])"
            (errorhandler)="onError($event)"
          ></app-questionnaire-review>
        }
      </div>
      <app-questionnaire-comment-answer
        [noMargin]="true"
        [noLabel]="true"
        [highlightChanges]="true"
        [data]="getAnswer(comment.name)"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer(comment.name)?.reviewed || !!getAnswer(comment.name)?.reviewing"
      ></app-questionnaire-comment-answer>
    }
  </div>
</div>

<!-- Tax estimates section -->
<div fxLayout="column" class="odd-form-section">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <p class="question-info">Tax Estimates</p>

    <!-- Currently provides tax estimates -->
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <span>Do you provide tax estimates?</span>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="
            getAnswersForReview([
              'FUND_INFO_TAX_ESTIMATES',
              'FUND_INFO_TAX_ESTIMATES_AS_OF',
              'FUND_INFO_TAX_ESTIMATES_PROVIDED',
              'FUND_INFO_TAX_ESTIMATES_NO',
              'FUND_INFO_TAX_ESTIMATES_NO_AS_OF',
              'FUND_INFO_TAX_ESTIMATES_NO_PROVIDED',
              'FUND_INFO_TAX_ESTIMATES_COMMENT'
            ])
          "
          (errorhandler)="onError($event)"
          reviewHandler="FUND_INFO_TAX_HANDLER"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-select-answer
      style="width: 15%"
      [data]="getAnswer('FUND_INFO_TAX_ESTIMATES')"
      (errorhandler)="onError($event)"
      datatype="yesNoNotAvailable"
      [disabled]="isReviewed() || !!getAnswer('FUND_INFO_TAX_ESTIMATES')?.reviewed || !!getAnswer('FUND_INFO_TAX_ESTIMATES')?.reviewing"
    ></app-questionnaire-select-answer>

    <!-- YES, will provide -->
    @if (getAnswer('FUND_INFO_TAX_ESTIMATES')?.value === 'Yes') {
      <div fxLayout="row">
        <app-questionnaire-date-answer
          class="abs-margin-right"
          label="If yes, as of what date?"
          [data]="getAnswer('FUND_INFO_TAX_ESTIMATES_AS_OF')"
          (errorhandler)="onError($event)"
          [disabled]="
            isReviewed() || !!getAnswer('FUND_INFO_TAX_ESTIMATES_AS_OF')?.reviewed || !!getAnswer('FUND_INFO_TAX_ESTIMATES_AS_OF')?.reviewing
          "
        ></app-questionnaire-date-answer>
        <app-questionnaire-date-answer
          label="When is it provided by?"
          [data]="getAnswer('FUND_INFO_TAX_ESTIMATES_PROVIDED')"
          (errorhandler)="onError($event)"
          [disabled]="
            isReviewed() || !!getAnswer('FUND_INFO_TAX_ESTIMATES_PROVIDED')?.reviewed || !!getAnswer('FUND_INFO_TAX_ESTIMATES_PROVIDED')?.reviewing
          "
        ></app-questionnaire-date-answer>
      </div>
    }

    <!-- NO, do not provide -->
    @if (getAnswer('FUND_INFO_TAX_ESTIMATES')?.value === 'No') {
      <span>If no to the question above, are you willing to provide estimates going forward?</span>
      <app-questionnaire-select-answer
        style="width: 15%"
        [data]="getAnswer('FUND_INFO_TAX_ESTIMATES_NO')"
        (errorhandler)="onError($event)"
        [options]="options"
        [disabled]="isReviewed() || !!getAnswer('FUND_INFO_TAX_ESTIMATES_NO')?.reviewed || !!getAnswer('FUND_INFO_TAX_ESTIMATES_NO')?.reviewing"
      ></app-questionnaire-select-answer>
    }

    <!-- NO, don't provide but willing to provide -->
    @if (getAnswer('FUND_INFO_TAX_ESTIMATES')?.value === 'No' && getAnswer('FUND_INFO_TAX_ESTIMATES_NO')?.value === true) {
      <div fxLayout="row">
        <app-questionnaire-date-answer
          class="abs-margin-right"
          label="If yes, as of what date?"
          [data]="getAnswer('FUND_INFO_TAX_ESTIMATES_NO_AS_OF')"
          (errorhandler)="onError($event)"
          [disabled]="
            isReviewed() || !!getAnswer('FUND_INFO_TAX_ESTIMATES_NO_AS_OF')?.reviewed || !!getAnswer('FUND_INFO_TAX_ESTIMATES_NO_AS_OF')?.reviewing
          "
        ></app-questionnaire-date-answer>
        <app-questionnaire-date-answer
          label="When is it provided by?"
          [data]="getAnswer('FUND_INFO_TAX_ESTIMATES_NO_PROVIDED')"
          (errorhandler)="onError($event)"
          [disabled]="
            isReviewed() ||
            !!getAnswer('FUND_INFO_TAX_ESTIMATES_NO_PROVIDED')?.reviewed ||
            !!getAnswer('FUND_INFO_TAX_ESTIMATES_NO_PROVIDED')?.reviewing
          "
        ></app-questionnaire-date-answer>
      </div>
    }

    <!-- Tax estimates comment -->
    <span>Comment</span>
    <app-questionnaire-comment-answer
      [noMargin]="true"
      [noLabel]="true"
      [highlightChanges]="true"
      [data]="getAnswer('FUND_INFO_TAX_ESTIMATES_COMMENT')"
      (errorhandler)="onError($event)"
      [disabled]="
        isReviewed() || !!getAnswer('FUND_INFO_TAX_ESTIMATES_COMMENT')?.reviewed || !!getAnswer('FUND_INFO_TAX_ESTIMATES_COMMENT')?.reviewing
      "
    ></app-questionnaire-comment-answer>

    <!-- Other Task estimates questions -->
    @for (txEst of taxEstimatesSection; track txEst.name) {
      <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
        <span>{{ txEst.label }}</span>
        @if (showReview) {
          <app-questionnaire-review
            [disabled]="isReviewed()"
            [answers]="getAnswersForReview([txEst.name, txEst.comment])"
            (errorhandler)="onError($event)"
          ></app-questionnaire-review>
        }
      </div>
      @if (txEst.type !== 'date') {
        <app-questionnaire-select-answer
          style="width: 15%"
          [data]="getAnswer(txEst.name)"
          (errorhandler)="onError($event)"
          datatype="yesNoNotAvailable"
          [disabled]="isReviewed() || !!getAnswer(txEst.name)?.reviewed || !!getAnswer(txEst.name)?.reviewing"
        ></app-questionnaire-select-answer>
      }
      @if (txEst.type === 'date') {
        <app-questionnaire-date-answer
          style="width: 15%"
          [data]="getAnswer(txEst.name)"
          (errorhandler)="onError($event)"
          [disabled]="isReviewed() || !!getAnswer(txEst.name)?.reviewed || !!getAnswer(txEst.name)?.reviewing"
        ></app-questionnaire-date-answer>
      }
      <span>Comment</span>
      <app-questionnaire-comment-answer
        [noMargin]="true"
        [noLabel]="true"
        [highlightChanges]="true"
        [data]="getAnswer(txEst.comment)"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer(txEst.comment)?.reviewed || !!getAnswer(txEst.comment)?.reviewing"
      ></app-questionnaire-comment-answer>
    }
  </div>
</div>

<div fxLayout="column" class="odd-form-section">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <span class="question-info">Are the Fund(s) "hard-wired" for purpose of calculating ERISA assets?</span>
      <app-questionnaire-select-answer
        style="width: 15%"
        [data]="getAnswer('FUND_INFO_ERISA_HARD_WIRED')"
        (errorhandler)="onError($event)"
        [options]="options"
        [disabled]="isReviewed() || !!getAnswer('FUND_INFO_ERISA_HARD_WIRED')?.reviewed || !!getAnswer('FUND_INFO_ERISA_HARD_WIRED')?.reviewing"
      ></app-questionnaire-select-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswersForReview(['FUND_INFO_ERISA_HARD_WIRED'])"
          (errorhandler)="onError($event)"
          reviewHandler="FUND_INFO_HARDWIRED_HANDLER"
        ></app-questionnaire-review>
      }
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <span class="question-info">How are you calculating the ERISA percentage of the fund? Please select all that apply.</span>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswersForReview(['FUND_INFO_ERISA_CALCULATION'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    @for (answer of getAllAnswers('FUND_INFO_ERISA_CALCULATION'); track answer.id) {
      <app-questionnaire-checkbox-answer
        [label]="erisaCalculationLabels[answer.index]"
        [data]="answer"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!answer.reviewed || !!answer.reviewing"
      ></app-questionnaire-checkbox-answer>
    }
  </div>
</div>

<div fxLayout="column" class="odd-form-section">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <span class="question-info">Current Erisa</span>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="
            getAnswersForReview([
              'FUND_INFO_ERISA_MASTER_VALUE',
              'FUND_INFO_ERISA_OFFSHORE_VALUE',
              'FUND_INFO_ERISA_ONSHORE_VALUE',
              'FUND_INFO_ERISA_OFFSHORE_CLASS',
              'FUND_INFO_ERISA_OFFSHORE_CLASS_VALUE',
              'FUND_INFO_ERISA_ONSHORE_CLASS',
              'FUND_INFO_ERISA_ONSHORE_CLASS_VALUE'
            ])
          "
          (errorhandler)="onError($event)"
          reviewHandler="FUND_INFO_ERISA_HANDLER"
        ></app-questionnaire-review>
      }
    </div>
    <div fxLayout="column">
      <table class="table-spacing abs-align-left" style="width: 80%">
        <tr>
          <th style="width: 40%"></th>
          <th style="width: 40%">ERISA %</th>
          <th style="width: 15%"></th>
          <th style="width: 5%"></th>
        </tr>
        <!-- Master -->
        <tr>
          <td>Master</td>
          <td>
            <app-questionnaire-decimal-answer
              [fxFlex]="30"
              [data]="getAnswer('FUND_INFO_ERISA_MASTER_VALUE')"
              (errorhandler)="onError($event)"
              [disabled]="
                isReviewed() || !!getAnswer('FUND_INFO_ERISA_MASTER_VALUE')?.reviewed || !!getAnswer('FUND_INFO_ERISA_MASTER_VALUE')?.reviewing
              "
            ></app-questionnaire-decimal-answer>
          </td>
        </tr>

        <!-- Offshore -->
        <tr>
          <td>Offshore feeder or Stand alone</td>
          <td>
            <app-questionnaire-decimal-answer
              [fxFlex]="30"
              [data]="getAnswer('FUND_INFO_ERISA_OFFSHORE_VALUE')"
              (errorhandler)="onError($event)"
              [disabled]="
                isReviewed() || !!getAnswer('FUND_INFO_ERISA_OFFSHORE_VALUE')?.reviewed || !!getAnswer('FUND_INFO_ERISA_OFFSHORE_VALUE')?.reviewing
              "
            ></app-questionnaire-decimal-answer>
          </td>
        </tr>
        <tr>
          <td></td>
          <td class="abs-bold">Share Class (in which ABS invests)</td>
          <td class="abs-bold">ERISA %</td>
        </tr>
        @for (answer of getAllAnswers('FUND_INFO_ERISA_OFFSHORE_CLASS'); track answer.id; let isLast = $last) {
          <tr>
            <td></td>
            <td>
              <app-questionnaire-text-answer
                [data]="answer"
                (errorhandler)="onError($event)"
                [disabled]="isReviewed() || !!answer.reviewed || !!answer.reviewing"
              ></app-questionnaire-text-answer>
            </td>
            <td>
              <app-questionnaire-decimal-answer
                [data]="getAnswer('FUND_INFO_ERISA_OFFSHORE_CLASS_VALUE', answer.index)"
                (errorhandler)="onError($event)"
                [disabled]="
                  isReviewed() ||
                  !!getAnswer('FUND_INFO_ERISA_OFFSHORE_CLASS_VALUE', answer.index)?.reviewed ||
                  !!getAnswer('FUND_INFO_ERISA_OFFSHORE_CLASS_VALUE', answer.index)?.reviewing
                "
              ></app-questionnaire-decimal-answer>
            </td>
            <td>
              @if (isLast && !showReview) {
                <button
                  mat-icon-button
                  aria-label="Add"
                  (click)="addField(['FUND_INFO_ERISA_OFFSHORE_CLASS', 'FUND_INFO_ERISA_OFFSHORE_CLASS_VALUE'], answer.index)"
                  [disabled]="creatingAnswers"
                >
                  <mat-icon>add_circle</mat-icon>
                </button>
              }
            </td>
          </tr>
        }

        <!-- Onshore -->
        <tr>
          <td>Onshore feeder or Stand alone</td>
          <td>
            <app-questionnaire-decimal-answer
              [fxFlex]="30"
              [data]="getAnswer('FUND_INFO_ERISA_ONSHORE_VALUE')"
              (errorhandler)="onError($event)"
              [disabled]="
                isReviewed() || !!getAnswer('FUND_INFO_ERISA_ONSHORE_VALUE')?.reviewed || !!getAnswer('FUND_INFO_ERISA_ONSHORE_VALUE')?.reviewing
              "
            ></app-questionnaire-decimal-answer>
          </td>
        </tr>
        <tr>
          <td></td>
          <td class="abs-bold">Share Class (in which ABS invests)</td>
          <td class="abs-bold">ERISA %</td>
        </tr>
        @for (answer of getAllAnswers('FUND_INFO_ERISA_ONSHORE_CLASS'); track answer.id; let isLast = $last) {
          <tr>
            <td></td>
            <td>
              <app-questionnaire-text-answer
                [data]="answer"
                (errorhandler)="onError($event)"
                [disabled]="isReviewed() || !!answer.reviewed || !!answer.reviewing"
              ></app-questionnaire-text-answer>
            </td>
            <td>
              <app-questionnaire-decimal-answer
                [data]="getAnswer('FUND_INFO_ERISA_ONSHORE_CLASS_VALUE', answer.index)"
                (errorhandler)="onError($event)"
                [disabled]="
                  isReviewed() ||
                  !!getAnswer('FUND_INFO_ERISA_ONSHORE_CLASS_VALUE', answer.index)?.reviewed ||
                  !!getAnswer('FUND_INFO_ERISA_ONSHORE_CLASS_VALUE', answer.index)?.reviewing
                "
              ></app-questionnaire-decimal-answer>
            </td>
            <td>
              @if (isLast && !showReview) {
                <button
                  mat-icon-button
                  aria-label="Add"
                  (click)="addField(['FUND_INFO_ERISA_ONSHORE_CLASS', 'FUND_INFO_ERISA_ONSHORE_CLASS_VALUE'], answer.index)"
                  [disabled]="creatingAnswers"
                >
                  <mat-icon>add_circle</mat-icon>
                </button>
              }
            </td>
          </tr>
        }
      </table>
    </div>
  </div>
</div>

<div fxLayout="column" class="odd-form-section">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <span class="question-info">Who is monitoring your ERISA capacity?</span>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswersForReview(['FUND_INFO_ERISA_MONITORING'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-radio-answer
      class="abs-margin-bottom"
      [multiAnswers]="getAllAnswers('FUND_INFO_ERISA_MONITORING')"
      [options]="erisaMonitoringLabels"
      (errorhandler)="onError($event)"
      layout="column"
      [disabled]="isReviewed() || !!getAnswer('FUND_INFO_ERISA_MONITORING', 0)?.reviewed || !!getAnswer('FUND_INFO_ERISA_MONITORING', 0)?.reviewing"
    ></app-questionnaire-radio-answer>

    @for (comment of lastComments; track comment.name) {
      <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
        <span class="question-info">{{ comment.label }}</span>
        @if (showReview) {
          <app-questionnaire-review
            [disabled]="isReviewed()"
            [answers]="getAnswersForReview([comment.name])"
            (errorhandler)="onError($event)"
          ></app-questionnaire-review>
        }
      </div>
      <app-questionnaire-comment-answer
        [noMargin]="true"
        [noLabel]="true"
        [highlightChanges]="true"
        [data]="getAnswer(comment.name)"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer(comment.name)?.reviewed || !!getAnswer(comment.name)?.reviewing"
      ></app-questionnaire-comment-answer>
    }
  </div>
</div>
