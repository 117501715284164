import { Component, Input } from '@angular/core';
import { Questionnaire } from 'app/modules/questionnaire/model/questionnaire.model';

@Component({
  selector: 'app-odd-webform-welcome',
  templateUrl: './odd-webform-welcome.component.html',
  styleUrls: ['./odd-webform-welcome.component.scss'],
})
export class OddWebformWelcomeComponent {
  @Input() data!: Questionnaire;
}
