import { Component } from '@angular/core';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { OddWebformTabBaseComponent } from '../odd-webform-tab-base/odd-webform-tab-base.component';

@Component({
  selector: 'app-odd-webform-isda-exposure',
  templateUrl: './odd-webform-isda-exposure.component.html',
  styleUrls: ['../odd-webform-tab-base/odd-webform-tab-base.component.scss'],
})
export class OddWebformIsdaExposureComponent extends OddWebformTabBaseComponent {
  comments = [
    {
      label: 'What are the default terms (i.e. NAV triggers) of each ISDA agreement? Are you agreements uni-lateral or bi-lateral?',
      name: 'ISDA_QUESTION_F1',
    },
    {
      label: 'What percentage of excess collateral would you leave at any counterparty before actively moving assets?',
      name: 'ISDA_QUESTION_F3',
    },
    {
      label: 'Do you actively hedge your FX exposure in regards to foreign securities? If so, how?',
      name: 'ISDA_QUESTION_F4',
    },
  ];

  constructor(protected service: QuestionnaireService) {
    super(service);
  }
}
