import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { Observable, forkJoin, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Answer, Questionnaire, QuestionnaireStatus } from '../../../../model/questionnaire.model';

@Component({
  template: '',
  styleUrls: ['./ddq-adviser-tab-base.component.scss'],
})
export abstract class DdqAdviserTabBaseComponent extends OnDestroyMixin {
  @Input() data!: Questionnaire;

  @Input() showReview = false;

  @Output() showError = new EventEmitter<string | null>();

  public creatingAnswers: boolean = false;

  constructor(protected service: QuestionnaireService) {
    super();
  }

  public onError(message: string | null): void {
    this.showError.emit(message);
  }

  /**
   * Get an answer in the questionnaire for a specific index
   * @param code the answer code.
   */
  public getAnswer(code: string, index?: number): Answer {
    const idx = index || 0;
    return this.data.answers.find((answer) => answer.code === code && answer.index === idx)!;
  }

  /**
   * Check questionnaire is reviewed.
   */
  public isReviewed(): boolean {
    return QuestionnaireStatus.REVIEWED === this.data.status;
  }

  /**
   * Get answers in the questionnaire, for index 0, by each code
   * @param codes the answer code.
   */
  public getAnswers(codes: string[]): Answer[] {
    return codes.map((code) => this.getAnswer(code));
  }

  /**
   * Get all answer in the questionnaire, for each code, all indexes
   * @param codes the answer code.
   */
  public getAnswersForReview(codes: string[]): Answer[] {
    return codes.reduce((results: Answer[], code) => results.concat(this.getAllAnswers(code)), []);
  }

  /**
   * Get all answers for a repeating question in the questionnaire.
   * @param code the answer code.
   */
  public getAllAnswers(code: string): Answer[] {
    return this.data?.answers?.filter((answer) => answer.code === code);
  }

  /**
   * Add a field to the questionnaire
   *
   * @param fields - list of field codes
   * @param lastIndex - index of the last question
   */
  public addField(fields: string[], lastIndex: number): void {
    this.creatingAnswers = true;

    forkJoin(fields.map((field) => this.createAnswer(this.getAnswer(field, lastIndex)!)))
      .pipe(
        finalize(() => {
          this.creatingAnswers = false;
        })
      )
      .subscribe();
  }

  /**
   * Call the service to create a new answer instance for a multi answer question
   *
   * @param baseAnswer Answer data.
   */
  private createAnswer(baseAnswer: Answer): Observable<Answer> {
    return this.service.createAnswer(baseAnswer).pipe(
      tap((answer) => {
        this.data.answers.push(answer);
        this.showError.emit(null);
        console.log('Created new question: ' + baseAnswer!.code + ' with index: ' + (baseAnswer!.index || 0 + 1));
      }),
      catchError((error) => {
        this.onError(error);
        return throwError(error);
      })
    );
  }

  /**
   * Check if a number is valid, returns zero otherwise
   */
  public getNumber(val: number): number {
    return val || 0;
  }

  /**
   * Safe sum of two numbers
   *
   * @param v1 - first value
   * @param v2 - second value
   */
  public sumFields(v1: number, v2: number): number {
    return (v1 || 0) + (v2 || 0);
  }
}
