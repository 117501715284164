<mat-card appearance="outlined" id="questionnaire" class="mat-mdc-card-content">
  @if (errorMessage) {
    <app-error-message [message]="errorMessage"></app-error-message>
  }
  @if (data) {
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-half-bottom">
      <h4 fxFlex class="abs-nomargin mat-subtitle-2">Sub-advisor attestation for {{ data.company }}</h4>
      <h4 class="abs-nomargin mat-subtitle-2">Status: {{ getStatus() }}</h4>
      <div fxFlex="none" fxLayoutAlign="center center" class="abs-margin-half-right abs-margin-left">
        <button mat-raised-button (click)="$window.open(getPdfUrl())">Print</button>
      </div>
    </div>
    @if (isCreated()) {
      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field fxFlex="30">
          <mat-label>Company Name</mat-label>
          <input type="text" matInput [(ngModel)]="data.company" />
        </mat-form-field>
        <button mat-button color="accent" (click)="updateCompanyName()">UPDATE</button>
      </div>
    }
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-form-field fxFlex="16">
        <mat-label>SMA Agreement on</mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="data.smaAgreement" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <button mat-button color="accent" (click)="updateSmaDate()">UPDATE</button>
    </div>
    <div fxLayout="column" fxLayoutGap="20px">
      <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
        @for (key of getAnswerKeys(); track key) {
          <app-questionnaire-question [name]="descriptor[key].title">
            <div fxLayout="row " fxLayoutAlign="start center">
              <app-questionnaire-boolean-answer
                fxFlex="150px"
                [disabled]="isReviewed() || !!getAnswer(key)!.reviewed || !!getAnswer(key)!.reviewing"
                [data]="getAnswer(key)"
              ></app-questionnaire-boolean-answer>
              @if (isReviewed() || isReviewing()) {
                <app-questionnaire-review
                  [disabled]="isReviewed()"
                  [answers]="[getAnswer(key)!, getAnswer(key + '_COMMENTS')!]"
                  (errorhandler)="onError($event)"
                ></app-questionnaire-review>
              }
            </div>
            <app-questionnaire-comment-answer
              [disabled]="isReviewed() || !!getAnswer(key)!.reviewed || !!getAnswer(key)!.reviewing"
              [data]="getAnswer(key + '_COMMENTS')"
            ></app-questionnaire-comment-answer>
          </app-questionnaire-question>
        }
        @if (isReviewing() || isReviewed()) {
          <div fxLayout="column">
            <h4 class="mat-body-strong">Signature information:</h4>
            <span class="mat-small">Name</span>
            <span class="mat-body-strong">{{ data.respondentName }}</span>
            <span class="mat-small abs-margin-half-top">Title</span>
            <span class="mat-body-strong">{{ data.respondentTitle }}</span>
            <span class="mat-small abs-margin-half-top">Signed on</span>
            <span class="mat-body-strong">{{ data.respondentFinishDate | date: 'longDate' }}</span>
            <span class="mat-small abs-margin-half-top">IP Address</span>
            <span class="mat-body-strong">{{ data.respondentIpAddress }}</span>
            @if (data.respondentSignature) {
              <span class="mat-small abs-margin-half-top">Signature image</span>
              <span class="mat-body-strong"><img [src]="data.respondentSignature" /></span>
            }
          </div>
        }
      </div>
      @if (isReviewing()) {
        <div fxLayout="row" fxLayoutAlign="end end">
          <button mat-raised-button color="primary" (click)="finishReview()">Finish Review</button>
        </div>
      }
    </div>
  }
</mat-card>
