import { Component, OnInit } from '@angular/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { tap } from 'rxjs/operators';
import { QuestionnaireAnswerBaseComponent } from '../questionnaire-answer-util/questionnaire-answer-base.component';

@Component({
  selector: 'app-questionnaire-text-answer',
  templateUrl: './questionnaire-text-answer.component.html',
  styleUrls: ['./questionnaire-text-answer.component.scss'],
})
export class QuestionnaireTextAnswerComponent extends QuestionnaireAnswerBaseComponent implements OnInit {
  constructor(protected service: QuestionnaireService) {
    super(service);
  }

  ngOnInit(): void {
    this.changeListener
      ?.pipe(
        tap(($event) => {
          if ($event.index === this.data!.index) {
            this.data!.value = $event.value;
            this.onValueChange();
          }
        }),
        untilComponentDestroyed(this)
      )
      .subscribe();
  }
}
