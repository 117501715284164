<div fxLayout="column" class="form-section" fxLayoutGap="8px">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    @for (comment of comments; track comment.name) {
      <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
        <span>{{ comment.label }}</span>
        @if (showReview) {
          <app-questionnaire-review
            [disabled]="isReviewed()"
            [answers]="getAnswersForReview([comment.name])"
            (errorhandler)="onError($event)"
          ></app-questionnaire-review>
        }
      </div>
      <app-questionnaire-comment-answer
        [noMargin]="true"
        [noLabel]="true"
        [highlightChanges]="true"
        [data]="getAnswer(comment.name)"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer(comment.name).reviewed || !!getAnswer(comment.name).reviewing"
      ></app-questionnaire-comment-answer>
    }

    <span class="abs-nomargin">
      Have there been any material changes made to your firm's business continuity/business recovery plan or policies relating to, among other areas:
    </span>
    <ul class="abs-nomargin">
      <li>protection and recovery of your firm's human, information, and physical assets;</li>
      <li>oversight structure and process;</li>
      <li>maintenance of offsite facilities;</li>
      <li>frequency of tests that are conducted; or</li>
      <li>back-up procedures?</li>
    </ul>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between abs-margin-half-top">
      <app-questionnaire-boolean-answer
        [highlightChanges]="true"
        [data]="getAnswer('DDQ_TECH_BUSINESS_CYBER_Q2')"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q2').reviewed || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q2').reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswers(['DDQ_TECH_BUSINESS_CYBER_Q2', 'DDQ_TECH_BUSINESS_CYBER_Q2_COMMENTS'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <span class="abs-nomargin">If yes, describe, including the reason for the change.</span>
    <app-questionnaire-comment-answer
      [noLabel]="true"
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_TECH_BUSINESS_CYBER_Q2_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="
        isReviewed() || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q2_COMMENTS').reviewed || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q2_COMMENTS').reviewing
      "
    ></app-questionnaire-comment-answer>

    <span>Has your firm conducted an assessment of client privacy and/or cybersecurity risks?</span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [highlightChanges]="true"
        [data]="getAnswer('DDQ_TECH_BUSINESS_CYBER_Q3')"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q3').reviewed || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q3').reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswers(['DDQ_TECH_BUSINESS_CYBER_Q3', 'DDQ_TECH_BUSINESS_CYBER_Q3_COMMENTS'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <span class="abs-nomargin">
      If yes, please provide assessment report or summary thereof, or otherwise describe the assessment and any relevant findings.
    </span>
    <app-questionnaire-comment-answer
      [noLabel]="true"
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_TECH_BUSINESS_CYBER_Q3_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="
        isReviewed() || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q3_COMMENTS').reviewed || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q3_COMMENTS').reviewing
      "
    ></app-questionnaire-comment-answer>

    <span>Does the firm have policies and procedures for cybersecurity intrusion monitoring and detection?</span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [highlightChanges]="true"
        [data]="getAnswer('DDQ_TECH_BUSINESS_CYBER_Q4')"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q4').reviewed || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q4').reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswers(['DDQ_TECH_BUSINESS_CYBER_Q4', 'DDQ_TECH_BUSINESS_CYBER_Q4_COMMENTS'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      label="If yes, please describe."
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_TECH_BUSINESS_CYBER_Q4_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="
        isReviewed() || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q4_COMMENTS').reviewed || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q4_COMMENTS').reviewing
      "
    ></app-questionnaire-comment-answer>

    <span>Has the firm identified weaknesses in the firm's technology infrastructure?</span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [highlightChanges]="true"
        [data]="getAnswer('DDQ_TECH_BUSINESS_CYBER_Q5')"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q5').reviewed || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q5').reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswers(['DDQ_TECH_BUSINESS_CYBER_Q5', 'DDQ_TECH_BUSINESS_CYBER_Q5_COMMENTS'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      label="If yes, please describe."
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_TECH_BUSINESS_CYBER_Q5_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="
        isReviewed() || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q5_COMMENTS').reviewed || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q5_COMMENTS').reviewing
      "
    ></app-questionnaire-comment-answer>

    <span>Has the firm conducted a test of its incident response plan in the last twelve months?</span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [highlightChanges]="true"
        [data]="getAnswer('DDQ_TECH_BUSINESS_CYBER_Q6')"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q6').reviewed || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q6').reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswers(['DDQ_TECH_BUSINESS_CYBER_Q6', 'DDQ_TECH_BUSINESS_CYBER_Q6_COMMENTS'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      label="If yes, please describe."
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_TECH_BUSINESS_CYBER_Q6_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="
        isReviewed() || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q6_COMMENTS').reviewed || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q6_COMMENTS').reviewing
      "
    ></app-questionnaire-comment-answer>

    <span>Does the firm have a cybersecurity insurance policy?</span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [highlightChanges]="true"
        [data]="getAnswer('DDQ_TECH_BUSINESS_CYBER_Q7')"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q7').reviewed || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q7').reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswers(['DDQ_TECH_BUSINESS_CYBER_Q7', 'DDQ_TECH_BUSINESS_CYBER_Q7_COMMENTS'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      label="If yes, please describe."
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_TECH_BUSINESS_CYBER_Q7_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="
        isReviewed() || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q7_COMMENTS').reviewed || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q7_COMMENTS').reviewing
      "
    ></app-questionnaire-comment-answer>

    <span>Do you use multi-factor authentication wherever possible?</span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [highlightChanges]="true"
        [data]="getAnswer('DDQ_TECH_BUSINESS_CYBER_Q19')"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q19').reviewed || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q19').reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswers(['DDQ_TECH_BUSINESS_CYBER_Q19', 'DDQ_TECH_BUSINESS_CYBER_Q19_COMMENTS'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      label="Please provide a brief description."
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_TECH_BUSINESS_CYBER_Q19_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="
        isReviewed() || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q19_COMMENTS').reviewed || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q19_COMMENTS').reviewing
      "
    ></app-questionnaire-comment-answer>

    <span>Have you run a tabletop test of your Incident Response Plan?</span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-boolean-answer
        [highlightChanges]="true"
        [data]="getAnswer('DDQ_TECH_BUSINESS_CYBER_Q20')"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q20').reviewed || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q20').reviewing"
      ></app-questionnaire-boolean-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswers(['DDQ_TECH_BUSINESS_CYBER_Q20', 'DDQ_TECH_BUSINESS_CYBER_Q20_COMMENTS'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      label="Please provide a brief description."
      [noMargin]="true"
      [highlightChanges]="true"
      [data]="getAnswer('DDQ_TECH_BUSINESS_CYBER_Q20_COMMENTS')"
      (errorhandler)="onError($event)"
      [disabled]="
        isReviewed() || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q20_COMMENTS').reviewed || !!getAnswer('DDQ_TECH_BUSINESS_CYBER_Q20_COMMENTS').reviewing
      "
    ></app-questionnaire-comment-answer>
  </div>
</div>
