<div fxLayout="column" class="odd-form-section">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <span class="question-info">
      To the best of your ability, please provide the ASC 820 / IFRS 7 estimate valuation of the fund by grossing assets and liabilities both as
      positive numbers. This is how administrators will calculate numbers on transparency reports. (Must equal 100%)
    </span>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <div>Gross</div>
      @for (field of grossFields; track field.name) {
        <div fxLayout="row" fxLayoutAlign="start center">
          <app-questionnaire-decimal-answer
            [label]="field.label"
            [data]="getAnswer(field.name)"
            (errorhandler)="onError($event)"
            [disabled]="isReviewed() || !!getAnswer(field.name)?.reviewed || !!getAnswer(field.name)?.reviewing"
          ></app-questionnaire-decimal-answer>
        </div>
      }
      <div>{{ grossTotal | number: '1.2-2' }}%</div>

      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="
            getAnswersForReview([
              'VAL_ASC_LEVEL1',
              'VAL_ASC_LEVEL2',
              'VAL_ASC_LEVEL3',
              'VAL_ASC_LEVEL3_ASSET_DESCRIPTION',
              'VAL_ASC_LEVEL3_REMOVED_ASSETS'
            ])
          "
          (errorhandler)="onError($event)"
          reviewHandler="VALUATION_HANDLER"
        ></app-questionnaire-review>
      }
    </div>

    <span class="question-info">If applicable, please describe what types of assets comprise your level 3 securities.</span>
    <app-questionnaire-comment-answer
      [noMargin]="true"
      [noLabel]="true"
      [highlightChanges]="true"
      [data]="getAnswer('VAL_ASC_LEVEL3_ASSET_DESCRIPTION')"
      (errorhandler)="onError($event)"
      [disabled]="
        isReviewed() || !!getAnswer('VAL_ASC_LEVEL3_ASSET_DESCRIPTION')?.reviewed || !!getAnswer('VAL_ASC_LEVEL3_ASSET_DESCRIPTION')?.reviewing
      "
    ></app-questionnaire-comment-answer>

    <span class="question-info">
      If applicable, have you removed any assets from your Fair Value Measurement for any reason (ie due to changing guidance like ASU 2015-07, which
      describes excluding investments measured using the NAV practical expedient from the classification of the ASC 820 hierarchy)?
    </span>
    <app-questionnaire-comment-answer
      [noMargin]="true"
      [noLabel]="true"
      [highlightChanges]="true"
      [data]="getAnswer('VAL_ASC_LEVEL3_REMOVED_ASSETS')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('VAL_ASC_LEVEL3_REMOVED_ASSETS')?.reviewed || !!getAnswer('VAL_ASC_LEVEL3_REMOVED_ASSETS')?.reviewing"
    ></app-questionnaire-comment-answer>
  </div>
</div>

<div fxLayout="column" class="odd-form-section">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <span class="question-info">Is the fund administrator independently pricing the entire portfolio?</span>
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100" fxLayoutGap="18px">
      @for (field of pricingFields; track field.name) {
        <div fxFlex="18">
          <app-questionnaire-decimal-answer
            [label]="field.label"
            [data]="getAnswer(field.name)"
            (errorhandler)="onError($event)"
            [disabled]="isReviewed() || !!getAnswer(field.name)?.reviewed || !!getAnswer(field.name)?.reviewing"
          ></app-questionnaire-decimal-answer>
        </div>
      }
      <div>{{ pricingTotal | number: '1.2-2' }}%</div>

      <div fxFlex>
        @if (showReview) {
          <app-questionnaire-review
            [disabled]="isReviewed()"
            [answers]="getAnswersForReview(['VAL_INDEP_PRICED', 'VAL_INDEP_3P_PRICED', 'VAL_INDEP_PRICING_MODEL', 'VAL_INDEP_OTHER'])"
            (errorhandler)="onError($event)"
          ></app-questionnaire-review>
        }
      </div>
    </div>
  </div>
</div>

<div fxLayout="column" class="odd-form-section">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <span class="question-info">
        Please provide the names of the pricing sources (i.e. third party pricing firms, pricing models, other, etc.) and any additional comments.
      </span>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="getAnswersForReview(['VAL_PRICING_SOURCES_NAMES'])"
          (errorhandler)="onError($event)"
        ></app-questionnaire-review>
      }
    </div>
    <app-questionnaire-comment-answer
      [noMargin]="true"
      [noLabel]="true"
      [highlightChanges]="true"
      [data]="getAnswer('VAL_PRICING_SOURCES_NAMES')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('VAL_PRICING_SOURCES_NAMES')?.reviewed || !!getAnswer('VAL_PRICING_SOURCES_NAMES')?.reviewing"
    ></app-questionnaire-comment-answer>
  </div>
</div>
