import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SnackBarService } from 'app/modules/common/services/snack-bar.service';
import { QuestionnaireStatus } from 'app/modules/questionnaire/model/questionnaire.model';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { QuestionnaireBaseComponent } from '../../../common/questionnaire-base/questionnaire-base.component';

@Component({
  selector: 'app-ddq-adviser-review',
  templateUrl: './ddq-adviser-review.component.html',
  styleUrls: ['./ddq-adviser-review.component.scss'],
})
export class DdqAdviserReviewComponent extends QuestionnaireBaseComponent {
  constructor(protected route: ActivatedRoute, protected service: QuestionnaireService, private snackBar: SnackBarService) {
    super(route, service);
  }

  /**
   * Finishes the questionnaire review.
   */
  public finishReview(): void {
    this.service.finishReview(this.data!.id).subscribe(
      () => {
        this.data!.status = QuestionnaireStatus.REVIEWED;
        this.snackBar.open('Questionnaire reviewed successfully.');
        this.clearError();
      },
      (errorMessage) => this.onError(errorMessage)
    );
  }
}
