@if (data) {
  <mat-radio-group
    [disabled]="disabled"
    aria-label="Yes/No"
    [(ngModel)]="data.value"
    (change)="onValueChange()"
    [ngClass]="{ 'answer-saved': data.modified && highlightChanges }"
    >
    <mat-radio-button [value]="true">Yes</mat-radio-button>
    <mat-radio-button [value]="false">No</mat-radio-button>
  </mat-radio-group>
}
