import { CommonModule as AngularCommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatModule } from 'app/mat.module';
import { CommonModule } from '../../../common/common.module';
import { QuestionnaireModule } from '../../questionnaire.module';
import { DdqAdviserAuditsComponent } from './components/ddq-adviser-audits/ddq-adviser-audits.component';
import { DdqAdviserComplianceComponent } from './components/ddq-adviser-compliance/ddq-adviser-compliance.component';
import { DdqAdviserFirmInfoComponent } from './components/ddq-adviser-firm-info/ddq-adviser-firm-info.component';
import { DdqAdviserInspectionsComponent } from './components/ddq-adviser-inspections/ddq-adviser-inspections.component';
import { DdqAdviserInsuranceComponent } from './components/ddq-adviser-insurance/ddq-adviser-insurance.component';
import { DdqAdviserInvestmentComponent } from './components/ddq-adviser-investment/ddq-adviser-investment.component';
import { DdqAdviserItCyberComponent } from './components/ddq-adviser-it-cyber/ddq-adviser-it-cyber.component';
import { DdqAdviserOrganizationComponent } from './components/ddq-adviser-organization/ddq-adviser-organization.component';
import { DdqAdviserOtherAccountsComponent } from './components/ddq-adviser-other-accounts/ddq-adviser-other-accounts.component';
import { DdqAdviserOtherComponent } from './components/ddq-adviser-other/ddq-adviser-other.component';
import { DdqAdviserOverviewFundsComponent } from './components/ddq-adviser-overview/ddq-adviser-overview-funds/ddq-adviser-overview-funds.component';
import { DdqAdviserOverviewTablesComponent } from './components/ddq-adviser-overview/ddq-adviser-overview-tables/ddq-adviser-overview-tables.component';
import { DdqAdviserOverviewComponent } from './components/ddq-adviser-overview/ddq-adviser-overview.component';
import { DdqAdviserReviewComponent } from './components/ddq-adviser-review/ddq-adviser-review.component';
import { DdqAdviserTransactionsComponent } from './components/ddq-adviser-transactions/ddq-adviser-transactions.component';
import { DdqAdviserWelcomeComponent } from './components/ddq-adviser-welcome/ddq-adviser-welcome.component';
import { DdqAdviserComponent } from './components/ddq-adviser/ddq-adviser.component';
import { DdqAdviserRespInvestingComponent } from './components/ddq-adviser-resp-investing/ddq-adviser-resp-investing.component';
import { DdqAdviserServiceProvidersComponent } from './components/ddq-adviser-service-providers/ddq-adviser-service-providers.component';
import { DdqAdviserGeneralInfoComponent } from './components/ddq-adviser-general-info/ddq-adviser-general-info.component';

@NgModule({
  declarations: [
    DdqAdviserComponent,
    DdqAdviserWelcomeComponent,
    DdqAdviserOrganizationComponent,
    DdqAdviserComplianceComponent,
    DdqAdviserInspectionsComponent,
    DdqAdviserAuditsComponent,
    DdqAdviserInvestmentComponent,
    DdqAdviserTransactionsComponent,
    DdqAdviserOtherAccountsComponent,
    DdqAdviserInsuranceComponent,
    DdqAdviserItCyberComponent,
    DdqAdviserOtherComponent,
    DdqAdviserReviewComponent,
    DdqAdviserOverviewComponent,
    DdqAdviserOverviewFundsComponent,
    DdqAdviserOverviewTablesComponent,
    DdqAdviserFirmInfoComponent,
    DdqAdviserRespInvestingComponent,
    DdqAdviserServiceProvidersComponent,
    DdqAdviserGeneralInfoComponent,
  ],
  imports: [AngularCommonModule, FormsModule, MatModule, CommonModule, ReactiveFormsModule, QuestionnaireModule],
})
export class DdqAdviserModule {}
