import { AfterContentChecked, Component } from '@angular/core';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { OddWebformTabBaseComponent } from '../odd-webform-tab-base/odd-webform-tab-base.component';

@Component({
  selector: 'app-odd-webform-valuation',
  templateUrl: './odd-webform-valuation.component.html',
  styleUrls: ['../odd-webform-tab-base/odd-webform-tab-base.component.scss'],
})
export class OddWebformValuationComponent extends OddWebformTabBaseComponent implements AfterContentChecked {
  public grossTotal = 0;

  public grossFields = [
    { label: 'Level 1', name: 'VAL_ASC_LEVEL1' },
    { label: 'Level 2', name: 'VAL_ASC_LEVEL2' },
    { label: 'Level 3', name: 'VAL_ASC_LEVEL3' },
  ];

  public pricingFields = [
    { label: '% Independently Priced', name: 'VAL_INDEP_PRICED' },
    { label: '% Third Party Priced', name: 'VAL_INDEP_3P_PRICED' },
    { label: '% Pricing Model', name: 'VAL_INDEP_PRICING_MODEL' },
    { label: '% Other', name: 'VAL_INDEP_OTHER' },
  ];

  public pricingTotal = 0;

  constructor(protected service: QuestionnaireService) {
    super(service);
  }

  ngAfterContentChecked(): void {
    this.grossTotal = 0;
    this.pricingTotal = 0;

    this.grossTotal = this.grossFields.reduce((total, field) => total + this.getNumber(this.getAnswer(field.name)!.value), 0);

    this.pricingTotal = this.pricingFields.reduce((total, field) => total + this.getNumber(this.getAnswer(field.name)!.value), 0);
  }
}
