import { Company } from 'app/modules/common/services/company.service';
import { Contact } from 'app/modules/common/services/contact.service';

/**
 * Questionnaire status
 */
export enum QuestionnaireStatus {
  PENDING = 1,
  CREATED = 2,
  READY_TO_SEND = 3,
  SENT = 4,
  IN_PROGRESS = 5,
  TO_BE_REVIEWED = 6,
  REVIEWED = 7,
  DELETED = 8,
}

export enum QuestionnaireType {
  ODD = 'ODD',
  ATTESTATION = 'ATTESTATION',
  DDQ_ADVISER = 'DDQ_ADVISER',
}

export const QuestionnaireStatusLabel = new Map<number, string>([
  [QuestionnaireStatus.PENDING, 'Pending'],
  [QuestionnaireStatus.CREATED, 'Created'],
  [QuestionnaireStatus.READY_TO_SEND, 'Ready to send'],
  [QuestionnaireStatus.SENT, 'Sent'],
  [QuestionnaireStatus.IN_PROGRESS, 'In progress'],
  [QuestionnaireStatus.TO_BE_REVIEWED, 'To be reviewed'],
  [QuestionnaireStatus.REVIEWED, 'Reviewed'],
  [QuestionnaireStatus.DELETED, 'Deleted'],
]);

/**
 * Represents a Questionnaire.
 */
export interface Questionnaire {
  id: number;
  status: number;
  company: string;
  asOfDate: string;
  smaAgreement?: Date | string;
  answers: Answer[];
  endDate: Date;
  fundNames: string[];
  questionnaireType: string;
  respondentName?: string;
  respondentTitle?: string;
  respondentIpAddress?: string;
  respondentFinishDate?: Date;
  respondentSignature?: string;
}

/**
 * Represents a questionnaire answer.
 */
export interface Answer {
  id: number;
  code: string;
  index: number;
  modified: AnswerAction;
  reviewed: AnswerAction;
  // eslint-disable-next-line
  value: any;
  version: number;
  datatype: string;
  valueAsDate?: Date;
  saving?: boolean;
  reviewing?: boolean;
}

/**
 * Represents a action information in an answer.
 */
export interface AnswerAction {
  idtUser: number;
  userName: string;
  date: Date;
}

/**
 * Represents the user data for conclusion action.
 */
export interface QuestionnaireConclusionData {
  name: string;
  title: string;
  ipAddress: string;
  signature: string;
}

export interface ReviewAction {
  action: string;
  name?: string;
  id?: number;
}

/**
 * Represents answers review data.
 */
export interface ReviewData {
  answers: Answer[];
  reviewHandler: string;
  reviewActions?: ReviewAction[];
}

/**
 * Represents strategy companies info data
 */
export interface CompanyReviewData {
  companies: { [key: number]: Company };
  primeBrokers: Company[];
  fundDirectors: Company[];
  custodians: Company[];
  contacts: { [key: number]: Contact[] };
}

export type IndexedValue = {
  index?: number;
  value?: string;
};
