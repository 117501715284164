import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SnackBarService } from 'app/modules/common/services/snack-bar.service';
import { QuestionnaireStatus } from '../../model/questionnaire.model';
import { QuestionnaireService } from '../../services/questionnaire.service';
import { AttestationBaseComponent } from '../attestation-base/attestation-base.component';

@Component({
  selector: 'app-attestation-edit',
  templateUrl: './attestation-edit.component.html',
  styleUrls: ['./attestation-edit.component.scss'],
})
export class AttestationEditComponent extends AttestationBaseComponent {
  $window = window;

  constructor(protected route: ActivatedRoute, service: QuestionnaireService, private snackBar: SnackBarService) {
    super(route, service);
  }

  /**
   * Finishes the questionnaire review.
   */
  public finishReview(): void {
    this.service.finishReview(this.data!.id).subscribe(
      () => {
        this.data!.status = QuestionnaireStatus.REVIEWED;
        this.snackBar.open('Questionnaire reviewed successfully.');
        this.clearError();
      },
      (errorMessage) => this.onError(errorMessage)
    );
  }

  /**
   * Updates SMA Agreement date.
   */
  public updateSmaDate(): void {
    this.service.updateSmaAgreement(this.data!).subscribe(
      () => {
        this.snackBar.open('SMA Agreement on date updated successfully.');
        this.clearError();
      },
      (errorMessage) => this.onError(errorMessage)
    );
  }

  /**
   * Updates Company name
   */
  public updateCompanyName(): void {
    this.service.updateCompanyName(this.data!).subscribe(
      () => {
        this.snackBar.open('Company Name updated successfully.');
        this.clearError();
      },
      (errorMessage) => this.onError(errorMessage)
    );
  }
}
