@if (userContext$ | async; as userContext) {
  @if (userContext.user) {
    <mat-toolbar color="primary" fxLayout="row">
      <span>ABS Questionnaires</span>
      <span fxFlex></span>
      <button mat-icon-button>
        <mat-icon>person</mat-icon>
      </button>
      <span>{{ userContext.user.name }}</span>
    </mat-toolbar>
  }
}
<div class="container">
  <router-outlet></router-outlet>
</div>
