import { Component } from '@angular/core';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { OddWebformTabBaseComponent } from '../../odd-webform-tab-base/odd-webform-tab-base.component';

@Component({
  selector: 'app-odd-webform-overview-funds',
  templateUrl: './odd-webform-overview-funds.component.html',
  styleUrls: ['../../odd-webform-tab-base/odd-webform-tab-base.component.scss'],
})
export class OddWebformOverviewFundsComponent extends OddWebformTabBaseComponent {
  public rows = [
    {
      label: 'Legal Fund Name (Master)',
      name: 'OVW_LEG_FUND_NAME_M1',
      ica: 'OVW_LEG_FUND_NAME_M1_ICA',
      aum: 'OVW_LEG_FUND_NAME_M1_AUM',
    },
    {
      label: 'Legal Fund Name (Onshore)',
      name: 'OVW_LEG_FUND_NAME_LP1',
      ica: 'OVW_LEG_FUND_NAME_LP1_ICA',
      aum: 'OVW_LEG_FUND_NAME_LP1_AUM',
      handler: 'OVERVIEW_FUNDS_HANDLER',
    },
    {
      label: 'Legal Fund Name (Offshore)',
      name: 'OVW_LEG_FUND_NAME_OFF1',
      ica: 'OVW_LEG_FUND_NAME_OFF1_ICA',
      aum: 'OVW_LEG_FUND_NAME_OFF1_AUM',
      handler: 'OVERVIEW_FUNDS_HANDLER',
    },
    {
      label: 'Legal Fund Name (Master 2)',
      name: 'OVW_LEG_FUND_NAME_M2',
      ica: 'OVW_LEG_FUND_NAME_M2_ICA',
      aum: 'OVW_LEG_FUND_NAME_M2_AUM',
    },
    {
      label: 'Legal Fund Name (Onshore 2)',
      name: 'OVW_LEG_FUND_NAME_LP2',
      ica: 'OVW_LEG_FUND_NAME_LP2_ICA',
      aum: 'OVW_LEG_FUND_NAME_LP2_AUM',
    },
    {
      label: 'Legal Fund Name (Offshore 2)',
      name: 'OVW_LEG_FUND_NAME_OFF2',
      ica: 'OVW_LEG_FUND_NAME_OFF2_ICA',
      aum: 'OVW_LEG_FUND_NAME_OFF2_AUM',
    },
    {
      label: 'Managed Accounts 1',
      name: 'OVW_LEG_FUND_NAME_MA1',
      ica: 'OVW_LEG_FUND_NAME_MA1_ICA',
      aum: 'OVW_LEG_FUND_NAME_MA1_AUM',
    },
    {
      label: 'Managed Accounts 2',
      name: 'OVW_LEG_FUND_NAME_MA2',
      ica: 'OVW_LEG_FUND_NAME_MA2_ICA',
      aum: 'OVW_LEG_FUND_NAME_MA2_AUM',
    },
    {
      label: 'Alternate Vehicles 1',
      name: 'OVW_LEG_FUND_NAME_AV1',
      ica: 'OVW_LEG_FUND_NAME_AV1_ICA',
      aum: 'OVW_LEG_FUND_NAME_AV1_AUM',
    },
    {
      label: 'Alternate Vehicles 2',
      name: 'OVW_LEG_FUND_NAME_AV2',
      ica: 'OVW_LEG_FUND_NAME_AV2_ICA',
      aum: 'OVW_LEG_FUND_NAME_AV2_AUM',
    },
  ];

  constructor(protected service: QuestionnaireService) {
    super(service);
  }
}
