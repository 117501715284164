import { Component, EventEmitter, Input } from '@angular/core';
import { CompanyAddress, CompanyType } from 'app/modules/common/services/company.service';
import { CompanyReviewData, IndexedValue } from 'app/modules/questionnaire/model/questionnaire.model';
import { QuestionnaireService } from 'app/modules/questionnaire/services/questionnaire.service';
import { DdqAdviserTabBaseComponent } from '../ddq-adviser-tab-base/ddq-adviser-tab-base.component';

@Component({
  selector: 'app-ddq-adviser-service-providers',
  templateUrl: './ddq-adviser-service-providers.component.html',
  styleUrls: ['../ddq-adviser-tab-base/ddq-adviser-tab-base.component.scss'],
})
export class DdqAdviserServiceProvidersComponent extends DdqAdviserTabBaseComponent {
  @Input() companyData?: CompanyReviewData;

  public companyEmitter = new EventEmitter<IndexedValue>();

  companyTypes = [
    CompanyType.ADMINISTRATOR,
    CompanyType.AUDITOR,
    CompanyType.COMPLIANCE_CONSULTANT,
    CompanyType.CUSTODIAN,
    CompanyType.LEGAL,
    CompanyType.PRIME_BROKER,
  ];

  constructor(protected service: QuestionnaireService) {
    super(service);
  }

  /*
   * when the user selects a company on the dropdown, the default address is added to the location field
   */
  public onCompanySelect(company: CompanyAddress): void {
    this.companyEmitter.emit({ index: company.questionnaireIndex, value: company.location });
  }
}
