import { CommonModule as AngularCommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from 'app/app-routing.module';
import { MatModule } from 'app/mat.module';
import { DecimalInputDirective } from './directives/decimal-input.directive';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { HomeComponent } from './home/home.component';
import { LayoutComponent } from './layout/layout.component';
import { LoadingDialogComponent } from './loading-dialog/loading-dialog.component';
import { DatatypePipe } from './pipe/datatype.pipe';
import { SignaturePadComponent } from './signature-pad/signature-pad.component';

@NgModule({
  declarations: [
    HomeComponent,
    ErrorMessageComponent,
    LayoutComponent,
    DecimalInputDirective,
    DatatypePipe,
    LoadingDialogComponent,
    SignaturePadComponent,
  ],
  imports: [AngularCommonModule, MatModule, AppRoutingModule, FormsModule],
  exports: [
    HomeComponent,
    ErrorMessageComponent,
    LayoutComponent,
    DecimalInputDirective,
    DatatypePipe,
    LoadingDialogComponent,
    SignaturePadComponent,
  ],
})
export class CommonModule {}
