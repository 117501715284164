<mat-card appearance="outlined" id="questionnaire" class="mat-mdc-card-content">
  <div>
    <img src="assets/images/abs.png" class="abs-logo" />
  </div>
  <mat-divider [inset]="true"></mat-divider>
  @if (errorMessage) {
    <app-error-message [message]="errorMessage"></app-error-message>
  }
  @if (data) {
    <div fxLayout="column" fxLayoutAlign="center center">
      <h2>SUB-ADVISOR ATTESTATION</h2>
    </div>
    @if (isFinished()) {
      <h3>
        This Sub-Advisor Attestation is already completed. Click
        <a [href]="getPdfUrl()" target="_blank">here</a>
        to download a pdf version of the questionnaire.
      </h3>
    } @else if (!finishProgress) {
      <div fxLayout="column" fxLayoutGap="20px">
        <h3>
          This Sub-Advisor Attestation (this "Certificate") is being requested of the undersigned (the "Sub-Advisor") in its capacity as sub-advisor
          to ABS Direct Equity Fund, LLC ("Separately Managed Account" or "SMA"). The below certifications and disclosures cover the period beginning
          on
          <b>{{ getStartDate() | date: 'longDate' }}</b>
          through
          <b>{{ getEndDate() | date: 'longDate' }}</b>
          (the "Reporting Period"). This Certificate must be signed by the Chief Compliance Officer of the Sub-Advisor.
        </h3>
        <span class="mat-caption info">
          If the Sub-Advisor responds "Yes" to any of the below questions, please provide a description or explanation setting forth all material
          facts.
        </span>
        <div fxLayout="column">
          <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
            <div fxLayout="column" fxLayoutGap="20px">
              @for (key of getAnswerKeys(); track key) {
                <app-questionnaire-question [name]="descriptor[key].title">
                  <app-questionnaire-boolean-answer (errorhandler)="onError($event)" [data]="getAnswer(key)"></app-questionnaire-boolean-answer>
                  <app-questionnaire-comment-answer
                    (errorhandler)="onError($event)"
                    [data]="getAnswer(key + '_COMMENTS')"
                  ></app-questionnaire-comment-answer>
                </app-questionnaire-question>
              }
            </div>
          </div>
          @if (data) {
            <div fxLayout="row" fxLayoutAlign="end end">
              <button mat-raised-button color="primary" (click)="finishQuestionnaire()">Sign and Send</button>
            </div>
          }
        </div>
      </div>
    }
    @if (finishProgress && !isFinished()) {
      <app-questionnaire-conclusion [questionnaire]="data" (cancel)="onCancelFinishQuestionnaire()" (errorhandler)="onError($event)">
        <div>
          <div fxLayout="column" fxLayoutAlign="center center">
            <h2>Signature</h2>
          </div>
          <h3>The Sub-Advisor hereby certifies that during the period, to best of our knowledge:</h3>
          <ul class="mat-subtitle-1">
            <li>
              with respect to the SMA, it has complied with the terms of the Trading Manager Agreement dated
              {{ data.smaAgreement | date: 'longDate' }}
              by and between the Sub-Advisor and ABS Investment Management LLC;
            </li>
            <li>it has complied with all applicable laws, rules and regulations; and</li>
            <li>the information provided herein is accurate as of the date set forth below.</li>
          </ul>
          <span>SUB-ADVISOR:</span>
          <div class="mat-subtitle-2">{{ data.company }}</div>
        </div>
      </app-questionnaire-conclusion>
    }
  }
</mat-card>
