<div fxLayout="column" class="odd-form-section" fxLayoutGap="8px">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    <span>
      Please provide detail to all expenses on an annualized basis being charged to the fund and the approximate cost. Please include any expenses
      charged at both the master and feeder level, if applicable. We are asking only for an estimated number of basis points. If something is a
      "mixed" charge, please break down the bps charged to each.
    </span>
    <span>Please denote in decimal points ie 20 basis points = .20 and 1 bp =.01.</span>
    <span>
      If anything is significantly different or conflicting between the funds listed, choose the larger fund by AUM and use the additional comments
      box at the bottom to explain.
    </span>

    <app-questionnaire-decimal-answer
      style="width: 15%"
      label="Average AUM"
      [data]="getAnswer('EXP_EXPENSE_SOFT_AVG_AUM')"
      (errorhandler)="onError($event)"
      [disabled]="isReviewed() || !!getAnswer('EXP_EXPENSE_SOFT_AVG_AUM')?.reviewed || !!getAnswer('EXP_EXPENSE_SOFT_AVG_AUM')?.reviewing"
    ></app-questionnaire-decimal-answer>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="18px" fxFlex="100">
      <div class="limited-medium-fields abs-bold">Expense Name</div>
      <div class="abs-bold" fxFlex="15">Direct expense</div>
      <div class="abs-bold" fxFlex="15">Soft dollars</div>
      <div class="abs-bold" fxFlex="15">Total Expenses</div>
    </div>

    @for (answer of getAllAnswers('EXP_EXPENSE_NAME'); track answer.id) {
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="18px" fxFlex="100">
        <div class="limited-medium-fields">{{ answer.index + 1 }} - {{ answer.value | datatype: 'fundExpensesValue' }}</div>
        <div fxFlex="15">
          <app-questionnaire-decimal-answer
            [decimals]="4"
            [data]="getAnswer('EXP_EXPENSE_DIRECT', answer.index)"
            (errorhandler)="onError($event)"
            [disabled]="isReviewed() || !!getAnswer('EXP_EXPENSE_DIRECT')?.reviewed || !!getAnswer('EXP_EXPENSE_DIRECT')?.reviewing"
          ></app-questionnaire-decimal-answer>
        </div>
        <div fxFlex="15">
          <app-questionnaire-decimal-answer
            [decimals]="4"
            [data]="getAnswer('EXP_EXPENSE_SOFT_DOLLAR', answer.index)"
            (errorhandler)="onError($event)"
            [disabled]="isReviewed() || !!getAnswer('EXP_EXPENSE_SOFT_DOLLAR')?.reviewed || !!getAnswer('EXP_EXPENSE_SOFT_DOLLAR')?.reviewing"
          ></app-questionnaire-decimal-answer>
        </div>
        <div fxFlex="15">
          <div fxLayout="row" fxLayoutAlign="end center">
            {{
              sumFields(getAnswer('EXP_EXPENSE_DIRECT', answer.index)?.value, getAnswer('EXP_EXPENSE_SOFT_DOLLAR', answer.index)?.value)
                | number: '1.4-4'
            }}
          </div>
        </div>
      </div>
    }

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="18px" fxFlex="100">
      <div class="limited-medium-fields">
        <div fxLayout="row" fxLayoutAlign="end center">Total Estimated Expense Ratio:</div>
      </div>
      <div fxFlex="15">
        <div fxLayout="row" fxLayoutAlign="end center">
          {{ directTotal | number: '1.4-4' }}
        </div>
      </div>
      <div fxFlex="15">
        <div fxLayout="row" fxLayoutAlign="end center">
          {{ softDollarTotal | number: '1.4-4' }}
        </div>
      </div>
      <div fxFlex="15">
        <div fxLayout="row" fxLayoutAlign="end center">
          {{ totalExpenses | number: '1.4-4' }}
        </div>
      </div>
    </div>

    <span class="abs-nomargin question-info">Additional Comments</span>
    <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
      <app-questionnaire-comment-answer
        fxFlex
        [noMargin]="true"
        [noLabel]="true"
        [highlightChanges]="true"
        [data]="getAnswer('EXP_EXPENSE_SOFT_COMMENTS')"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer('EXP_EXPENSE_SOFT_COMMENTS')?.reviewed || !!getAnswer('EXP_EXPENSE_SOFT_COMMENTS')?.reviewing"
      ></app-questionnaire-comment-answer>
      @if (showReview) {
        <app-questionnaire-review
          [disabled]="isReviewed()"
          [answers]="
            getAnswersForReview([
              'EXP_EXPENSE_SOFT_AVG_AUM',
              'EXP_EXPENSE_NAME',
              'EXP_EXPENSE_DIRECT',
              'EXP_EXPENSE_SOFT_DOLLAR',
              'EXP_EXPENSE_SOFT_COMMENTS'
            ])
          "
          (errorhandler)="onError($event)"
          reviewHandler="FUND_EXPENSES_HANDLER"
        ></app-questionnaire-review>
      }
    </div>
  </div>
</div>

<div fxLayout="column" class="odd-form-section">
  <div class="abs-field-wrapper" fxLayout="column" fxLayoutGap="8px">
    @for (comment of comments; track comment.name) {
      <div fxLayout="row" fxLayoutAlign="start center" class="abs-margin-between">
        <span class="question-info">{{ comment.label }}</span>
        @if (showReview) {
          <app-questionnaire-review
            [disabled]="isReviewed()"
            [answers]="getAnswersForReview([comment.name])"
            (errorhandler)="onError($event)"
          ></app-questionnaire-review>
        }
      </div>
      <app-questionnaire-comment-answer
        [noMargin]="true"
        [noLabel]="true"
        [highlightChanges]="true"
        [data]="getAnswer(comment.name)"
        (errorhandler)="onError($event)"
        [disabled]="isReviewed() || !!getAnswer(comment.name)?.reviewed || !!getAnswer(comment.name)?.reviewing"
      ></app-questionnaire-comment-answer>
    }
  </div>
</div>
