import { CommonModule as AngularCommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatModule } from 'app/mat.module';
import { CommonModule } from '../common/common.module';
import { AttestationEditComponent } from './components/attestation-edit/attestation-edit.component';
import { AttestationComponent } from './components/attestation/attestation.component';
import { QuestionnaireBooleanAnswerComponent } from './components/common/questionnaire-boolean-answer/questionnaire-boolean-answer.component';
import { QuestionnaireCheckboxAnswerComponent } from './components/common/questionnaire-checkbox-answer/questionnaire-checkbox-answer.component';
import { QuestionnaireCommentAnswerComponent } from './components/common/questionnaire-comment-answer/questionnaire-comment-answer.component';
import { QuestionnaireCompanyAnswerComponent } from './components/common/questionnaire-company-answer/questionnaire-company-answer.component';
import { QuestionnaireConclusionComponent } from './components/common/questionnaire-conclusion/questionnaire-conclusion.component';
import { QuestionnaireConflictComponent } from './components/common/questionnaire-conflict/questionnaire-conflict.component';
import { QuestionnaireDateAnswerComponent } from './components/common/questionnaire-date-answer/questionnaire-date-answer.component';
import { QuestionnaireDecimalAnswerComponent } from './components/common/questionnaire-decimal-answer/questionnaire-decimal-answer.component';
import { QuestionnaireNumberAnswerComponent } from './components/common/questionnaire-number-answer/questionnaire-number-answer.component';
import { QuestionnaireQuestionComponent } from './components/common/questionnaire-question/questionnaire-question.component';
import { QuestionnaireRadioAnswerComponent } from './components/common/questionnaire-radio-answer/questionnaire-radio-answer.component';
import { QuestionnaireCompanyReviewComponent } from './components/common/questionnaire-review/questionnaire-company-review.component';
import { QuestionnaireReviewComponent } from './components/common/questionnaire-review/questionnaire-review.component';
import { QuestionnaireSelectAnswerComponent } from './components/common/questionnaire-select-answer/questionnaire-select-answer.component';
import { QuestionnaireTextAnswerComponent } from './components/common/questionnaire-text-answer/questionnaire-text-answer.component';

@NgModule({
  declarations: [
    QuestionnaireQuestionComponent,
    QuestionnaireBooleanAnswerComponent,
    QuestionnaireCommentAnswerComponent,
    QuestionnaireConclusionComponent,
    AttestationComponent,
    AttestationEditComponent,
    QuestionnaireReviewComponent,
    QuestionnaireTextAnswerComponent,
    QuestionnaireNumberAnswerComponent,
    QuestionnaireSelectAnswerComponent,
    QuestionnaireCheckboxAnswerComponent,
    QuestionnaireDecimalAnswerComponent,
    QuestionnaireCompanyAnswerComponent,
    QuestionnaireDateAnswerComponent,
    QuestionnaireRadioAnswerComponent,
    QuestionnaireCompanyReviewComponent,
    QuestionnaireConflictComponent,
  ],
  imports: [AngularCommonModule, FormsModule, MatModule, CommonModule, ReactiveFormsModule],
  exports: [
    QuestionnaireQuestionComponent,
    QuestionnaireBooleanAnswerComponent,
    QuestionnaireCommentAnswerComponent,
    QuestionnaireConclusionComponent,
    AttestationComponent,
    AttestationEditComponent,
    QuestionnaireReviewComponent,
    QuestionnaireTextAnswerComponent,
    QuestionnaireNumberAnswerComponent,
    QuestionnaireSelectAnswerComponent,
    QuestionnaireCheckboxAnswerComponent,
    QuestionnaireDecimalAnswerComponent,
    QuestionnaireCompanyAnswerComponent,
    QuestionnaireDateAnswerComponent,
    QuestionnaireRadioAnswerComponent,
    QuestionnaireCompanyReviewComponent,
    QuestionnaireConflictComponent,
  ],
})
export class QuestionnaireModule {}
