<div fxLayout="column" class="odd-form-section">
  <p class="question-info">Please list the names of all funds managed by the Investment Manager</p>

  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="18px">
    <div class="limit-title-fields"></div>
    <div fxFlex="35" class="abs-bold">Name</div>
    <div fxFlex="10" class="abs-bold">ICA Exemption</div>
    <div fxFlex="10" class="abs-bold">AUM</div>
    @if (showReview) {
      <div fxFlex="10"></div>
    }
  </div>

  @for (row of rows; track row.name) {
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="18px">
      <div class="limit-title-fields">{{ row.label }}</div>
      <div fxFlex="35">
        <app-questionnaire-text-answer
          [data]="getAnswer(row.name)"
          (errorhandler)="onError($event)"
          [disabled]="isReviewed() || !!getAnswer(row.name)?.reviewed || !!getAnswer(row.name)?.reviewing"
        ></app-questionnaire-text-answer>
      </div>
      <div fxFlex="10">
        <app-questionnaire-select-answer
          [data]="getAnswer(row.ica)"
          datatype="icaExemption"
          (errorhandler)="onError($event)"
          [disabled]="isReviewed() || !!getAnswer(row.ica)?.reviewed || !!getAnswer(row.ica)?.reviewing"
        ></app-questionnaire-select-answer>
      </div>
      <div fxFlex="10">
        <app-questionnaire-decimal-answer
          [data]="getAnswer(row.aum)"
          (errorhandler)="onError($event)"
          [disabled]="isReviewed() || !!getAnswer(row.aum)?.reviewed || !!getAnswer(row.aum)?.reviewing"
        ></app-questionnaire-decimal-answer>
      </div>
      @if (showReview) {
        <div fxFlex="10">
          <app-questionnaire-review
            [disabled]="isReviewed()"
            [answers]="getAnswersForReview([row.name, row.ica, row.aum])"
            (errorhandler)="onError($event)"
            [reviewHandler]="row.handler"
          ></app-questionnaire-review>
        </div>
      }
    </div>
  }
</div>
